import React from "react";
import {AEField} from "../../../../../_metronic/_partials/controls/forms/AEField";

export const Form = ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         btnRef,
                     }) => {

    return (
        <div className="form form-label-right">
            <div className="form-group row">
                <div className={"col-lg-6"}>
                    <AEField
                        name="firstName"
                        label={'FIRST_NAME'}
                    />
                </div>
                <div className={"col-lg-6"}>
                    <AEField
                        name="lastName"
                        label={'LAST_NAME'}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className={"col-lg-6"}>
                    <AEField
                        name="email"
                        label={'EMAIL'}
                    />
                </div>
                <div className={"col-lg-6"}>
                    <AEField
                        name="phone"
                        label={'PHONE'}
                    />
                </div>
            </div>

            <button
                style={{display: "none"}}
                ref={btnRef}
                onClick={handleSubmit}
            />
        </div>
    );
}

