import React, {useEffect, useRef} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {Form} from "./Form";
import {AEButton} from "../../../../_ae/components/buttons";
import {
    resetBidProcessForCreate,
    saveBidProcess
} from "../../../../redux/store/bidProcess"
import {useDispatch, useSelector} from "react-redux";

export const BidProcessForm = ({entity}) => {

    const dispatch = useDispatch();
    const {user} = useSelector(state => state.auth);
    useEffect(() => {
        dispatch(resetBidProcessForCreate());
    }, [dispatch]);

    const saveBtnRef = useRef();
    const saveBtnRefClick = () => {
        if (saveBtnRef && saveBtnRef.current) {
            const {current} = saveBtnRef;
            current.click()
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={
                {
                    user: user ? user : undefined,
                    post: undefined,
                    email: "",
                    phone: "",
                    price: 0,
                    note: ""
                }
            }
            validationSchema={
                Yup.object().shape({
                    user: Yup.object(),
                    post: Yup.object(),
                    email : Yup.string().email().when(['user', 'phone'], {
                        is: (user, phone) => !user && !phone,
                        then: Yup.string().email().required()
                    }),
                    /*email : Yup.string().email().when('user',
                        (user,schema)=>{
                            if(!user){
                                return schema.required('field required');
                            }else{
                                return schema;
                            }
                        }),*/
                    phone: Yup.string().when(['user'], {
                        is: (user) => !user,
                        then: Yup.string().required()
                    }),
                    price: Yup.number().required(),
                    note: Yup.string()
                })
            }
            onSubmit={(values) => {
                values.user = user;
                values.post = entity;
                dispatch(saveBidProcess(values))

            }}
           >
            {formik => (
                <>
                    <Form
                        {...formik}
                        btnRef={saveBtnRef}
                    />
                    <AEButton
                        className="btn btn-primary btn-wide color-primary btn-property p-2"
                        onClick={saveBtnRefClick}
                    >
                        Envoyer
                    </AEButton>
                </>
            )}
        </Formik>
    );
}

