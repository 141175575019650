import React from 'react';
import OwlCarousels from "../../../../_ae/components/OwlCarousel";
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import {CreateStroy} from "./addStory";
import {useDispatch, useSelector} from "react-redux";
import {StoryAvatar} from "./Avatar";
import {Link} from "react-router-dom";
import SVG from "react-inlinesvg";


const SliderSettings = {
    nav: true,
    dots: false,
    margin: 0,
    loop: false,
    mouseDrag: false,
    items: 8,
    responsive: {
        0: {
            items: 2
        },
        420: {
            items: 3
        },
        600: {
            items: 4
        },
        900: {
            items: 5
        },
        1024: {
            items: 6
        },
        1360: {
            items: 8
        }
    }
}

export const StoryCarousel = ({
                                  stories,
                                  adClass = "mt-5 mb-5 owl-simple",
                                  count = 6,
                                  start = 0,
                                  sliderOption = SliderSettings,
                                  ...props
                              }) => {

    const {user} = useSelector(state => state.auth);

    return (
        <OwlCarousels adClass={`${adClass} d-flex brand-carousel cols-xl-7 cols-lg-5 cols-md-4 cols-sm-3 cols-2`}
                      carouselOptions={sliderOption} carouselId="storyslider">
            {
                user ?
                    <CreateStroy/> :
                    <Link className="text-center" target="_blank" to={'/admin'}>
                        <span className="symbol-label">
                            <SVG
                                className="h-75 align-self-end"
                                src={toAbsoluteUrl("/media/svg/avatars/004-boy-1.svg")}
                            />
                            <i className="fa fa-plus-circle pr-2"> Ajouter Story</i>
                        </span>
                    </Link>
            }
            {
                stories &&
                Object.values(stories).map((stories, index) => {
                    return stories.map((story, i) => {
                            if (i === 0) {
                                return (
                                    <div key={story.id} className="card mb-2 story-card">
                                        <div className="card-body image-box">
                                            {
                                                (story.fileName.split(".")).pop() === "mp4" ?
                                                    <video width="600" height="210"
                                                           className="align-self-center story-item"
                                                           id={story.id}
                                                           style={{maxWidth: 100 + '%'}}
                                                           muted>
                                                        <source
                                                            src={toEntityFileNameUrl(story, 'stories')}
                                                            type="video/mp4"/>
                                                        Your browser does not support the video tag.
                                                    </video>

                                                    :

                                                    <img className="card-img-top story-item"
                                                         id={story.id}
                                                         src={toEntityFileNameUrl(story, 'stories')}
                                                         alt=""/>
                                            }
                                            {
                                                <span className={"story-icon_total"}>
                                                    {stories.length}
                                                </span>
                                                // <span>
                                                //     {story.author.firstName}{` `}{story.author.lastName}
                                                // </span>
                                            }

                                            {
                                                story.author &&
                                                <span className={"story-label_image"}>
                                                    <Link to={'/annonces/by/user/:id'.replace(':id', story.author.id)}
                                                          className="agent-name text-color-primary">
                                                        <StoryAvatar
                                                            entity={story.author}
                                                            className={"rounded-circle mr-2 border border-4 border-info"}
                                                            size={35}/>
                                                    </Link>
                                                </span>
                                                // <span>
                                                //     {story.author.firstName}{` `}{story.author.lastName}
                                                // </span>
                                            }
                                            {
                                                story.author &&
                                                <span className={"story-label_username"}>
                                                        {story.author.firstName} {story.author.lastName}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        }
                    )
                })
            }
        </OwlCarousels>
    )
}


