import React, {useEffect} from 'react';
import {Pagination} from "../../../../../_metronic/_partials/controls";
import {useDispatch} from "react-redux";
import {useMetadata} from "../../../../../_ae/AEPagination";
import {PostListCard} from "../PostListCard";
import {Link, useParams} from "react-router-dom";
import {fetchPosts, usePostsListState} from "../../../../../redux/store/posts";
import {Slider} from "../../Slider";
import {Filter} from "./Filter";
import {AELabel} from "../../../../../_ae/components/AELabel";
import {fetchCategoryForEdit, useCategoriesEditState} from "../../../../../redux/store/categories";
import {MainHeader} from "../../MainHeader";


export function PostsByCategory() {

    const dispatch = useDispatch();
    const {id} = useParams();
    const {data: category} = useCategoriesEditState();
    const {data: postsByCategory, isLoading, metadata} = usePostsListState()


    const metadataState = useMetadata(metadata)
    // const metadataState = useMetadata({...metadata, filters: {...metadata.filters, category: {id: id}}})
    const {page, perPage, sortAsc, filters, sortField, select} = metadataState;

    useEffect(() => {
        dispatch(fetchCategoryForEdit(id));
    }, [id, dispatch])

    const getEntities = () => {
        if (filters.category) {
            dispatch(fetchPosts({
                ...metadataState.toAEMetadata(),
                filters: {...metadataState.toAEMetadata().filters, "status": "enabled"}
            }));
        } else {
            dispatch(fetchPosts({
                ...metadataState.toAEMetadata(),
                filters: {...metadataState.toAEMetadata().filters, category: {id: id}, "status": "enabled"}
            }));
        }
    }


    useEffect(() => {
        // filters.category = {id: id};
        getEntities()
    }, [id, dispatch, page, perPage, filters.search, filters.status, filters.priceRange, filters.attributes, filters.specifications, filters.category, filters.type, filters.city, select, sortField, sortAsc]);


    return (
        <>
            <header className="header">
                <MainHeader {...metadataState}/>
                <div className="container">
                    <Slider/>
                </div>
            </header>
            <main className="main section-color-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="h-side top-pad h-side-slim clearfix">
                                <div className="properties-filter breadcrumb">
                                    <div className="form-group">
                                        <Link to='/' style={{textDecoration: 'underline'}}>
                                            <span className="agent-name m-2">
                                                   <i className={"agent-name fa fa-home"}/> Toutes les Catégories >>
                                            </span>
                                        </Link>

                                        {
                                            category.parrent &&
                                            <Link to={'/annonces/by/category/:id'.replace(':id', category.parrent.id)}>
                                                 <span className="agent-name m-2">
                                                   {category.parrent.label} >>
                                                 </span>
                                            </Link>
                                        }

                                        <Link to={'/annonces/by/category/:id'.replace(':id', category.id)}>
                                            <span className="agent-name m-2">
                                                   {category.label} >>
                                            </span>
                                        </Link>
                                        {
                                            category.subCategories.map(subCategory => {
                                                return (
                                                    <AELabel key={subCategory.id} variant="light" size="lg">
                                                        <Link
                                                            to={'/annonces/by/category/:id'.replace(':id', subCategory.id)}>
                                                            {subCategory.label}
                                                        </Link>
                                                    </AELabel>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="grid-type pull-right">

                                    </div>
                                </div>
                            </div>
                            <div className="properties">
                                <div className="row">
                                    {
                                        postsByCategory &&
                                        postsByCategory.map((post, index) => {
                                            return (
                                                <PostListCard key={index} entity={post}/>
                                            );
                                        })
                                    }
                                </div>

                                <Pagination {...metadataState} counts={[12, 24, 48]}/>

                            </div>
                        </div>
                        <Filter id={id} {...metadataState}/>
                    </div>
                </div>
            </main>
        </>
    );
}



