import axios from "axios";
import {axiosFormDataConfigs, getFormData} from "../../../_ae/helpers/AxiosHelpers";
import {toAbsoluteApiUrl} from "../../../_metronic/_helpers";
import {MODULES} from "../helpers";

export const API_URI = toAbsoluteApiUrl(`/${MODULES.POSTS}`);

const normalize = _entity => {
    const {id, title, fileName, price, description, type, state} = _entity;

    const author = {id: _entity.author ? _entity.author.id : undefined}
    const announcer = {id: _entity.announcer ? _entity.announcer.id : undefined}
    const resume = {id: _entity.resume ? _entity.resume.id : undefined}
    const status = _entity.status ? _entity.status : 'enabled'

    const city = {id: _entity.city ? _entity.city.id : undefined}

    const category = {id: _entity.category ? _entity.category.id : undefined}

    let postAttachements = undefined;
    if (_entity.postAttachements) {
        postAttachements = _entity.postAttachements.map(postAttachement => postAttachement)
    }

    let specifications = undefined;
    if (_entity.specifications) {
        specifications = _entity.specifications.map(specification => ({id: specification.id}))
    }

    let attributes = undefined;

    if (_entity.attributes) {
        attributes = _entity.attributes.filter(v => v).map(attribute => ({id: attribute.id}))
    }
    return {
        id,
        title,
        fileName,
        price,
        postAttachements,
        description,
        type,
        state,
        author,
        resume,
        city,
        category,
        specifications,
        attributes,
        announcer,
        status
    }
}

export const allPosts = (metadata) => axios.get(`${API_URI}/all`, {params: {meta: metadata}}); //coté administration : si admin return all posts else return user posts only
export const all = (metadata) => axios.get(API_URI, {params: {meta: metadata}});
export const getFeatured = (metadata) => axios.get(`${API_URI}/featured`, {params: {meta: metadata}});
export const find = (id, metadata) => axios.get(`${API_URI}/${id}`, {params: {meta: metadata}});
export const findByUser = (id, metadata) => axios.get(`${API_URI}/by/user/${id}`, {params: {meta: metadata}});
export const findByAnnouncer = (id, metadata) => axios.get(`${API_URI}/by/announcer/${id}`, {params: {meta: metadata}});
export const findByCategory = (id, metadata) => axios.get(`${API_URI}/by/category/${id}`, {params: {meta: metadata}});
export const destroy = id => axios.delete(`${API_URI}/${id}`);
export const create = (entity, files) => axios.post(API_URI, getFormData(normalize(entity), files), axiosFormDataConfigs);
export const update = (entity, files) => axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(normalize(entity), files), axiosFormDataConfigs);
