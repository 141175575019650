import React from "react";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import {AESVG, SVG_ICON} from "../../../../_ae/components/svg";
import {useIntl} from "react-intl";
import {AEEmail, AELink} from "../../../../_ae/components/AELink";
import {AEAvatar} from "../../../../_ae/components/AEAvatar";
import {FullName} from "./FullName";
import clsx from "clsx";
import {DropdownCustomToggler,} from "../../../../_metronic/_partials/dropdowns";
import {Dropdown} from "react-bootstrap";
import {AERole} from "../../../../_ae/components/AERole";
import {Role} from "./Role";
import {useRoutesForAppState} from "../../../../redux/store/routes";
import {City} from "../../../pages/City";

export const EntityCard = ({
  entity,
  size= "md",
  className = '',
  avatar,
  editAction,
  deleteAction,
}) => {

  const { formatMessage } = useIntl()
  const {data: routes, routesLoading} = useRoutesForAppState();

  const classes = {
    xs:{
      card: 'shadow-none ',
      cardBody: 'p-1',
      symbol: 'symbol-40 mr-2',
      title: 'h6',
    },
    sm:{
      card: 'shadow-none ',
      cardBody: 'p-2',
      symbol: 'symbol-70 mr-2',
      title: 'h5',
    },
    md:{
      symbol: 'symbol-70 mr-5',
      cardBody: 'p-3 d-flex flex-column',
      title: 'h4',
    },
    lg:{
      symbol: 'symbol-80 mr-5',
      title: 'h3',
      cardBody: 'p-4',
    },
  }

  const sizeClasses = classes[size];
  const isLg = size === 'lg'
  const isMd = size === 'md'
  const isXs = size === 'xs'

  const actions = [
    {id: 'USERS.EDIT', show: editAction},
    {id: 'USERS.DELETE', show: deleteAction},
  ].filter(action=>action.show);


  return (
    <>
      <Card className={`${className}`}>
        <CardBody className={`${sizeClasses.cardBody}`}>
          <div className={`d-flex`}>
            {
              avatar && <AEAvatar entity={entity} className={sizeClasses.symbol} />
            }

            <div className="flex-grow-1">
              <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                  {/*TODO LINK*/}
                  <FullName user={entity} fontSize={sizeClasses.title} fontWeight={'bold'}/>
                  <div className={clsx('flex-grow-1', !isXs && 'pt-1')} >
                    {
                      [
                        {id: "EMAIL", svg: SVG_ICON.EMAIL, value: <AEEmail value={entity.email} />, hidden: isXs},
                        {id: "CITY", svg: SVG_ICON.LOCATION, value: <City city={entity.city} />},
                      ]
                        .filter(op=>!op.hidden)
                        .map(op=>(
                          <div key={op.id} className="d-flex pb-1">
                            {
                              !isXs &&
                              <AESVG
                                size={'md'}
                                className={"flex-shrink-0 mr-2"}
                                path={op.svg}
                              />
                            }

                            <span className="">
                              {op.value}
                              {
                                !isXs &&
                                <span className={"text-muted ml-2"}> {formatMessage({id: op.id})} </span>
                              }

                            </span>
                          </div>
                        ))
                    }
                  </div>
                </div>
                {
                  actions.length > 0 &&
                  <Dropdown>
                    <Dropdown.Toggle as={DropdownCustomToggler} >
                      <AESVG path={SVG_ICON.DOTS} variant={'primary'} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {
                        actions
                          .map(action=>{
                            const route = routes.find(r=>r.id === action.id);
                            if (! route) return '';
                            const path = route.path.replace(':id', entity.id)

                            return (
                              <AERole key={route.id} roles={route.roles}>
                                <AELink to={path} className="dropdown-item" >
                                  <AESVG className={'pr-2'} path={route.svg}/>
                                  {formatMessage({id: route.id})}
                                </AELink>
                              </AERole>
                            )
                          })
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                }
              </div>
            </div>
          </div>

          {
            (isMd || isLg) &&
            entity.roles.length > 0 &&
            <div className={'mt-auto'}>
              <div className="separator separator-solid my-2"/>
              <div className="">
                {entity.roles.map(rl=> <Role key={rl} role={rl} /> )}
              </div>
            </div>

          }
        </CardBody>
      </Card>
    </>
  )
}