import React from "react";
import {toEntityFileNameUrl} from "../../../../_metronic/_helpers";

export const Icon =({entity, className = '', size = 35}) =>{
    return (
       
        <div className={`symbol ${className} symbol-${size}`}>
          {
             /*entity.fileName.split('.').pop() == 'svg' ?
              
             <span className="symbol-label">
              <SVG
                className="h-75 align-self-center"
                src={toEntityFileNameUrl(entity, 'specifications')}
              />
              </span>

              :*/
              <img src={toEntityFileNameUrl(entity, 'specifications')} alt={` `}
              className="h-75 align-self-center"/>
             
          }
          {/*<i className="symbol-badge bg-primary"/>*/}
        </div>
        )
    }   