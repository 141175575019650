import React from "react";
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export const Icon =({entity, className = '', size = 35}) =>{
    return (
       
        <div className={`symbol ${className} symbol-${size}`}>
          {
            entity.fileName ?
              <img src={toEntityFileNameUrl(entity, 'categories')} alt={` `}
              className="h-75 align-self-center"/>
              :
              <img src={toAbsoluteUrl("/media/svg/categories.png")} alt={` `}
              className="h-75 align-self-center"/>
          }
          {/*<i className="symbol-badge bg-primary"/>*/}
        </div>
        )
    }   