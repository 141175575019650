import axios from "axios";
import {axiosFormDataConfigs, getFormData} from "../../../_ae/helpers/AxiosHelpers";
import {toAbsoluteApiUrl} from "../../../_metronic/_helpers";
import {MODULES} from "../helpers";

export const API_URI = toAbsoluteApiUrl(`/${MODULES.ATTRIBUTES}`);

const normalize = _entity => {

    console.log(_entity);
    const {id, label, multiple} = _entity;
    const parrent = {id: _entity.parrent ? _entity.parrent.id : undefined}
    const category = {id: _entity.category.id}
    let subOptions = undefined;
    if (_entity.subOptions) {
        subOptions = _entity.subOptions.map(subOption => ({id: subOption.id}))
    }

    return {id, label, multiple, category, parrent, subOptions}
}


export const all = (metadata) => axios.get(API_URI, {params: {meta: metadata}});
export const find = (id, metadata) => axios.get(`${API_URI}/${id}`, {params: {meta: metadata}});
export const findByCategory = (id, metadata) => axios.get(`${API_URI}/by/category/${id}`, {params: {meta: metadata}});
export const destroy = id => axios.delete(`${API_URI}/${id}`);
export const create = (entity, files) => axios.post(API_URI, getFormData(normalize(entity), files), axiosFormDataConfigs);
export const update = entity => axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(normalize(entity)), axiosFormDataConfigs);
