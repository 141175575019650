import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {fetchUserForEdit, useUsersEditState} from "../../../../redux/store/users";
import "./NotificationStyle.css"
import {toEntityFileNameUrl} from "../../../../_metronic/_helpers";

export const NotificationsRow = (entity) => {
    const dispatch = useDispatch();
    // const {user} = useSelector(state => state.auth);
    const {data: author} = useUsersEditState()
    let notification = entity.entity


    useEffect(() => {
        if (notification.user) {
            dispatch(fetchUserForEdit(notification.user.id));
        }
    }, [notification, dispatch]);

    return (
        <>
            <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                <div className="dropdown-list-image mr-3">
                    {
                        author &&
                        <img className="rounded-circle"
                             src={toEntityFileNameUrl(author, 'users')}
                             alt=""/>
                    }
                </div>
                <div className="font-weight-bold mr-3">
                    <div className="mb-2">
                        <span className="font-weight-normal">
                            {notification.message}
                        </span>
                    </div>
                    {
                        notification.entityId &&
                        <button type="button" className="btn btn-outline-success btn-sm">
                            <Link to={'/annonces/:id/details'.replace(':id', notification.entityId)}>
                                <i className={"fa fa-link pr-2"}/> Voir l'annonce
                            </Link>
                        </button>
                    }
                </div>
                {/*<span className="ml-auto mb-auto">*/}
                {/*    <div className="btn-group">*/}
                {/*        <button type="button" className="btn btn-light btn-sm rounded"*/}
                {/*                data-toggle="dropdown"*/}
                {/*                aria-haspopup="true" aria-expanded="false">*/}
                {/*            <i className="mdi mdi-dots-vertical"/>*/}
                {/*        </button>*/}
                {/*        <div className="dropdown-menu dropdown-menu-right">*/}
                {/*            <button className="dropdown-item" type="button"><i*/}
                {/*                className="mdi mdi-delete"/> Delete</button>*/}
                {/*            <button className="dropdown-item" type="button"><i*/}
                {/*                className="mdi mdi-close"/> Turn Off</button>*/}
                {/*        </div>*/}
                {/*        </div>*/}
                {/*    <br/>*/}
                {/*    <div className="text-right text-muted pt-1">4d</div>*/}
                {/*</span>*/}
            </div>
        </>
    );
}
