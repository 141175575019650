import React, {useEffect} from "react";
import {FormikProvider, useFormik} from "formik";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../_metronic/_helpers";
import {AEAutocompleteField, AEField} from "../../../_metronic/_partials/controls/forms/AEField";
import {
    fetchCategoriesForSubCategoriesFilter,
    useSubCategoriesFilterState
} from "../../../redux/store/categories";
import {
    fetchCityForLocationsFilter,
    useCitiesLocationsFilterState
} from "../../../redux/store/cities";
import {fetchPosts} from "../../../redux/store/posts";
import {useMetadata} from "../../../_ae/AEPagination";


const LoggedInView = props => {
    const {isAuthorized, user} = useSelector(({auth}) => ({
        user: auth.user,
        isAuthorized: auth.user != null,
    }), shallowEqual);

    return (
        isAuthorized ?
            <ul className="login-menu clearfix">
                <li>
                    <Link target="_blank" to="/admin/posts" className="font-weight-bold">
                        <i className="fa fa-list"/> Mes annonces
                    </Link>
                </li>
                <li>
                    <Link to="/favorites" className="font-weight-bold">
                        <i className="fa fa-star"/> Mes favories
                    </Link>
                </li>
                <li>
                    <Link to="/bidprocesses" className="font-weight-bold">
                        <i className="fas fa-money"/> Mes Propositions d'enchères
                    </Link>
                </li>
                <li>
                    <Link to="/notifications" className="font-weight-bold">
                        <i className="fa fa-globe"/> Notifications
                    </Link>
                </li>
                <li>
                    <a href="/admin/logout"
                       onClick="setTimeout(location.reload.bind(location), 1)">
                        <i className="fa fa-power-off"/> Quitter
                    </a>
                </li>
            </ul>
            :
            <ul className="login-menu clearfix">
                <li>
                    <Link target="_blank" to="/admin/auth/login" className="font-weight-bold">
                        <i className="fa fa-power-off"/> Se connecter
                    </Link>
                </li>
                <li>
                    <Link target="_blank" to="/admin/auth/login" className="font-weight-bold">
                        <i className="fa fa-list"/> Mes annonces
                    </Link>
                </li>
                <li>
                    <a href="/admin/auth/login"
                       onClick="setTimeout(location.reload.bind(location), 1)">
                        <i className="fa fa-star"/> Mes Favoris
                    </a>
                </li>
            </ul>
    );

};


const sorts = [
    {id: 1, prop: "title", label: "TITLE"},
    {id: 2, prop: "createdAt", label: "DATE"},
]


// const types = [
//     {id: "OFFRE", label: "Offre"},
//     {id: "DEMANDE", label: "Demande"},
//     {id: "LOCATION", label: "Location"},
// ]

export const MainHeader = ({setFilters, setSort, sort, setSortAsc}) => {

    const dispatch = useDispatch();
    const history = useHistory()
    const {data: categories} = useSubCategoriesFilterState();
    const {data: cities, metadata} = useCitiesLocationsFilterState();
    const metadataState = useMetadata(metadata)

    //const [locations, setLocations] = React.useState([]);


    const formik = useFormik({
        initialValues: {
            search: "",
            city: undefined,
            // category: undefined,
            //location: undefined,
            // type: "",
            // priceRange: [0, 1000000000]
        },
        onSubmit: (values) => {
            const {search, city} = values;
            let filters = {search};
            filters.status = "enabled"; //to get only enabled posts!
            // if (category) filters.category = {id: category.id};
            if (city) filters.city = {id: city.id};
            // if (type) filters.type = type.id;
            // filters.priceRange = priceRange;
            setFilters(filters)
        }
    })

    // const categoriesFormik = useFormik({
    //     initialValues: {
    //         category: undefined,
    //     },
    //     onSubmit: (values) => {
    //         history.push('/annonces/by/category/:id'.replace(':id', values.category.id));
    //     }
    // })

    useEffect(() => {
        dispatch(fetchCategoriesForSubCategoriesFilter())
        // dispatch(fetchCityForLocationsFilter())
        dispatch(fetchCityForLocationsFilter(metadataState.toAEMetadata({
            ...metadataState.toAEMetadata(),
            sort: {...metadataState.toAEMetadata().sort, field: "name"},
            pagination: {...metadataState.toAEMetadata().pagination, perPage: 999}

        })));
    }, [dispatch])


    /* let city = null;
     useEffect(() => {
         city = formik.values.city
         if (city)
         {
             setLocations(city.locations);
         }
     }, [formik.values.city])
     */

    useEffect(() => {
        formik.handleSubmit()
    }, [formik.values])

    const handleCategoryChange = (event, value) => {
        if (value) {
            history.push('/annonces/by/category/:id'.replace(':id', value.id));
        }
    }
    // useEffect(() => {
    //     handleCategoryChange
    // }, [categoriesFormik.values])


    const fieldProps = {
        withFeedbackLabel: false,
        validation: false,
        className: 'text-white'
    }
    const style = {
        //backgroundColor: '#004790',
        color: 'white'
    }


    return (
        <>
            <div className="top-box" data-toggle="sticky-onscroll">
                <div className="container header-container">
                    <div className="top-bar color-primary">
                        <div className="container clearfix">
                            <div className="pull-left">
                                <LoggedInView/>
                            </div>
                            <div className="pull-right">
                                <ul className="social-nav clearfix">
                                    <li>
                                        <a target="_blank" href="https://www.facebook.com/Onixma-100435112399216/">
                                            <i className="fab fa-facebook-square fa-2x"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.instagram.com/onix.ma/">
                                            <i className="fab fa-instagram-square fa-2x"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.linkedin.com/in/onix-annonces-b90516220/">
                                            <i className="fab fa-linkedin-square fa-2x"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <section className="header-inner" style={{backgroundColor: "whitesmoke"}}>
                        <div className="container header-container">
                            <div className="logo pull-left pull-sm-up col-sm-2 col-xs-12 text-left">
                                <Link to="/annonces" className="nav-link">
                                    <img src={toAbsoluteUrl("/frontoffice/img/logos/logo.png")} alt="Onix.ma"
                                         style={{width: 10.8 + "em"}}/>
                                    <img src={toAbsoluteUrl("/frontoffice/img/logos/logo.png")} alt="Onix.ma"
                                         className="mini-logo"/>
                                </Link>
                            </div>
                            <div className="pull-right pull-sm-up col-sm-2 col-xs-12 websitetitle focus-color">
                                <Link target="_blank" to="/admin/posts" className="row">
                                    <div className="sub-title">Bienvenue!</div>
                                    <h2 className="title">Déposez une annonce</h2>
                                </Link>
                            </div>

                            <div className="pull-right pull-sm-up col-sm-1 col-xs-12 websitetitle p-0 pl-1">
                                <div className="row">
                                    <div className="col-lg-12 p-2 h-50 bg-success">
                                        <Link to="/boutiques" className="">
                                            <div className="title text-white">
                                                <i className="fas fa-store text-white p-2"/>Boutiques
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-12 p-2 h-50 bg-info">
                                        <Link to="/packs" className="">
                                            <div className="title text-white">
                                                <i className="fas fa-box text-white p-2"/>Packs
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/*SearchBar Here*/}
                            <div className="pull-left menu col-sm-6 col-xs-12 pt-4">
                                <FormikProvider value={formik}>
                                    <div className="d-flex row onix_searchbar">

                                        {/*<div className={`flex-fill col-lg-3 m-3`}>*/}
                                        {/*    <AEAutocompleteField*/}
                                        {/*        style={style}*/}
                                        {/*        name="type"*/}
                                        {/*        label={'TRANSACTION'}*/}
                                        {/*        options={types}*/}
                                        {/*        getOptionLabel={opt => opt.label}*/}
                                        {/*        {...fieldProps}*/}
                                        {/*    />*/}
                                        {/*</div>*/}

                                        <div className={`flex-fill col-lg-4 m-2`}>
                                            <AEField
                                                style={style}
                                                name="search"
                                                label={'WHAT_ARE_YOU_LOOKING_FOR'}
                                                {...fieldProps}
                                            />
                                        </div>
                                        {/*<div className={`flex-fill col-lg-3 m-2`}>*/}
                                        {/*    <AEAutocompleteField*/}
                                        {/*        style={style}*/}
                                        {/*        name="category"*/}
                                        {/*        label={'CATEGORY'}*/}
                                        {/*        options={categories.filter(category => category.parrent == null)}*/}
                                        {/*        getOptionLabel={opt => opt.label}*/}
                                        {/*        {...fieldProps}*/}
                                        {/*    />*/}
                                        {/*</div>*/}


                                        <div className={`flex-fill col-lg-4 m-2`}>
                                            <AEAutocompleteField
                                                style={style}
                                                name="city"
                                                label={'CITY'}
                                                options={cities}
                                                getOptionLabel={opt => opt.name}
                                                {...fieldProps}
                                            />
                                        </div>

                                        <div className={`flex-fill col-lg-3 m-2`}>
                                            <AEAutocompleteField
                                                style={style}
                                                name="category"
                                                label={'CATEGORY'}
                                                options={categories.filter(category => category.parrent == null)}
                                                onChange={handleCategoryChange}
                                                getOptionLabel={opt => opt.label}
                                                renderOption={category =>
                                                    <>
                                                        <img src={toEntityFileNameUrl(category, 'categories')}
                                                             className={"mr-2"} alt={` `}
                                                             style={{maxWidth: 16 + "px"}}/>
                                                        {category.label}
                                                    </>
                                                }
                                                {...fieldProps}
                                            />
                                        </div>
                                        {/*<div className={`flex-fill col-lg-3 border m-2`}>*/}
                                        {/*    <ul className="main-navigation" id="main-nav">*/}
                                        {/*        <li>*/}
                                        {/*            <a className="main-navigation_link" href="#">*/}
                                        {/*                Catégories*/}
                                        {/*            </a>*/}
                                        {/*            <ul className="main-navigation">*/}
                                        {/*                {*/}
                                        {/*                    categories &&*/}
                                        {/*                    categories.map((category, index) => {*/}
                                        {/*                        if (!category.parrent) {*/}
                                        {/*                            return (*/}
                                        {/*                                <li key={index}>*/}
                                        {/*                                    <Link*/}
                                        {/*                                        to={'/annonces/by/category/:id'.replace(':id', category.id)}*/}
                                        {/*                                        className="main-navigation_link">*/}
                                        {/*                                        <img*/}
                                        {/*                                            src={toEntityFileNameUrl(category, 'categories')}*/}
                                        {/*                                            className={"mr-2"} alt={` `}*/}
                                        {/*                                            style={{maxWidth: 16 + "px"}}/>*/}
                                        {/*                                        {category.label}*/}
                                        {/*                                    </Link>*/}
                                        {/*                                </li>*/}
                                        {/*                            )*/}
                                        {/*                        }*/}
                                        {/*                    })*/}
                                        {/*                }*/}
                                        {/*            </ul>*/}
                                        {/*        </li>*/}
                                        {/*    </ul>*/}
                                        {/*</div>*/}


                                        {/*<div className={`flex-fill col-lg-3 m-3`}>*/}
                                        {/*    <AEField*/}
                                        {/*        type="number"*/}
                                        {/*        name="priceRange.0"*/}
                                        {/*        label={'MIN_PRICE'}*/}
                                        {/*        {...fieldProps}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        {/*<div className={`flex-fill col-lg-3 m-3`}>*/}
                                        {/*    <AEField*/}
                                        {/*        type="number"*/}
                                        {/*        name="priceRange.1"*/}
                                        {/*        label={'MAX_PRICE'}*/}
                                        {/*        {...fieldProps}*/}
                                        {/*    />*/}
                                        {/*</div>*/}

                                        {/*<div className={`flex-fill col-lg-2 ml-3`}>
                                            {
                                                locations &&
                                                locations.length > 0 &&
                                                <AEAutocompleteField
                                                    style={style}
                                                    name="location"
                                                    label={'LOCATION'}
                                                    options={locations}
                                                    getOptionLabel={opt => opt.name}
                                                    {...fieldProps}
                                                />
                                            }
                                        </div>*/}
                                        {/*<div className={`flex-fill col-lg-6 m-3`}>*/}
                                        {/*</div>*/}
                                        {/*<div className={`flex-fill col-lg-2 mt-3`}>*/}
                                        {/*    <AEButton*/}
                                        {/*        variant={"light"}*/}
                                        {/*        fontWeight={"bolder"}*/}
                                        {/*        size={"sm"}*/}
                                        {/*        onClick={formik.handleSubmit}*/}
                                        {/*    >*/}
                                        {/*        /!*<i className={"fa fa-search"}> Rechercher</i>*!/*/}
                                        {/*        {<AESVG path={"/General/Search.svg"} variant={"primary"}/>}*/}
                                        {/*    </AEButton>*/}
                                        {/*</div>*/}
                                    </div>
                                </FormikProvider>

                                {/*<FormikProvider value={categoriesFormik}>*/}
                                {/*    <div className="d-flex row onix_searchbar">*/}

                                {/*        <div className={`flex-fill col-lg-3 m-2`}>*/}
                                {/*            <AEAutocompleteField*/}
                                {/*                style={style}*/}
                                {/*                name="category"*/}
                                {/*                label={'CATEGORIES'}*/}
                                {/*                options={categories}*/}
                                {/*                getOptionLabel={opt => opt.label}*/}
                                {/*                {...fieldProps}*/}
                                {/*            />*/}
                                {/*        </div>*/}

                                {/*        /!*<div className={`flex-fill col-lg-3 border m-2`}>*!/*/}
                                {/*        /!*    <ul className="main-navigation" id="main-nav">*!/*/}
                                {/*        /!*        <li>*!/*/}
                                {/*        /!*            <a className="main-navigation_link" href="#">*!/*/}
                                {/*        /!*                Catégories*!/*/}
                                {/*        /!*            </a>*!/*/}
                                {/*        /!*            <ul className="main-navigation">*!/*/}
                                {/*        /!*                {*!/*/}
                                {/*        /!*                    categories &&*!/*/}
                                {/*        /!*                    categories.map((category, index) => {*!/*/}
                                {/*        /!*                        if (!category.parrent) {*!/*/}
                                {/*        /!*                            return (*!/*/}
                                {/*        /!*                                <li key={index}>*!/*/}
                                {/*        /!*                                    <Link*!/*/}
                                {/*        /!*                                        to={'/annonces/by/category/:id'.replace(':id', category.id)}*!/*/}
                                {/*        /!*                                        className="main-navigation_link">*!/*/}
                                {/*        /!*                                        <img*!/*/}
                                {/*        /!*                                            src={toEntityFileNameUrl(category, 'categories')}*!/*/}
                                {/*        /!*                                            className={"mr-2"} alt={` `}*!/*/}
                                {/*        /!*                                            style={{maxWidth: 16 + "px"}}/>*!/*/}
                                {/*        /!*                                        {category.label}*!/*/}
                                {/*        /!*                                    </Link>*!/*/}
                                {/*        /!*                                </li>*!/*/}
                                {/*        /!*                            )*!/*/}
                                {/*        /!*                        }*!/*/}
                                {/*        /!*                    })*!/*/}
                                {/*        /!*                }*!/*/}
                                {/*        /!*            </ul>*!/*/}
                                {/*        /!*        </li>*!/*/}
                                {/*        /!*    </ul>*!/*/}
                                {/*        /!*</div>*!/*/}
                                {/*    </div>*/}
                                {/*</FormikProvider>*/}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className="top-box-mask"/>
        </>
    )
}