import React from "react";
import PropTypes from 'prop-types';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import {AESVG} from "./svg";
import {getArrayOfNumbers} from "../helpers/UIHelper";


/* TODO
*   list all icons
*   icon classes
* */


export const AERating = ({value = 0, className, ...props}) => {

    return (
        <div className={className}>
            {
                getArrayOfNumbers(5)
                    .map(number => (
                        <AESVG
                            key={number}
                            variant={number <= value + (Number.isInteger(value) ? 0 : 1) ? 'warning' : 'light'}
                            path={`/General/${!Number.isInteger(value) && number - 1 === parseInt(value) ? 'Half-star' : 'Star'}.svg`}
                            // path={`/General/Star.svg`}
                        />
                    ))
            }
        </div>
    )
}

export const AEResumeRating = ({value = 0, className, ...props}) => {

    return (
        <div className={className}>
            {
                getArrayOfNumbers(5)
                    .map((number, index) => {
                            if (number <= value + (Number.isInteger(value) ? 0 : 1)) {
                                return (
                                    <i key={index} className={"fa resume_icon"}>
                                    {/*    filled star */}
                                        &#xf005;
                                    </i>
                                )
                            }else{
                                return (
                                    <i key={index} className={"fa resume_icon"}>
                                    {/*    empty star*/}
                                        &#xf006;
                                    </i>
                                )
                            }
                        }
                    )}
        </div>
    )
}
AERating.propTypes =
{
    // path: PropTypes.string
}
;