import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useMetadata} from "../../../../_ae/AEPagination";
import {useSelector} from "react-redux";
import {
    useFavoritesListState,
    fetchFavorites,
} from "../../../../redux/store/favorites";
import {FavoriteCard} from "./FavoriteCard"
import Header from "../Header";

export const FavoritesList = () => {
    const dispatch = useDispatch();
    const {data, metadata} = useFavoritesListState()
    const {user} = useSelector(state => state.auth);
    const metadataState = useMetadata(metadata)
    const {page, perPage, sortAsc, filters, sortField, select} = metadataState;


    useEffect(() => {
        dispatch(fetchFavorites(user.id));
    }, [user.id, dispatch, page, perPage, filters.search, select, sortField, sortAsc]);


    return (
        <>
            <Header/>

            <main className="main section-color-primary">
                <div className="container">
                    <section className="top-title">
                        <ul className="breadcrumb">
                            <li className="item"><a href="/"> Acceuil </a></li>
                            <li className="item"> Mes favoris</li>
                        </ul>
                        <h1 className="h-side-title page-title page-title-big text-color-primary">Mes Favoris</h1>
                    </section>
                    <div className="row">
                        <div className="col-md-9">

                            <div className="properties">
                                <div className="row">
                                    {
                                        data &&
                                        data.map(entity => (
                                            <div key={entity.id} className={'col-md-10'}>
                                                <FavoriteCard entity={entity.post}/>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        </>
    );
}
