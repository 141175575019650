import React, {useEffect, useState} from 'react';
import {AEAutocompleteField} from "../../../../../_metronic/_partials/controls/forms/AEField";


export const AttributeCard = ({entity, index, attributes}) => {

    const [subOptions, setSubOptions] = useState([]);
    const [selected, setSelected] = useState(undefined);

    let key = (index * 2)

    const fieldProps = {
        withFeedbackLabel: false,
        validation: false,
        className: 'text-white'
    }
    const changeSelectOptionHandler = (event, value) => {
        attributes[index] = value
        setSelected((entity.subOptions.find(item => value === item)));
    };

    return (
        <>

            <div key={key} className="d-flex form-group col-lg-12">
                <div className={'flex-grow-1'}>
                    <AEAutocompleteField
                        name={`attributes.${key}`}
                        label={entity.label}
                        options={entity.subOptions}
                        getOptionLabel={o => o.label}
                        {...fieldProps}
                        onChange={changeSelectOptionHandler}
                        multiple={entity.multiple === "multiple" ? true : false}
                    />
                </div>
            </div>
            {
                selected &&
                selected.subOptions &&
                selected.subOptions.length > 0 &&
                <AttributeCard entity={selected} index={index} attributes={attributes}/>

            }

        </>
    );
}