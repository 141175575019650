export const CONFIG = {
  roles: {
    U: "ROLE_USER",
    P: "ROLE_PRO",
    E: "ROLE_EDITOR",
    A: "ROLE_ADMIN"
  },
  auth: {
    login: {
      google: false
    },
    register: {
      enabled: true
    },
  }
}

//fixme: duplicated
export const ROLES = CONFIG.roles;


