import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AEAutocompleteField, AEField, AEFileField} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {toAbsoluteApiUrl} from "../../../../../_metronic/_helpers";
import {FieldArray} from "formik";
import {
    fetchCategoriesForSubCategoriesFilter,
    useSubCategoriesFilterState
} from "../../../../../redux/store/categories";

import {
    fetchCitiesForLocationsEdit,
    useCitiesLocationsEditState
} from "../../../../../redux/store/cities";

import axios from "axios";
import {AttributeField} from "./AttributeField";
import {MODULES} from "../../../../../redux/store/helpers";
import {fetchResumes, useResumesListState} from "../../../../../redux/store/resumes";
import {fetchAnnouncers, useAnnouncersListState} from "../../../../../redux/store/announcers";
import {useMetadata} from "../../../../../_ae/AEPagination";

//todo server validation errors
export const Form = ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         setFieldValue,
                         btnRef,
                     }) => {
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.auth);

    const {data: categories} = useSubCategoriesFilterState();
    const {data: announcers} = useAnnouncersListState();
    // const [selectedCity, setSelectedCity] = React.useState(undefined);
    const [selected, setSelected] = React.useState("");
    const [selectedValue, setSelectedValue] = React.useState("");
    const [categorySpecifications, setcategorySpecifications] = React.useState("");
    // const {data: locations} = useLocationsUsersFilterState();
    const {data: cities} = useCitiesLocationsEditState()

    const {data: resumes, metadata} = useResumesListState()
    const metadataState = useMetadata(metadata)


    /* Hooks */
    useEffect(() => {
        if (user && selected?.label?.toString().toLowerCase().includes("emploie")) {
            dispatch(fetchResumes({
                ...metadataState.toAEMetadata(),
                filters: {...metadataState.toAEMetadata().filters, user: {id: user.id}}
            }));
        }
    }, [dispatch, user, selected]);

    {/* todo geolocalisation */
    }
    // navigator.geolocation.getCurrentPosition(function(position) {
    //   console.log("Latitude is :", position.coords.latitude);
    //   console.log("Longitude is :", position.coords.longitude);
    // });

    let options = null;
    const changeSelectOptionHandler = (event, value) => {
        setSelected(value);
    };


    let attributes = null;
    let specifications = null;
    const categoryChangeHandler = (event, value) => {
        if (values.category) {
            axios.get(toAbsoluteApiUrl(`/attributes/by/category/${values.category.id}`))
                .then(res => {
                    setSelectedValue(res.data);
                });

            axios.get(toAbsoluteApiUrl(`/specifications/by/category/${values.category.id}`))
                .then(res => {
                    setcategorySpecifications(res.data);
                });
        }
    };

    useEffect(() => {
        dispatch(fetchCitiesForLocationsEdit())
        dispatch(fetchAnnouncers())
        // dispatch(fetchLocationForUsersFilter())
        dispatch(fetchCategoriesForSubCategoriesFilter())
    }, [dispatch])

    useEffect(() => {
        categoryChangeHandler()
    }, [values.category])


    if (selected) {
        let cat = [];
        cat.push(selected);
        options = selected.subCategories.length > 0 ? selected.subCategories : cat;
    }

    //get the category attributes
    if (selectedValue) {
        attributes = selectedValue;
    }

    //get the category specifications
    if (categorySpecifications) {
        specifications = categorySpecifications;
    }


    return (
        <div className="form form-label-right">

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        type="text"
                        name="title"
                        label={'TITLE'}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        name="description"
                        label={'DESCRIPTION'}
                        multiline
                        type={'textarea'}
                        rows={4}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg-6"}>
                    <AEField
                        name="price"
                        label={'PRICE'}
                    />
                </div>

                <div className={`col-lg-6`}>
                    <AEAutocompleteField
                        name="type"
                        label={'TYPE'}
                        options={['OFFRE', 'DEMANDE', 'LOCATION']}
                        getOptionLabel={o => o}
                    />
                </div>
            </div>

            <div className="form-group row py-1">
                <div className={`col-lg-6`}>
                    <AEAutocompleteField
                        name="state"
                        label={'STATE'}
                        options={['NEUF', 'OCCASION', 'AUTRES']}
                        getOptionLabel={o => o}
                    />
                </div>

                <div className={`col-lg-6`}>
                    <AEAutocompleteField
                        name="city"
                        label={'CITY'}
                        options={cities}
                        getOptionLabel={o => o.name}
                    />
                </div>
            </div>


            <div className="form-group row py-1">
                <div className={`col-lg-6`}>
                    {<AEAutocompleteField
                        name="supercategory"
                        label={'SUPER_CATEGORY'}
                        options={categories.filter(category => category.parrent == null)}
                        getOptionLabel={o => o.label}
                        onChange={changeSelectOptionHandler}
                    />}
                </div>

                <div className={`col-lg-6`}>
                    {
                        options &&
                        <AEAutocompleteField
                            name="category"
                            label={'CATEGORY'}
                            options={options}
                            getOptionLabel={o => o.label}
                            // onChange={categoryChangeHandler}
                        />
                    }
                </div>
            </div>

            {
                selected &&
                (selected.label.toString().toLowerCase().includes("emploie")) &&
                <div className="form-group row py-1">
                    <div className={`col-lg-12`}>
                        {<AEAutocompleteField
                            name="resume"
                            label={'RESUME'}
                            options={resumes}
                            getOptionLabel={o => `${o.fullName} - crée le: ${o.updatedAt}`}
                        />}
                    </div>
                </div>
            }

            <div className="form-group row py-1">
                {
                    attributes &&
                    <FieldArray
                        name="attributes"
                        render={(helpers) => (
                            <>
                                {
                                    attributes._data.map((attribute, index) => (
                                        <AttributeField entity={attribute} index={index}
                                                        attributes={values.attributes} setFieldValue={setFieldValue}/>
                                        // <div key={index} className="d-flex form-group col-lg-6">
                                        //   <div className={'flex-grow-1'}>
                                        //     <AEAutocompleteField
                                        //       name={`attributes.${index}`}
                                        //       label={attribute.label}
                                        //       options={attribute.subOptions}
                                        //       getOptionLabel={o=>o.label}
                                        //     />
                                        //   </div>
                                        // </div>
                                    ))
                                }
                            </>
                        )}
                    />
                }

            </div>

            <div className="form-group row py-1">
                <div className={`col-lg-12`}>
                    {
                        specifications &&
                        specifications._data.length > 0 &&
                        <AEAutocompleteField
                            name="specifications"
                            label={'SPECIFICATIONS'}
                            options={specifications._data}
                            getOptionLabel={o => o.label}
                            multiple
                        />
                    }
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    {
                        announcers &&
                        announcers.length > 0 &&
                        <AEAutocompleteField
                            name="announcer"
                            label={'ANNOUNCER'}
                            options={announcers}
                            getOptionLabel={o => o.firstName + " " + o.lastName}
                        />
                    }
                </div>
            </div>
            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEFileField
                        name="fileName"
                        label={'Miniature'}
                        preview
                        previewPath={`/${MODULES.POSTS}`}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEFileField
                        name="postAttachements"
                        label={'Attachements(Images et Videos)'}
                        multiple
                    />
                </div>
            </div>

            <button
                style={{display: "none"}}
                ref={btnRef}
                onClick={handleSubmit}
            />

        </div>
    )
        ;
}

