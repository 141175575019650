import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
// import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Header from "../Header";
import {fetchNotifications, useNotificationsListState} from "../../../../redux/store/notifications";
import "./NotificationStyle.css"
import {toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import {NotificationsRow} from "./NotificationRow";

export const NotificationsList = () => {
    const dispatch = useDispatch();
    // const {user} = useSelector(state => state.auth);
    const {data} = useNotificationsListState()

    useEffect(() => {
        // if (user) dispatch(fetchBidProcesses(user.id));
        dispatch(fetchNotifications());
    }, [dispatch]);


    return (
        <>
            <Header/>
            <main className="main section-color-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <section className="top-title">
                                <ul className="breadcrumb">
                                    <li className="item">
                                        <Link to="/">Acceuil</Link>
                                    </li>
                                    <li className="item">Notifications</li>
                                </ul>
                            </section>
                            <div className="widget widget-box box-container">

                                <div className="widget-content">
                                    {/*{<ul>*/}
                                    {/*    {*/}
                                    {/*        data &&*/}
                                    {/*        data.map((notification, index) => {*/}
                                    {/*            return (*/}
                                    {/*                <li key={index}>{notification.message}</li>*/}
                                    {/*            )*/}
                                    {/*        })*/}
                                    {/*    }*/}
                                    {/*</ul>}*/}
                                    <div className="box shadow-sm rounded bg-white mb-3">
                                        <div className="box-title border-bottom p-3">
                                            <div className="widget-header text-uppercase m-auto py-2">
                                                <h3>Vos notifications:</h3>
                                            </div>
                                        </div>
                                        <div className="box-body p-0">
                                            {
                                                data &&
                                                data.map((entity, index) => {
                                                    return (
                                                        <NotificationsRow key={index} entity={entity}/>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
