import React from "react";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import {AESVG, SVG_ICON} from "../../../../_ae/components/svg";
import {useIntl} from "react-intl";
import {AELink} from "../../../../_ae/components/AELink";
import {Title} from "./Title";
import {DropdownCustomToggler,} from "../../../../_metronic/_partials/dropdowns";
import {Dropdown} from "react-bootstrap";
import {AERole} from "../../../../_ae/components/AERole";
import {useRoutesForAppState} from "../../../../redux/store/routes";
import {Icon} from "./Icon";
import {AELabel} from "../../../../_ae/components/AELabel";

export const EntityCard = ({
                               entity,
                               size = "md",
                               className = '',
                               avatar,
                               editAction,
                               deleteAction,
                               detailsAction,
                           }) => {

    const {formatMessage} = useIntl()
    const {data: routes, routesLoading} = useRoutesForAppState();
    const classes = {
        xs: {
            card: 'shadow-none ',
            cardBody: 'p-1',
            symbol: 'symbol-40 mr-2',
            title: 'h6',
        },
        sm: {
            card: 'shadow-none ',
            cardBody: 'p-2',
            symbol: 'symbol-70 mr-2',
            title: 'h5',
        },
        md: {
            symbol: 'symbol-70 mr-5',
            cardBody: 'p-3 d-flex flex-column',
            title: 'h4',
        },
        lg: {
            symbol: 'symbol-80 mr-5',
            title: 'h3',
            cardBody: 'p-4',
        },
    }

    const sizeClasses = classes[size];
    const isXs = size === 'xs'

    const actions = [
        {id: 'RESUMES.EDIT', show: editAction},
        {id: 'RESUMES.DELETE', show: deleteAction},
        {id: 'RESUMES.DETAILS', show: detailsAction},
    ].filter(action => action.show);


    return (
        <>
            <Card className={`${className}`}>
                <CardBody className={`${sizeClasses.cardBody}`}>
                    <div className={`row`}>

                        <div className={`${isXs ? '' : 'col-lg-3 align-items-start'}`}>
                            {
                                <Icon entity={entity} className={sizeClasses.symbol} size={'120'}/>
                            }
                        </div>

                        <div className="col-lg-8">
                            <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                                <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                                    <Title entity={entity} fontSize={sizeClasses.title} fontWeight={'bold'}/>
                                </div>

                                {
                                    actions.length > 0 &&
                                    <Dropdown>
                                        <Dropdown.Toggle as={DropdownCustomToggler}>
                                            <AESVG path={SVG_ICON.DOTS} variant={'primary'}/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {
                                                actions
                                                    .map(action => {
                                                        const route = routes.find(r => r.id === action.id);
                                                        if (!route) return '';
                                                        const path = route.path.replace(':id', entity.id)
                                                        return (
                                                            <AERole key={route.id} roles={route.roles}>
                                                                <AELink to={path} className="dropdown-item">
                                                                    <AESVG className={'pr-2'} path={route.svg}/>
                                                                    {formatMessage({id: route.id})}
                                                                </AELink>
                                                            </AERole>
                                                        )
                                                    })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                            </div>

                            <div className="flex-grow-1">
                                {entity.profile &&
                                <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                                    <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                                        {entity.profile}
                                    </div>
                                </div>}
                                <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                                    <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                                        <AELabel variant={"light"}>
                                            Dérniere modification le: {entity.updatedAt}
                                        </AELabel>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}