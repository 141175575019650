import React, {useEffect} from 'react';
import {toEntityFileNameUrl} from "../../../_metronic/_helpers";
import {fetchSlides, useSlidesListState} from "../../../redux/store/slides";
import {useDispatch} from "react-redux";
import {useMetadata} from "../../../_ae/AEPagination";
import {Loader} from "../../../_ae/components/loader";

export function Slider() {

    const dispatch = useDispatch();
    const {data, isLoading, metadata} = useSlidesListState()

    const metadataState = useMetadata(metadata)

    const getEntities = () => {
        dispatch(fetchSlides(metadataState.toAEMetadata()));
    }

    useEffect(() => {
        getEntities()
    }, [dispatch]);


    return (
        <section className="header-slider">

            <div id="header-slider" className="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    {
                        data &&
                        data.map((slide, index) => {
                            return (
                                <li key={index} data-target="#header-slider" data-slide-to={index}
                                    className={`${index === 0 ? 'active' : ''}`}/>
                            );
                        })
                    }</ol>

                <div className="carousel-inner" role="listbox">
                    <Loader isLoading={isLoading}/>
                    {
                        data &&
                        data.map((slide, index) => {
                            return (
                                <div key={index} className={`item ${index === 0 ? 'active' : ''}`}>
                                    <img src={toEntityFileNameUrl(slide, 'slides')} alt={index}/>
                                    <div className="carousel-caption">
                                        <h3 className="carousel-caption-title"><span> {slide.title} </span>
                                            <i className="line-bottom color-primary"/></h3>
                                        <div className="s-description"><p>{slide.detail}</p></div>
                                        {/*<a href="listing.html"
                                    className="btn btn-primary color-primary"><span>Click to show</span></a>*/}
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>

                <a className="right carousel-control" href="#header-slider" role="button" data-slide="next">
                    <span className="icon-next" aria-hidden="true"/>
                    <span className="sr-only">Suivant</span>
                </a>
                <a className="left carousel-control" href="#header-slider" role="button" data-slide="prev">
                    <span className="icon-prev" aria-hidden="true"/>
                    <span className="sr-only">Précedant</span>
                </a>
            </div>
        </section>
    );
}
  