import {toast, ToastContainer} from "react-toastify";
import React, {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {getConfig} from "../../../_metronic/i18n";
// import {useIntl} from "react-intl";
import {AEToaster} from "./AEAlert";
import Pusher from "pusher-js";
import {useDispatch} from "react-redux";

export const AEPushNotifications = ({
                                        variant = 'primary',
                                        channelName = 'my-channel',
                                        eventName = 'my-event'
                                    }) => {

    // const {message, setMessage} = useState("");
    const dispatch = useDispatch();

    function getNotificationMsg(c, e) {
        var pusher = new Pusher('526e35caa03a5cb7c1c6', {
            cluster: 'eu'
        });

        var channel = pusher.subscribe(c);

        let msg = '';
        channel.bind(e, function (data) {
            msg = JSON.stringify(data);

            toast.info(msg);
            // toast('🦄 Wow so easy!', {
            //     position: "bottom-left",
            //     autoClose: 5000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
            // alert(JSON.stringify(data));
        });
        return msg;
    }

    const {rtl} = getConfig();
    let msg = '';
    useEffect(() => {
        msg = getNotificationMsg(channelName, eventName)
    }, [dispatch])

    // // console.log(message)
    // const {rtl} = getConfig();
    // return (
    //     <AEToaster message={message}/>
    // )
    return (
        <div>
            <ToastContainer
                toastClassName={`-bg-info rounded`}
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop
                closeOnClick
                rtl={rtl}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />
        </div>
    );
}

