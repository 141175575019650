import React, {lazy} from "react";
import {Provider} from "react-redux";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {LayoutSplashScreen, MaterialThemeProvider} from "../_metronic/layout";
import {Routes} from "./Routes";
import {I18nProvider} from "../_metronic/i18n";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {AEAlert} from "../_ae/components/toastify/AEAlert";
import store, {persistor} from "../redux/store";

import Footer from './modules/Frontoffice/Footer';
import PostsList from "./modules/Frontoffice/Posts/Home/PostsList";
import {PostDetails} from "./modules/Frontoffice/Posts/PostDetails";

import {FavoritesList} from "./modules/Frontoffice/Favorites";
import {BidList} from "./modules/Frontoffice/BidProcess";
import PostsGrid from "./modules/Frontoffice/Posts/Home/PostsGrid";
import {PostsByCategory} from "./modules/Frontoffice/Posts/postsByCategory/List";
import {PostsByUser} from "./modules/Frontoffice/Posts/postsByUser/List";
import StoresList from "./modules/Frontoffice/Posts/stores/List";
import {StoreDetails} from "./modules/Frontoffice/Posts/stores/StoreDetails";
import ScrollToTop from "./ScrollToTop";
import {PackDetails} from "./modules/Frontoffice/Packs/PackDetails";
import PacksList from "./modules/Frontoffice/Packs/List";
import {NotificationsList} from "./modules/Frontoffice/Notification/NotificationsList";
// import {ErrorsPage} from "./pages/ErrorsPages/ErrorsPage";
import {AEPushNotifications} from "../_ae/components/toastify/AEPushNotifications";
import {PostsByAnnouncer} from "./modules/Frontoffice/Posts/postsByUser/ListbyAnnouncer";
import {TermsOfService} from "./modules/Frontoffice/TermsOfService/TermsOfService";

const Login = lazy(() => import('./modules/Auth/pages/Login'));
const Register = lazy(() => import('./modules/Auth/pages/Registration'));

document.addEventListener('contextmenu', event => event.preventDefault());

export default function App({basename}) {
    return (
        /* Provide Redux store */
        <Provider store={store}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={<LayoutSplashScreen/>}>
                {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
                <React.Suspense fallback={<LayoutSplashScreen/>}>
                    {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                    <BrowserRouter basename={basename}>
                        {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                        <Switch>

                            {/*<Route path="/error" component={ErrorsPage}/>*/}

                            <Route path="/admin">
                                <MaterialThemeProvider>
                                    {/* Provide `react-intl` context synchronized with Redux state.  */}
                                    <I18nProvider>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            {/*<ToastContainer*/}
                                            {/*  position="top-right"//todo*/}
                                            {/*  autoClose={5000}*/}
                                            {/*  hideProgressBar={false}*/}
                                            {/*  newestOnTop={false}*/}
                                            {/*  closeOnClick*/}
                                            {/*  rtl={false}*/}
                                            {/*  pauseOnFocusLoss*/}
                                            {/*  draggable*/}
                                            {/*  pauseOnHover*/}
                                            {/*>aaa</ToastContainer>*/}
                                            <AEAlert/>
                                            {/* Render routes with provided `Layout`. */}
                                            <Routes/>
                                        </MuiPickersUtilsProvider>
                                    </I18nProvider>
                                </MaterialThemeProvider>
                            </Route>


                            <Route path="/">
                                <I18nProvider>
                                    <BrowserRouter>
                                        <ScrollToTop />
                                        <div className="App">
                                            <div id="fb-root"></div>
                                            <div className="container container-wrapper">
                                                {/*<AEAlert/>*/}
                                                <AEPushNotifications channelName={'my-channel'} />

                                                <Switch>
                                                    <Redirect exact from="/" to="/annonces"/>

                                                    <Route path="/admin/auth/login" component={Login}/>
                                                    <Route path="/admin/auth/register" component={Register}/>
                                                    <Route path="/annonces/:id/details">
                                                        <PostDetails/>
                                                    </Route>
                                                    <Route path="/annonces/by/category/:id" component={PostsByCategory}/>
                                                    <Route path="/annonces/by/user/:id" component={PostsByUser}/>
                                                    <Route path="/annonces/by/announcer/:id" component={PostsByAnnouncer}/>
                                                    {/*<Route path="/annonces/featured" component={PostsFeatured}/>*/}
                                                    <Route path="/annonces/list" component={PostsList}/>
                                                    <Route path="/annonces" component={PostsGrid}/>
                                                    <Route path="/boutiques/:id/:storeName" component={StoreDetails}/>
                                                    <Route path="/boutiques" component={StoresList}/>
                                                    <Route path="/bidprocesses" component={BidList}/>
                                                    <Route path="/packs/:id" component={PackDetails}/>
                                                    <Route path="/packs" component={PacksList}/>
                                                    <Route path="/favorites" component={FavoritesList}/>
                                                    <Route path="/notifications" component={NotificationsList}/>
                                                    <Route path="/termsofservice" component={TermsOfService}/>
                                                    <Route exact path="/" component={PostsList}/>
                                                    {/*<Route component={ErrorsPage} />*/}
                                                    {/*<Route path="/featured" component={PostsFeatured} />*/}

                                                </Switch>

                                                <Footer/>

                                                <a className="btn btn-scoll-up color-secondary" id="btn-scroll-up"/>
                                            </div>
                                        </div>
                                    </BrowserRouter>
                                </I18nProvider>
                            </Route>
                        </Switch>
                    </BrowserRouter>
                </React.Suspense>
            </PersistGate>
        </Provider>
    );
}
