import React, {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {
    Card,
    CardBody, CardFooter,
    ModalProgressBar,
} from "../../../../../_metronic/_partials/controls";
import {useIntl} from "react-intl";
import {Formik} from "formik";
import * as Yup from "yup";
import {YupAuthPassword, YupAuthPasswordConfirm} from "../../../../../_ae/yupjs/Schema";
import {
    fetchUserForEdit, resetUserForEdit,
    saveUser,
    useUsersEditState
} from "../../../../../redux/store/users";
import {Form} from "./Form";
import {AEButton} from "../../../../../_ae/components/buttons";

export const Edit = ({history, match: {params: {id},}}) => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    const {isLoading, data, validationErrors} = useUsersEditState();

    useEffect(() => {
        dispatch(
            id ?
                fetchUserForEdit(id) :
                resetUserForEdit()
        );
    }, [id, dispatch]);

    const saveBtnRef = useRef();
    const saveBtnRefClick = () => {
        if (saveBtnRef && saveBtnRef.current) {
            const {current} = saveBtnRef;
            current.click()
        }
    };

    return (
        <Card>
            {isLoading && <ModalProgressBar/>}
            <CardBody className={'pt-10'}>
                <Formik
                    enableReinitialize
                    initialErrors={validationErrors}
                    validationSchema={
                        Yup.object().shape({
                            id: Yup.number().nullable(),
                            firstName: Yup.string().required(),
                            lastName: Yup.string(),
                            email: Yup.string().email().required(),
                            password: Yup.string().when('id', {
                                is: (val) => !val,
                                then: YupAuthPassword()
                            }),
                            _password_confirm: Yup.string().when('id', {
                                is: (val) => !val,
                                then: YupAuthPasswordConfirm()
                            }),
                            city: Yup.object().required(),
                            // roles: Yup.array(),
                            roles: Yup.object().required(),
                        })
                    }
                    initialValues={data}
                    onSubmit={values => {
                        dispatch(saveUser(values)).then(() => {
                            history.goBack();
                        });
                    }}
                    render={formik => (
                        <Form
                            {...formik}
                            btnRef={saveBtnRef}
                        />)}
                />
            </CardBody>
            <CardFooter className={'d-flex justify-content-end'}>
                <AEButton
                    variant={'light'}
                    onClick={history.goBack}
                >
                    <i className="fa fa-arrow-left"/>
                    {formatMessage({id: 'BACK'})}
                </AEButton>
                <AEButton
                    className="ml-2"
                    onClick={saveBtnRefClick}
                >
                    {formatMessage({id: 'SAVE'})}
                </AEButton>
            </CardFooter>
        </Card>
    );
}

