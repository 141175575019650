import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {Formik} from "formik";
import * as Yup from "yup";
import {
    resetStoryForCreate,
    saveStory,
    useStoriesCreateState
} from "../../../../redux/store/stories";
import {AEButton} from "../../../../_ae/components/buttons";
import {AEFileField} from "../../../../_metronic/_partials/controls/forms/AEField";
import {MODULES} from "../../../../redux/store/helpers";
import {AEAvatar} from "../../../../_ae/components/AEAvatar";

//todo server validation errors
export const CreateStroy = ({history}) => {
    const dispatch = useDispatch();
    const {data, validationErrors} = useStoriesCreateState();
    const {user} = useSelector(state => state.auth);


    useEffect(() => {
        dispatch(resetStoryForCreate());
    }, [dispatch]);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialErrors={validationErrors}
                validationSchema={
                    Yup.object().shape({
                        fileName: Yup.mixed(),
                    })
                }
                initialValues={data}
                onSubmit={({fileName, ...values}) => {
                    values.author = user;
                    dispatch(saveStory(values, {fileName})).then(() => {
                        window.location.reload()
                    });
                    // resetForm(data)
                }}
                render={formik => (
                    <Form
                        {...formik}
                    />)}
            />
        </>

    );
}

const Form = ({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
              }) => {

    const {formatMessage} = useIntl();
    const {user} = useSelector(state => state.auth);

    return (
        <div className="card mb-2 story-card">
            <div className="form-group m-3 add-story-form">
                <div className="card-body row h-75">
                    <div className="col-lg-12">
                        {
                            !(values.fileName) &&
                                <AEAvatar entity={user} className={"card-img-top"} size={150}/>
                            // <img className="card-img-top"
                            //      src={toEntityFileNameUrl(user, 'users')}
                            //      alt="Card image cap p-2"/>
                        }
                        <AEFileField
                            className={"p-0"}
                            name="fileName"
                            label={"ADD_TO_STORY"}
                            withFeedbackLabel={false}
                            preview
                            previewPath={`/${MODULES.STORIES}`}
                        />
                    </div>
                    <div className="col-lg-12">
                        {
                            values.fileName &&
                            <AEButton
                                className="mx-3 my-2"
                                onClick={handleSubmit}
                                variant={"light"}
                            >
                                <i className="fa fa-plus-circle mr-2"/>
                                {formatMessage({id: 'ADD_TO_STORY'})}
                            </AEButton>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}



