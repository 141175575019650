import React, {useEffect} from 'react';
import {Pagination} from "../../../../../_metronic/_partials/controls";
import {useDispatch} from "react-redux";
import {useMetadata} from "../../../../../_ae/AEPagination";
import {PostListCard} from "../PostListCard";
import {Link, useParams} from "react-router-dom";
import {fetchPostsByUser, usePostsByUserListState} from "../../../../../redux/store/posts";
import {fetchUserForEdit, useUsersEditState} from "../../../../../redux/store/users";
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../../_metronic/_helpers";

import {MainHeader} from "../../MainHeader";
import SVG from "react-inlinesvg";


export function PostsByUser() {

    const dispatch = useDispatch();
    const {id} = useParams();

    const {data: postsByUser, isLoading, metadata} = usePostsByUserListState()
    const {data} = useUsersEditState();


    const metadataState = useMetadata(metadata)
    const {page, perPage, sortAsc, filters, sortField, select} = metadataState;

    const getEntities = () => {
        dispatch(fetchPostsByUser(id, metadataState.toAEMetadata()));
    }

    /* Hooks */
    useEffect(() => {
        getEntities()
    }, [id, dispatch, page, perPage, filters.search, select, sortField, sortAsc]);

    useEffect(() => {
        dispatch(fetchUserForEdit(id));
    }, [id, dispatch]);

    return (
        <>
            <header className="header">
                <MainHeader {...metadataState}/>
                <div className="container">

                </div>
            </header>

            <main className="main section-color-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="h-side top-pad h-side-slim clearfix">
                                <div className="properties-filter breadcrumb">
                                    <div className="form-group">
                                        <Link to='/' style={{textDecoration: 'underline'}}>
                                            Toutes les annonces >>
                                        </Link>
                                        {
                                            data &&
                                            <span className="agent-name text-color-primary m-2">
                                                   Annnoces de : {data.firstName}{` `}{data.lastName}
                                            </span>
                                        }

                                    </div>
                                    <div className="grid-type pull-right">

                                    </div>
                                </div>
                            </div>
                            <div className="properties">
                                <div className="row">
                                    {
                                        postsByUser &&
                                        postsByUser.map((post, index) => {
                                            return (
                                                <PostListCard key={index} entity={post}/>
                                            );
                                        })
                                    }
                                </div>

                                <Pagination {...metadataState} counts={[12, 24, 48]}/>

                            </div>
                        </div>

                        <div className="col-md-3 sidebar-right">
                            <div className="widget widget-box box-container widget-properties">
                                <div className="widget-header text-uppercaser">
                                    Details d'Annonceur:
                                </div>
                                <div className="properties">
                                    <div className="row">
                                        {
                                            data &&
                                            <div className="media">
                                                <div className="agent-logo media-left media-middle">
                                                    <div className="img-circle-cover">
                                                        {
                                                            data.fileName ?
                                                                <img src={toEntityFileNameUrl(data, 'users')} alt=""
                                                                     className="img-circle"/>
                                                                :
                                                                <span className="symbol-label">
                                                                    <SVG
                                                                        className="h-100 align-self-end"
                                                                        src={toAbsoluteUrl("/media/svg/avatars/004-boy-1.svg")}
                                                                    />
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="agent-details media-right media-top">
                                                    <span className=""
                                                          style={{direction: "ltr"}}>
                                                        {data.firstName}{` `}{data.lastName}
                                                    </span>
                                                    <br/>
                                                    {
                                                        data.phone &&
                                                        <>
                                                             <span className="phone"
                                                                   style={{direction: "ltr"}}>
                                                                {data.phone ? data.phone : ''}
                                                             </span>
                                                            <br/>
                                                        </>
                                                    }
                                                    <a href={`mailto:` + data.email}
                                                       className="mail text-color-primary">{data.email}
                                                    </a>
                                                    <br/>
                                                    <span className=""
                                                          style={{direction: "ltr"}}>
                                                        Total des articles: {data.totalOfPosts ? data.totalOfPosts : ''}
                                                    </span>
                                                    {/*<ul className="list-overview">*/}
                                                    {/*    <li>*/}
                                                    {/*        <AERating value={5} className={''}/>*/}
                                                    {/*    </li>*/}
                                                    {/*</ul>*/}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/*<div className="widget widget-ads-right">*/}
                            {/*    <img src={toAbsoluteUrl("frontoffice/img/placeholders/265x220.png")} alt=""*/}
                            {/*         className="center-block"/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}



