import React from "react";
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export const Miniature =({entity, className = '', size = 35}) =>{
    return (
        <div className={`symbol ${className} symbol-${size}`}>
          {
            entity.fileName ?
              <img src={toEntityFileNameUrl(entity, 'groups')} alt={` `}
              className="mw-75 h-50 align-self-center"/>
              :
              <span className="symbol-label">
              <SVG
                className="mw-100 h-50 align-self-center"
                src={toAbsoluteUrl("/media/svg/gallery.svg")}
              />
            </span>
          }
          {/*<i className="symbol-badge bg-primary"/>*/}
        </div>
        )
    }   