import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import * as Yup from "yup";
import {
    resetFavoriteForCreate,
    saveFavorite, deleteFavorite, fetchFavorites, useFavoritesListState, useFavoritesDeleteState
} from "../../../../redux/store/favorites";


export const FavoriteForm = ({
                                 values,
                                 errors,
                                 handleChange,
                                 handleBlur,
                                 handleSubmit,
                                 isSubmitting,
                                 post
                             }) => {
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.auth);

    const {data: favorites} = useFavoritesListState();
    const [isFavorite, setIsFavorite] = useState(false)
    const [favorite, setFavorite] = useState(undefined)
    /* Hooks */
    useEffect(() => {
        if (user) {
            dispatch(fetchFavorites(user.id));
        }
    }, [user, dispatch]);

    useEffect(() => {
        dispatch(resetFavoriteForCreate());
    }, [dispatch]);


    useEffect(() => {
        if (favorites && post) {
            if (handleCheck(post)) {
                setIsFavorite(true);
                setFavorite((favorites.find(item => post.id === item.post.id)));
            }
        }
    }, [favorites, post, dispatch]);

    const handleCheck = (val) => {
        return favorites.some(item => val.id === item.post.id);
    }


    return (
        <Formik
            enableReinitialize
            initialValues={
                {
                    user: undefined,
                    post: undefined
                }
            }
            validationSchema={
                Yup.object().shape({
                    user: Yup.object(),
                    post: Yup.object()
                })
            }
            onSubmit={(values) => {
                values.user = user;
                values.post = post;
                if (!user) {
                    alert('Vous devez être connecté pour ajouter cet article aux favoris!')
                }
                if (!isFavorite) {
                    dispatch(saveFavorite(values))
                } else {
                    dispatch(deleteFavorite(favorite.id))
                }
            }}
            render={formik => (
                isFavorite ?
                    <button className="btn"
                            onClick={formik.handleSubmit}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24">
                            <path
                                d="M19.5 10c-2.483 0-4.5 2.015-4.5 4.5s2.017 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.017-4.5-4.5-4.5zm2.5 5h-5v-1h5v1zm-6.527 4.593c-1.108 1.086-2.275 2.219-3.473 3.407-6.43-6.381-12-11.147-12-15.808 0-6.769 8.852-8.346 12-2.944 3.125-5.362 12-3.848 12 2.944 0 .746-.156 1.496-.423 2.253-1.116-.902-2.534-1.445-4.077-1.445-3.584 0-6.5 2.916-6.5 6.5 0 2.063.97 3.901 2.473 5.093z"/>
                            <title>Supprimer favoris</title>
                        </svg>
                    </button>
                    :
                    <button className="btn "
                            onClick={formik.handleSubmit}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24">
                            <path
                                d="M19.5 10c-2.483 0-4.5 2.015-4.5 4.5s2.017 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.017-4.5-4.5-4.5zm2.5 5h-2v2h-1v-2h-2v-1h2v-2h1v2h2v1zm-6.527 4.593c-1.108 1.086-2.275 2.219-3.473 3.407-6.43-6.381-12-11.147-12-15.808 0-4.005 3.098-6.192 6.281-6.192 2.197 0 4.434 1.042 5.719 3.248 1.279-2.195 3.521-3.238 5.726-3.238 3.177 0 6.274 2.171 6.274 6.182 0 .746-.156 1.496-.423 2.253-.527-.427-1.124-.768-1.769-1.014.122-.425.192-.839.192-1.239 0-2.873-2.216-4.182-4.274-4.182-3.257 0-4.976 3.475-5.726 5.021-.747-1.54-2.484-5.03-5.72-5.031-2.315-.001-4.28 1.516-4.28 4.192 0 3.442 4.742 7.85 10 13l2.109-2.064c.376.557.839 1.048 1.364 1.465z"/>
                            <title>Ajouter aux favoris</title>
                        </svg>
                    </button>
            )}
        />
    )
}




