import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {Loader} from "../../../../_ae/components/loader";
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import {Location} from "../../../pages/Location";
import {AECurrency} from "../../../../_ae/components/Currency";
// import {AERating} from "../../../../_ae/components/rating";
import {
    fetchPostForDetails,
    fetchPostsByCategory,
    usePostDetailsState,
    usePostsByCategoryListState
} from "../../../../redux/store/posts";
// import {
//     useReviewsListState,
//     fetchReviews,
// } from "../../../../redux/store/reviews";

// import {ReviewForm} from "../Reviews/Form";
// import {ReviewCard} from "../Reviews/ReviewCard";
import Header from "../Header";
import {FavoriteForm} from "../Favorites/Form";
import {BidProcessForm} from "../BidProcess/create";
import {PostCard} from "./PostCard";
import {AEShareButtons} from "../../../../_ae/components/AEShareButtons";
import {AEQRCode} from "../../../../_ae/components/AEQRCode";
// import {fetchUserForEdit, useUsersEditState} from "../../../../redux/store/users";
import {DetailsCard} from "../../Resumes/components/DetailsCard";
import {getNonAccentString} from "../../../../_ae/components/AEUtils";

export function PostDetails() {
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.auth);
    const {isLoading, data} = usePostDetailsState()
    // const {data: reviews} = useReviewsListState();
    const {id} = useParams();
    const [attachments, setAttachments] = useState([])
    const {data: postsByCategory} = usePostsByCategoryListState()
    // const {data: author} = useUsersEditState();
    let author = data.announcer ? data.announcer : data.author;

    let storeName = "";
    if (author && author.type === "STORE") {
        storeName = getNonAccentString(data.author.store?.name);
    }

    useEffect(() => {
        setAttachments([]);
        dispatch(fetchPostForDetails(id));
    }, [id, dispatch]);
    //
    // useEffect(() => {
    //     if (data && data.author) {
    //         dispatch(fetchUserForEdit(data.author.id));
    //     }
    // }, [data, dispatch]);

    // useEffect(() => {
    //     if (data && data.author) {
    //         dispatch(fetchReviews(data.author.id));
    //     }
    // }, [data, dispatch]);

    useEffect(() => {
        setAttachments(getAttachments(data.postAttachements));

        if (data && data.category) dispatch(fetchPostsByCategory(data.category.id));
    }, [id, data, dispatch]);


    const getVideosOrNull = (attachments) => {
        let videos = [];
        attachments.forEach(attachment => {
            const splittedFileName = attachment.fileName.split(".");
            if (splittedFileName[splittedFileName.length - 1] === "mp4") {
                videos.push(attachment);
            }
        });
        return videos;
    };

    const getImagesOrNull = (attachments) => {
        let images = [];
        attachments.map(attachment => {
            const splittedFileName = attachment.fileName.split(".");
            if (splittedFileName[splittedFileName.length - 1] !== "mp4") {
                images.push(attachment);
            }
        });
        return images;
    };

    const getAttachments = (attachments) => {
        let attachemnts = [];
        getVideosOrNull(attachments).forEach(video => {
            attachemnts.push(video);
        });

        getImagesOrNull(attachments).forEach(img => {
            attachemnts.push(img);
        });
        return attachemnts;
    }


    return (
        <>
            <Header/>
            <Loader isLoading={isLoading}/>
            <main className="main section-color-primary">
                <div className="container">
                    <section className="top-title">
                        <ul className="breadcrumb">
                            <li className="item"><a href="/"> Acceuil </a></li>
                            <li className="item"> Details de l'annonce</li>
                        </ul>
                        <h1 className="h-side-title page-title page-title-big text-color-primary">{data.title}</h1>
                    </section>
                    <div className="row">
                        <div className="col-md-9">
                            <div className="widget widget-box box-container widget-property">
                                <div className="property-slider-box">
                                    <div id="property-slider" className="property-slider carousel slide"
                                         data-ride="carousel">

                                        <div className="carousel-inner d-flex justify-content-center" role="listbox">
                                            {
                                                attachments &&
                                                attachments.length > 0 &&
                                                attachments.length === data.postAttachements.length &&
                                                attachments.map((attachment, index) => {
                                                    const splittedFileName = attachment.fileName.split(".");
                                                    if (splittedFileName[splittedFileName.length - 1] === "mp4") {
                                                        return (
                                                            <>
                                                                <div key={index}
                                                                     className={`item ${index === 0 ? 'active' : ''}`}>
                                                                    <video width="600" height="300"
                                                                           className="align-self-center my-4"
                                                                           style={{maxWidth: 100 + '%'}}
                                                                           autoPlay muted controls>
                                                                        <source
                                                                            src={toEntityFileNameUrl(attachment, 'postAttachements')}
                                                                            type="video/mp4"/>
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                </div>
                                                            </>
                                                        )
                                                    } else {
                                                        return (
                                                            <div key={index}
                                                                 className={`item ${index === 0 ? 'active' : ''}`}>
                                                                <img
                                                                    src={toEntityFileNameUrl(attachment, 'postAttachements')}
                                                                    alt={` `}
                                                                    className="align-self-center my-4 order-lg-last d-lg-flex "
                                                                    style={{maxWidth: 100 + '%'}}/>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                <div
                                                    className={`item ${data.postAttachements && data.postAttachements.length === 0 ? 'active' : ''}`}>
                                                    {data.fileName ?
                                                        <img src={toEntityFileNameUrl(data, 'posts')} alt={data.title}/>
                                                        :
                                                        <SVG src={toAbsoluteUrl("/media/svg/gallery.svg")}
                                                             style={{maxWidth: 75 + '%'}}/>}
                                                </div>
                                            }
                                        </div>

                                        <a className="left carousel-control" href="#property-slider" role="button"
                                           data-slide="prev">
                                            <span className="icon-prev" aria-hidden="true"/>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="right carousel-control" href="#property-slider" role="button"
                                           data-slide="next">
                                            <span className="icon-next" aria-hidden="true"/>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="widget-body">
                                    <div className="widget-header widget-title text-uppercase">
                                        <h2>Description</h2>
                                    </div>
                                    <div className="widget-content wide-p">
                                        <p className='clearfix'>
                                            {data.description}
                                        </p>
                                    </div>

                                    <div className="separator separator-solid my-2"/>
                                    <div className="property-preview-f-left text-color-primary mt-3">
                                        <span className="property-card-value">
                                            Partager :
                                        </span>

                                        <AEShareButtons pathname={'/annonces/:id/details'.replace(':id', data.id)}
                                                        facebook={true} twitter={true} whatsapp={true} telegram={true}/>
                                    </div>

                                </div>
                            </div>
                            {
                                data.resume &&
                                <div className="widget widget-box box-container widget-property">
                                    {/*<div className="widget-header widget-title text-uppercase ml-6">*/}
                                    {/*    <h2>CV :</h2>*/}
                                    {/*</div>*/}
                                    <DetailsCard
                                        entity={data.resume}
                                        className="mb-0 card-border"
                                    />
                                </div>
                            }

                            <div className="widget widget-box box-container widget-overview visible-sm visible-xs">
                                <div className="widget-header text-uppercase d-flex justify-content-lg-between">
                                    <h2>Details</h2>
                                    <FavoriteForm user={user} post={data}/>
                                </div>
                                <ul className="list-overview">
                                    <li className="custom-address">
                                        <span className="list-overview-option">Prix</span>
                                        <span className="list-overview-value" title="">
                                                <AECurrency value={data.price}/>
                                                </span>
                                    </li>

                                    <li className="custom-address">
                                        <span className="list-overview-option">Transaction</span>
                                        <span className="list-overview-value" title="">{data.type}</span>
                                    </li>
                                    <li className="custom-address">
                                        <span className="list-overview-option">Catégorie</span>
                                        <span className="list-overview-value"
                                              title="">{data.category ? data.category.label : ''}</span>
                                    </li>
                                    <li className="custom-address">
                                        <span className="list-overview-option">Location</span>
                                        <span className="list-overview-value" title="">
                                                <Location location={data.location}/>
                                                </span>
                                    </li>
                                    {
                                        data.postAttributes &&
                                        data.postAttributes.map((postAttribute, index) => {
                                            return (
                                                <li key={index} className="custom-address">
                                                <span
                                                    className="list-overview-option">{postAttribute.attribute.parrent.label}</span>
                                                    <span className="list-overview-value"
                                                          title="Vatikanska 11, Zagreb">{postAttribute.attribute.label}</span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            {
                                data.specifications &&
                                data.specifications.length > 0 &&
                                <div className="widget widget-box box-container">
                                    <div className="widget-header text-uppercase">
                                        <h2>Spécifications </h2>
                                    </div>
                                    <ul className="amenities text-color-primary clearfix">
                                        {
                                            data.specifications.length > 0 &&
                                            data.specifications.map((specification, index) => {
                                                return (
                                                    <li key={index}>
                                                        <div className={`symbol symbol-35`}>
                                                            <img
                                                                src={toEntityFileNameUrl(specification, 'specifications')}
                                                                alt={` `}
                                                                className="h-25 align-self-center"
                                                                style={{display: "initial"}}/>
                                                        </div>
                                                        <span>{specification.label}</span>
                                                        <i className="icon-checked"/>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            }
                            {/*<div className="widget widget-box box-container">*/}
                            {/*    <div className="widget-header text-uppercase">*/}
                            {/*        <h2>Reviews</h2>*/}
                            {/*    </div>*/}
                            {/*    <ReviewForm*/}
                            {/*        user={user}*/}
                            {/*        data={data}*/}
                            {/*    />*/}

                            {/*    <div className="row">*/}
                            {/*        <div className="col-sm-7">*/}
                            {/*            <hr/>*/}
                            {/*            <div className="review-block">*/}
                            {/*                {*/}
                            {/*                    reviews &&*/}
                            {/*                    reviews.map(review => {*/}
                            {/*                        return (*/}
                            {/*                            <ReviewCard entity={review}/>*/}
                            {/*                        )*/}
                            {/*                    })*/}
                            {/*                }*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="widget widget-profilelisting">
                                <div className="widget-body">
                                    <div className="widget-header text-uppercase">
                                        <h2>Annonces Associés</h2>
                                    </div>
                                </div>
                                <div className="properties">
                                    <div className="row">
                                        {
                                            postsByCategory &&
                                            postsByCategory.slice(0, 6).map((entity, index) => (
                                                entity.id !== data.id ?
                                                    <PostCard key={index} entity={entity}/>
                                                    :
                                                    ''
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="widget widget-box box-container widget-agent">
                                {
                                    author &&
                                    <div className="media">
                                        <div className="agent-logo media-left media-middle">
                                            <div className="img-circle-cover">
                                                {
                                                    author && author.fileName ?
                                                        <img src={toEntityFileNameUrl(author, 'users')} alt=""
                                                             className="img-circle"/>
                                                        :
                                                        <span className="symbol-label">
                                                            <SVG
                                                                  className="h-100 align-self-end"
                                                                src={toAbsoluteUrl("/media/svg/avatars/004-boy-1.svg")}
                                                            />
                                                        </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="agent-details media-right media-top">

                                            {
                                                data && data.announcer ?
                                                    <Link to={'/annonces/by/announcer/:id'.replace(':id', author.id)}
                                                          className="agent-name text-color-primary">
                                                        {author.firstName}{` `}{author.lastName}
                                                    </Link>
                                                    :
                                                    <Link to={'/annonces/by/user/:id'.replace(':id', author.id)}
                                                          className="agent-name text-color-primary">
                                                        {author.firstName}{` `}{author.lastName}
                                                    </Link>
                                            }
                                            <span className="phone"
                                                  style={{direction: "ltr"}}>{author.phone}</span>
                                            <a href={`mailto:` + author.email}
                                               className="mail text-color-primary">{author.email}
                                            </a>
                                            {
                                                data && data.announcer ?
                                                    <ul className="list-overview">
                                                        <Link
                                                            to={'/annonces/by/announcer/:id'.replace(':id', author.id)}
                                                            className="agent-name text-color-primary">
                                                            <span
                                                                className={`border label label-lg label-inline label-success m-1 py-2 font-weight-bold`}>
                                                                {author.totalOfPosts ? author.totalOfPosts : '0'} Article(s)
                                                            </span>
                                                        </Link>
                                                        {/*<li>*/}
                                                        {/*    <AERating value={author.rating} className={''}/>*/}
                                                        {/*</li>*/}
                                                    </ul>
                                                    :
                                                    <ul className="list-overview">
                                                        <Link to={'/annonces/by/user/:id'.replace(':id', author.id)}
                                                              className="agent-name text-color-primary">
                                                            <span
                                                                className={`border label label-lg label-inline label-success m-1 py-2 font-weight-bold`}>
                                                                {author.totalOfPosts ? author.totalOfPosts : '0'} Article(s)
                                                            </span>
                                                        </Link>
                                                        {/*<li>*/}
                                                        {/*    <AERating value={author.rating} className={''}/>*/}
                                                        {/*</li>*/}
                                                    </ul>
                                            }
                                        </div>

                                    </div>
                                }
                                {
                                    author &&
                                    author.type === "STORE" &&
                                    <div className="agent-detail-picture mw-25">
                                        <AEQRCode
                                            pathname={'boutiques/:id/:storeName'.replace(':id', data.id).replace(':storeName', storeName)}
                                        />
                                    </div>
                                }
                            </div>

                            <div className="widget widget-box box-container widget-overview hidden-sm  hidden-xs">
                                <div className="widget-header text-uppercase d-flex justify-content-lg-between">
                                    <h2>Details</h2>
                                    <FavoriteForm post={data}/>
                                </div>
                                <ul className="list-overview">
                                    <li className="custom-address">
                                        <span className="list-overview-option">Prix</span>
                                        <span className="list-overview-value" title="">
                                                <AECurrency value={data.price}/>
                                                </span>
                                    </li>
                                    <li className="custom-address">
                                        <span className="list-overview-option">Transaction</span>
                                        <span className="list-overview-value" title="">{data.type}</span>
                                    </li>
                                    <li className="custom-address">
                                        <span className="list-overview-option">Date de pub.</span>
                                        <span className="list-overview-value" title="">
                                               {data.createdAt}
                                        </span>
                                    </li>
                                    <li className="custom-address">
                                        <span className="list-overview-option">Catégorie</span>
                                        <span className="list-overview-value"
                                              title="">{data.category ? data.category.label : ''}</span>
                                    </li>
                                    <li className="custom-address">
                                        <span className="list-overview-option">Ville</span>
                                        <span className="list-overview-value" title="">
                                                {data.city ? data.city.name : 'Non spécifié'}
                                        </span>
                                    </li>

                                    <li className="custom-address">
                                        <span className="list-overview-option">L'état</span>
                                        <span className="list-overview-value" title="">
                                                {data.state ? data.state : 'Non spécifié'}
                                        </span>
                                    </li>
                                    {
                                        data.postAttributes &&
                                        data.postAttributes.map(postAttribute => {
                                            return (
                                                <li className="custom-address">
                                                <span
                                                    className="list-overview-option">{postAttribute.attribute.parrent.label}</span>
                                                    <span className="list-overview-value"
                                                          title="">{postAttribute.attribute.label}</span>
                                                </li>
                                            )
                                        })
                                    }
                                    {/*<li>*/}
                                    {/*    <span className="list-overview-option">Rating</span>*/}
                                    {/*    <AERating value={5} className={'list-overview-value'}/>*/}
                                    {/*</li>*/}
                                </ul>
                            </div>

                            <div className="widget widget-form" id="form">
                                <form action="#" method="post">
                                    <div className="box-container widget-body">
                                        <div className="widget-header text-uppercaser">
                                            <h2>Proposer </h2>
                                            <h2>une offre</h2>
                                        </div>
                                        <div className="form-additional">
                                            <BidProcessForm entity={data}/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/*<div className="widget widget-ads-right">*/}
                            {/*    <img src={toAbsoluteUrl("assets/img/placeholders/265x220.png")} alt=""*/}
                            {/*         className="center-block"/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}