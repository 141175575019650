import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {
    usersDeleteName, usersDeleteReducer,
    usersEditName,
    usersEditReducer,
    usersListName,
    usersListReducer
} from "./store/users";
import {
    locationsDeleteName, locationsDeleteReducer,
    locationsEditName,
    locationsEditReducer,
    locationsListName,
    locationsListReducer, locationsUsersFilterName, locationsUsersFilterReducer
} from "./store/locations";
import {
    citiesDeleteName,
    citiesDeleteReducer,
    citiesEditName,
    citiesEditReducer,
    citiesListName,
    citiesListReducer,
    citiesLocationsEditName,
    citiesLocationsEditReducer,
    citiesLocationsFilterName,
    citiesLocationsFilterReducer
} from "./store/cities";
import {
    countriesDeleteName,
    countriesDeleteReducer,
    countriesEditName,
    countriesEditReducer,
    countriesListName,
    countriesListReducer,
    countriesCitiesEditName,
    countriesCitiesEditReducer,
    countriesCitiesFilterName,
    countriesCitiesFilterReducer
} from "./store/countries";
import {
    routesDeleteName, routesDeleteReducer,
    routesEditName,
    routesEditReducer,
    routesForAppName, routesForAppReducer, routesForRolesEditName, routesForRolesEditReducer,
    routesListName,
    routesListReducer
} from "./store/routes";
import {
    rolesDeleteName,
    rolesDeleteReducer,
    rolesEditName,
    rolesEditReducer,
    rolesListName,
    rolesListReducer
} from "./store/roles";
import {
    specificationsEditName,
    specificationsEditReducer,
    specificationsListName,
    specificationsListReducer,
    specificationsByCategoryListName,
    specificationsByCategoryListReducer,
    specificationsDeleteName,
    specificationsDeleteReducer
} from "./store/specifications";
import {
    categoriesEditName,
    categoriesEditReducer,
    categoriesListName,
    categoriesListReducer,
    categoriesDeleteName,
    categoriesDeleteReducer,
    subCategoriesEditName,
    subCategoriesEditReducer,
    subCategoriesFilterName,
    subCategoriesFilterReducer
} from "./store/categories";
import {
    attributesEditName,
    attributesEditReducer,
    attributesListName,
    attributesListReducer,
    attributesByCategoryListName,
    attributesByCategoryListReducer,
    attributesDeleteName,
    attributesDeleteReducer,
    subOptionsEditName,
    subOptionsEditReducer,
    subOptionsFilterName,
    subOptionsFilterReducer
} from "./store/attributes";
import {
    postsListName,
    postsListReducer,
    allPostsListName,
    allPostsListReducer,
    featuredPostsListName,
    featuredPostsListReducer,
    postsByCategoryListName,
    postsByCategoryListReducer,
    postsByUserListName,
    postsByUserListReducer,
    postsByAnnouncerListName,
    postsByAnnouncerListReducer,
    postsEditName,
    postsEditReducer,
    postsDeleteName,
    postsDeleteReducer,
    postDetailsName,
    postDetailsReducer,
} from "./store/posts";
import {
    postGroupsListName,
    postGroupsListReducer,
    postGroupsByCategoryListName,
    postGroupsByCategoryListReducer,
    postGroupsEditName,
    postGroupsEditReducer,
    postGroupsDeleteName,
    postGroupsDeleteReducer,
    postGroupsDetailsName,
    postGroupsDetailsReducer,
} from "./store/groups";
import {
    slidesListName,
    slidesListReducer,
    slidesEditName,
    slidesEditReducer,
    slidesDeleteName,
    slidesDeleteReducer,
} from "./store/slides";
import {
    favoritesListName,
    favoritesListReducer,
    favoritesCreateName,
    favoritesCreateReducer,
    favoritesDeleteName,
    favoritesDeleteReducer,
} from "./store/favorites";
import {
    bidProcessListName,
    bidProcessListReducer,
    bidProcessCreateName,
    bidProcessCreateReducer,
    bidProcessDeleteName,
    bidProcessDeleteReducer,
} from "./store/bidProcess";
import {
    reviewsListName,
    reviewsListReducer,
    reviewsEditName,
    reviewsEditReducer,
    reviewsDeleteName,
    reviewsDeleteReducer,
} from "./store/reviews";
import {
    storiesListName,
    storiesListReducer,
    storiesCreateName,
    storiesCreateReducer,
    storiesDeleteName,
    storiesDeleteReducer,
} from "./store/stories";
import {
    announcersListName,
    announcersListReducer,
    announcersEditName,
    announcersEditReducer,
    announcersDeleteName,
    announcersDeleteReducer,
} from "./store/announcers";
import {
    resumesListName,
    resumesListReducer,
    resumesEditName,
    resumesEditReducer,
    resumesDeleteName,
    resumesDeleteReducer,
    resumeDetailsName,
    resumeDetailsReducer,
} from "./store/resumes";
import {
    notificationsListName,
    notificationsListReducer,
} from "./store/notifications";
import {authReducers} from "./store/auth";
export const rootReducer = combineReducers({
    auth: auth.reducer,
    ...authReducers,
    //users
    [usersListName]: usersListReducer,
    [usersEditName]: usersEditReducer,
    [usersDeleteName]: usersDeleteReducer,
    //locations
    [locationsListName]: locationsListReducer,
    [locationsEditName]: locationsEditReducer,
    [locationsDeleteName]: locationsDeleteReducer,
    [locationsUsersFilterName]: locationsUsersFilterReducer,
    //cities
    [citiesListName]: citiesListReducer,
    [citiesEditName]: citiesEditReducer,
    [citiesDeleteName]: citiesDeleteReducer,
    [citiesLocationsFilterName]: citiesLocationsFilterReducer,
    [citiesLocationsEditName]: citiesLocationsEditReducer,
    //countries
    [countriesListName]: countriesListReducer,
    [countriesEditName]: countriesEditReducer,
    [countriesDeleteName]: countriesDeleteReducer,
    [countriesCitiesFilterName]: countriesCitiesFilterReducer,
    [countriesCitiesEditName]: countriesCitiesEditReducer,
    //roles
    [rolesListName]: rolesListReducer,
    [rolesEditName]: rolesEditReducer,
    [rolesDeleteName]: rolesDeleteReducer,
    //routes
    [routesListName]: routesListReducer,
    [routesEditName]: routesEditReducer,
    [routesDeleteName]: routesDeleteReducer,
    [routesForAppName]: routesForAppReducer,
    [routesForRolesEditName]: routesForRolesEditReducer,
    //specifications
    [specificationsListName]: specificationsListReducer,
    [specificationsEditName]: specificationsEditReducer,
    [specificationsByCategoryListName]: specificationsByCategoryListReducer,
    [specificationsDeleteName]: specificationsDeleteReducer,
    //categories
    [categoriesListName]: categoriesListReducer,
    [categoriesEditName]: categoriesEditReducer,
    [categoriesDeleteName]: categoriesDeleteReducer,
    [subCategoriesFilterName]: subCategoriesFilterReducer,
    [subCategoriesEditName]: subCategoriesEditReducer,
    //attributes
    [attributesListName]: attributesListReducer,
    [attributesByCategoryListName]: attributesByCategoryListReducer,
    [attributesEditName]: attributesEditReducer,
    [attributesDeleteName]: attributesDeleteReducer,
    [subOptionsFilterName]: subOptionsFilterReducer,
    [subOptionsEditName]: subOptionsEditReducer,
    //annonces(posts)
    [postsListName]: postsListReducer,
    [allPostsListName]: allPostsListReducer,
    [postsEditName]: postsEditReducer,
    [featuredPostsListName]: featuredPostsListReducer,
    [postsByCategoryListName]: postsByCategoryListReducer,
    [postsByUserListName]: postsByUserListReducer,
    [postsByAnnouncerListName]: postsByAnnouncerListReducer,
    [postsDeleteName]: postsDeleteReducer,
    [postDetailsName]: postDetailsReducer,
    //pack d'annonces(groups)
    [postGroupsListName]: postGroupsListReducer,
    [postGroupsEditName]: postGroupsEditReducer,
    [postGroupsByCategoryListName]: postGroupsByCategoryListReducer,
    [postGroupsDeleteName]: postGroupsDeleteReducer,
    [postGroupsDetailsName]: postGroupsDetailsReducer,
    //slides
    [slidesListName]: slidesListReducer,
    [slidesEditName]: slidesEditReducer,
    [slidesDeleteName]: slidesDeleteReducer,
    //favorites
    [favoritesListName]: favoritesListReducer,
    [favoritesCreateName]: favoritesCreateReducer,
    [favoritesDeleteName]: favoritesDeleteReducer,
    //bid
    [bidProcessListName]: bidProcessListReducer,
    [bidProcessCreateName]: bidProcessCreateReducer,
    [bidProcessDeleteName]: bidProcessDeleteReducer,
    //reviews
    [reviewsListName]: reviewsListReducer,
    [reviewsEditName]: reviewsEditReducer,
    [reviewsDeleteName]: reviewsDeleteReducer,
    //stories
    [storiesListName]: storiesListReducer,
    [storiesCreateName]: storiesCreateReducer,
    [storiesDeleteName]: storiesDeleteReducer,
    //resumes
    [resumesListName]: resumesListReducer,
    [resumesEditName]: resumesEditReducer,
    [resumesDeleteName]: resumesDeleteReducer,
    [resumeDetailsName]: resumeDetailsReducer,
    //announcers
    [announcersListName]: announcersListReducer,
    [announcersEditName]: announcersEditReducer,
    [announcersDeleteName]: announcersDeleteReducer,
    //notifications
    [notificationsListName]: notificationsListReducer,

});

export function* rootSaga() {
    yield all([auth.saga()]);
}
