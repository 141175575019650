import React, {useRef} from "react";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import {useIntl} from "react-intl";
import {useRoutesForAppState} from "../../../../redux/store/routes";
import {PDFExport} from '@progress/kendo-react-pdf';
import {ResumeImg} from "./ResumeImg";
import {AEResumeRating} from "../../../../_ae/components/rating";
import moment from "moment";
import "../resume_style.css";
// import {toAbsoluteUploadUrl} from "../../../../_metronic/_helpers";
// import {MODULES} from "../../../../redux/store/helpers";
import {AEFileDownload} from "../../../../_ae/components/AEFileDownload";

export const DetailsCard = ({
                                entity,
                                size = "md",
                                className = '',
                                avatar,
                                editAction,
                                deleteAction,
                            }) => {

    // const dispatch = useDispatch();
    const {formatMessage} = useIntl()
    // const {data: routes, routesLoading} = useRoutesForAppState();

    const classes = {
        xs: {
            card: 'shadow-none ',
            cardBody: 'p-1',
            symbol: 'symbol-40 mr-2',
            title: 'h6',
        },
        sm: {
            card: 'shadow-none ',
            cardBody: 'p-2',
            symbol: 'symbol-70 mr-2',
            title: 'h5',
        },
        md: {
            symbol: 'symbol-70 mr-5',
            cardBody: 'p-3 d-flex flex-column',
            title: 'h4',
        },
        lg: {
            symbol: 'symbol-80 mr-5',
            title: 'h3',
            cardBody: 'p-4',
        },
    }
    const sizeClasses = classes[size];

    // const actions = [
    //     {id: 'RESUMES.EDIT', show: editAction},
    //     {id: 'RESUMES.DELETE', show: deleteAction},
    // ].filter(action => action.show);

    const resumeRef = useRef();
    const downloadResumeRef = useRef();
    const handleDownloadResumeRef = () => {
        if (downloadResumeRef && downloadResumeRef.current) {
            if (resumeRef && resumeRef.current) {
                const {current} = resumeRef;
                current.save();
            }
        }
    };

    // const handleDownloadResumeRef = () => {
    //     if (downloadResumeRef && downloadResumeRef.current) {
    //         if (resumeRef && resumeRef.current) {
    //             axios.get(toAbsoluteApiUrl(`/resumes/getpdf/${entity.id}`))
    //                 .then(res => {
    //                    console.log(res.data);
    //                 });
    //
    //         }
    //     }
    // };
    // function exportPDF() {
    //     if ( resumeRef &&  resumeRef.current) {
    //         const {current} =  resumeRef;
    //         current.save();
    //     }
    // }
    let username = entity ? entity.fullName : "---"


    let formations = []
    if (entity.formations) {
        formations = entity.formations.map(formation => formation)
    }

    let experiences = []
    if (entity.experiences) {
        experiences = entity.experiences.map(experience => experience)
    }

    let skills = []
    if (entity.skills) {
        skills = entity.skills.map(skill => skill)
    }

    let languages = []
    if (entity.languages) {
        languages = entity.languages.map(language => language)
    }

    let hobbies = []
    if (entity.hobbies) {
        hobbies = entity.hobbies.map(hobby => hobby)
    }

    return (
        <>
            <Card className={`${className}`}>
                <CardBody className={`${sizeClasses.cardBody}`}>
                    <div>
                        <div style={{textAlign: 'center', marginBottom: 10}}>
                            {/*<a href={toAbsoluteUploadUrl(`${MODULES.RESUMES}/${entity.resumeName}`)}*/}
                            {/*   className={"btn btn-light-info"}*/}
                            {/*   target={"_blank"}*/}
                            {/*   download={`CV-${username}`}>*/}
                            {/*    <i className={"fa fa-download"}/>*/}
                            {/*    Télécharger*/}
                            {/*</a>*/}
                            {
                                entity && entity.resumeName &&
                            <AEFileDownload
                                id={entity.id}
                                target={'resumes'}
                                fileName={entity.resumeName}
                                customFileName={`CV-${username}`}
                            />
                            }
                            {/*}*/}
                            {/*<button ref={downloadResumeRef} onClick={handleDownloadResumeRef}*/}
                            {/*        className={"btn btn-light-info"}>*/}
                            {/*    <i className={"fa fa-download"}/>Download*/}
                            {/*</button>*/}
                        </div>
                        <PDFExport paperSize={"A4"}
                                   fileName={`CV-${username}`}
                                   title=""
                                   subject=""
                                   keywords=""

                                   ref={resumeRef}
                            // ref={(r) => this.resume = r}
                        >
                            <div className={"resume_preview"} style={{
                                // height: 860,
                                // width: 620,
                                padding: 'none',
                                backgroundColor: 'white',
                                boxShadow: '5px 5px 5px black',
                                margin: 'auto',
                                overflowX: 'hidden',
                                overflowY: 'hidden'
                            }}>

                                <div className="container profile-box">
                                    <div className="row">
                                        <div className="col-lg-4 left-co">
                                            <div className="left-side">
                                                {
                                                    entity.fileName &&
                                                    <div className="profile-info">
                                                        <ResumeImg entity={entity} className={"w-100"}/>
                                                        {/*<img*/}
                                                        {/*    src="https://www.smarteyeapps.com/demo/profile-page/assets/images/profile.png"*/}
                                                        {/*    alt="" />*/}
                                                        {/*<h3>Jonney Anderson</h3>*/}
                                                        {/*<span>Web Designer</span>*/}
                                                    </div>
                                                }
                                                <h4 className="ltitle">Contact</h4>
                                                <div className="contact-box pb0">
                                                    <div className="icon">
                                                        <i className="fa resume_icon">
                                                            {/* fa-phone */}
                                                            &#xf095;
                                                        </i>
                                                    </div>
                                                    <div className="detail">
                                                        {entity.phone}
                                                    </div>
                                                </div>
                                                <div className="contact-box pb0">
                                                    <div className="icon">
                                                        <i className="fa resume_icon">
                                                            {/* fa-envelope */}
                                                            &#xf0e0;
                                                        </i>
                                                    </div>
                                                    <div className="detail">
                                                        {entity.email}
                                                    </div>
                                                </div>
                                                <div className="contact-box">
                                                    <div className="icon">
                                                        <i className="fa resume_icon">
                                                            {/* fa-map-marker */}
                                                            &#xf041;
                                                        </i>
                                                    </div>
                                                    <div className="detail">
                                                        {entity.address}
                                                    </div>
                                                </div>

                                                {/* SKILLS */}
                                                {
                                                    skills && skills.length > 0 &&
                                                    <>
                                                        <h4 className="ltitle">
                                                            {/*<i className="fas fa-users-cog"/>*/}
                                                            {formatMessage({id: 'SKILLS'})}
                                                        </h4>
                                                        <div className="profess-cover row no-margin">
                                                            {
                                                                skills.map((item, index) => {
                                                                    return (
                                                                        <div key={index} className="col-md-12">
                                                                            <div className=" prog-row row">
                                                                                <div className="col-sm-12">
                                                                                    {item.skill}:
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <AEResumeRating value={item.level}
                                                                                                    className={'list-overview-value'}/>
                                                                                    {/*<div className="progress">*/}
                                                                                    {/*    <div className="progress-bar"*/}
                                                                                    {/*         role="progressbar"*/}
                                                                                    {/*         style={{width: 65 + "%"}}*/}
                                                                                    {/*         aria-valuenow="25"*/}
                                                                                    {/*         aria-valuemin="0"*/}
                                                                                    {/*         aria-valuemax="100"/>*/}
                                                                                    {/*</div>*/}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </>
                                                }

                                                {/* LANGUAGES */}
                                                {
                                                    languages && languages.length > 0 &&
                                                    <>
                                                        <h4 className="ltitle">
                                                            {/*<i className="fas fa-users-cog"/>*/}
                                                            {formatMessage({id: 'LANGUAGES'})}
                                                        </h4>
                                                        <div className="profess-cover row no-margin">
                                                            {
                                                                languages.map((item, index) => {
                                                                    return (
                                                                        <div key={index} className="col-md-12">
                                                                            <div className=" prog-row row">
                                                                                <div className="col-sm-12">
                                                                                    {item.language}:
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <AEResumeRating value={item.level}
                                                                                                    className={'list-overview-value'}/>
                                                                                    {/*<div className="progress">*/}
                                                                                    {/*    <div className="progress-bar"*/}
                                                                                    {/*         role="progressbar"*/}
                                                                                    {/*         style={{width: 65 + "%"}}*/}
                                                                                    {/*         aria-valuenow="25"*/}
                                                                                    {/*         aria-valuemin="0"*/}
                                                                                    {/*         aria-valuemax="100"/>*/}
                                                                                    {/*</div>*/}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </>
                                                }

                                                {/* HOBBIES */}
                                                {
                                                    hobbies && hobbies.length > 0 &&
                                                    <>
                                                        <h4 className="ltitle">
                                                            {formatMessage({id: 'HOBBIES'})}
                                                        </h4>
                                                        <ul className="hoby m-0 p-0">
                                                            {
                                                                hobbies.map((hobby, index) => {
                                                                    return (
                                                                        <li key={index}> -{hobby.label}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </>
                                                }
                                            </div>
                                        </div>


                                        <div className="col-lg-8 rt-div">
                                            <div className="rit-cover">
                                                <div className="hotkey">
                                                    <h1 className="">{entity.fullName}</h1>
                                                    {/*<small>Web Designer</small>*/}
                                                </div>
                                                <h2 className="rit-titl">
                                                    <i className={"fa fa-2x resume_icon"}>
                                                        {/* fa-user */}
                                                        &#xf007;
                                                    </i>
                                                    Profile
                                                </h2>
                                                <div className="about">
                                                    <p>
                                                        {entity.profile}
                                                    </p>
                                                </div>

                                                {/* EXPERIENCES */}
                                                {
                                                    experiences && experiences.length > 0 &&
                                                    <>
                                                        <h2 className="rit-titl">
                                                            <i className="fa fa-2x resume_icon">
                                                                {/* fa-briefcase */}
                                                                &#xf0b1;
                                                            </i>
                                                            {formatMessage({id: 'EXPERIENCES'})}
                                                        </h2>
                                                        {
                                                            experiences.map((experience, index) => {
                                                                return (
                                                                    <div key={index} className="work-exp">
                                                                        <h6>
                                                                            {experience.title}
                                                                        </h6>
                                                                        <i>
                                                                            {moment(experience.startDate).format('DD-MM-YYYY')}
                                                                            <span className={"mx-2"}>Jusqu'à</span>
                                                                            {moment(experience.endDate).format('DD-MM-YYYY')}
                                                                        </i>
                                                                        <p>
                                                                            {experience.description}
                                                                        </p>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </>
                                                }

                                                {/* FORMATIONS */}
                                                {
                                                    formations && formations.length > 0 &&
                                                    <>
                                                        <h2 className="rit-titl">
                                                            <i className="fa fa-2x resume_icon">&#xf19d;</i>
                                                            {formatMessage({id: 'FORMATIONS'})}
                                                        </h2>
                                                        {
                                                            formations.map((formation, index) => {
                                                                return (
                                                                    <div key={index} className="work-exp">
                                                                        <h6>
                                                                            {formation.title}
                                                                        </h6>
                                                                        <i>
                                                                            {moment(formation.startDate).format('DD-MM-YYYY')}
                                                                            <span className={"mx-2"}>Jusqu'à</span>
                                                                            {moment(formation.endDate).format('DD-MM-YYYY')}
                                                                        </i>
                                                                        <p>
                                                                            {formation.description}
                                                                        </p>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PDFExport>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}