import axios from "axios";
import {axiosFormDataConfigs, getFormData} from "../../../_ae/helpers/AxiosHelpers";
import {toAbsoluteApiUrl} from "../../../_metronic/_helpers";
import {MODULES} from "../helpers";

export const API_URI = toAbsoluteApiUrl(`/${MODULES.USERS}`);

export const normalize = _entity => {
    const {id, firstName, lastName, email, password, gender} = _entity;
    // const city = {id: _entity.city.id}
    const city = {id: _entity.city ? _entity.city.id : undefined}
    const roles = []

    if (_entity.roles) {
        roles.push({id: _entity.roles.id})
    }
    // let roles = undefined;
    // if (_entity.roles) {
    //     roles = _entity.roles.map(role => ({id: role.id}))
    // }
    const username = email

    return {id, firstName, lastName, email, password, roles, city, username, gender}
}

export const all = (metadata) => axios.get(API_URI, {params: {meta: metadata}});
export const find = (id, metadata) => axios.get(`${API_URI}/${id}`, {params: {meta: metadata}});
export const destroy = id => axios.delete(`${API_URI}/${id}`);
export const create = (entity, files) => axios.post(API_URI, getFormData(normalize(entity), files), axiosFormDataConfigs);
export const update = entity => axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(normalize(entity)), axiosFormDataConfigs);
