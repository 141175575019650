import React from "react";
import Header from "../Header";
import {useIntl} from "react-intl";
import {termsOfService} from "./data.json";
import {TosItem} from "./tosItem";
import {FavoriteCard} from "../Favorites/FavoriteCard";

export const TermsOfService = () => {
    const {formatMessage} = useIntl()

    return (
        <>
            <Header/>

            <main className="main section-color-primary">
                <div className="container">
                    <section className="top-title">
                        <ul className="breadcrumb">
                            <li className="item"><a href="/">
                                {formatMessage({id: "HOME"})}
                            </a></li>
                            <li className="item">
                                {formatMessage({id: "TERMS.OF.SERVICE.TITLE"})}
                            </li>
                        </ul>
                        {/*<h1 className="h-side-title page-title page-title-big text-color-primary">Terms Of Service</h1>*/}
                    </section>
                    <div className="row">
                        <div className="col-12">
                            <div className="properties">
                                {/*   todo data */}
                                <div className="box-overflow-container box-container">
                                    <div className="box-container-title">
                                        <h1 className="h-side-title page-title page-title-big text-color-primary my-2">
                                            {formatMessage({id: "TERMS.OF.SERVICE.TITLE"})}
                                        </h1>
                                    </div>
                                    <div className="panel-group panel-content property_content_position">
                                        {
                                            termsOfService.map((entity, index) => (
                                                    <TosItem key={index} {...entity}/>
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    );
}
