import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {useMetadata} from "../../../../../_ae/AEPagination";
import {fetchPosts, usePostsListState} from "../../../../../redux/store/posts";
import {Pagination} from "../../../../../_metronic/_partials/controls";
import {Loader} from "../../../../../_ae/components/loader";
import {PostListCard} from "../PostListCard";
import {PostsFeatured} from "./PostsFeatured";
import {Slider} from "../../Slider";
import CategoriesList from "../../Categories/CategoriesList";
import {MainHeader} from "../../MainHeader";
import {Stories} from "../../Stories";


function PostsList() {

    const dispatch = useDispatch();
    const {data, isLoading, metadata} = usePostsListState()

    const metadataState = useMetadata(metadata)
    const {page, perPage, sortAsc, filters, sortField, select, total} = metadataState;

    const getEntities = () => {
        dispatch(fetchPosts(metadataState.toAEMetadata({
            ...metadataState.toAEMetadata(),
            filters: {...metadataState.toAEMetadata().filters, "status": "enabled"},
            sort: {...metadataState.toAEMetadata().sort, field: "createdAt", asc: false}
        })));
    }


    useEffect(() => {
        getEntities()
    }, [dispatch, page, perPage, filters.search, filters.category, filters.status, filters.priceRange, filters.type, filters.city, select, sortField, sortAsc]);


    return (
        <>
            <header className="header">
                {/*<Header/>*/}
                <MainHeader {...metadataState}/>

                <div className="container">
                    <Slider/>
                    <Stories/>
                    <CategoriesList/>
                </div>
            </header>

            <main className="main section-color-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="h-side top-pad h-side-slim clearfix">
                                <div className="properties-filter breadcrumb">
                                    <div className="form-group">
                                        <Link to='/' style={{textDecoration: 'underline'}}>
                                            <span className="agent-name m-2">
                                                   <i className={"agent-name fa fa-home"}/>
                                                    Toutes les Catégories ({data ? total : '0'} annonces) >>
                                            </span>
                                        </Link>
                                        <Loader isLoading={isLoading}/>
                                    </div>
                                    <div className="grid-type pull-right ml-auto">
                                        <Link to={"/annonces"} className="grid">
                                            <i className="fa fa-th"/>
                                        </Link>
                                        <Link to={"/annonces/list"} className="list active">
                                            <i className="fa fa-list"/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="properties">
                                <div className="row">
                                    {
                                        data &&
                                        data.map((post, index) => {
                                            return (
                                                <PostListCard key={index} entity={post}/>
                                            );
                                        })
                                    }
                                </div>

                                <Pagination {...metadataState} counts={[12, 24, 36]}/>

                            </div>
                        </div>

                        <PostsFeatured/>

                    </div>
                </div>
            </main>
        </>
    );
}

export default PostsList;

