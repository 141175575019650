import React from "react";
import {localField} from "../../_ae/helpers/UIHelper";

export const City = ({city, fontSize = 'md', fontWeight = 'bold'}) => {
    if (!city) return ''

    const {country} = city;

    return (
        <>
            <span className={`font-size-${fontSize} font-weight-${fontWeight}`}>{city[localField()]}</span>
            {
                country &&
                <>
                    {` `}
                    <span className={'pl-2 text-muted'}>{city.country[localField()]}</span>
                </>
            }
        </>
    )

}
