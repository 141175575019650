import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
// import {
//     fetchCategories,
//     useCategoriesListState
// } from "../../../../../redux/store/categories";
// import {useMetadata} from "../../../../../_ae/AEPagination";

const LoggedInView = props => {
    const {isAuthorized, user} = useSelector(({auth}) => ({
        user: auth.user,
        isAuthorized: auth.user != null,
    }), shallowEqual);

    return (
        isAuthorized ?
            <ul className="login-menu clearfix">
                <li>
                    <Link target="_blank" to="/admin/posts" className="font-weight-bold">
                        <i className="fa fa-list"/> Mes annonces
                    </Link>
                </li>
                <li>
                    <Link to="/favorites" className="font-weight-bold">
                        <i className="fa fa-star"/> Mes favories
                    </Link>
                </li>
                <li>
                    <Link to="/bidprocesses" className="font-weight-bold">
                        <i className="fas fa-money"/> Mes Propositions d'enchères
                    </Link>
                </li>
                <li>
                    <Link to="/notifications" className="font-weight-bold">
                        <i className="fa fa-globe"/> Notifications
                    </Link>
                </li>
                <li>
                    <a href="/admin/logout"
                       onClick="setTimeout(location.reload.bind(location), 1)">
                        <i className="fa fa-power-off"/> Quitter
                    </a>
                </li>
            </ul>
            :
            <ul className="login-menu clearfix">
                <li>
                    <Link target="_blank" to="/admin/auth/login" className="font-weight-bold">
                        <i className="fa fa-power-off"/> Se connecter
                    </Link>
                </li>
                <li>
                    <Link target="_blank" to="/admin/auth/login" className="font-weight-bold">
                        <i className="fa fa-list"/> Mes annonces
                    </Link>
                </li>
                <li>
                    <a href="/admin/auth/login"
                       onClick="setTimeout(location.reload.bind(location), 1)">
                        <i className="fa fa-star"/> Mes Favoris
                    </a>
                </li>
            </ul>
    );

};


function Header() {

    const dispatch = useDispatch();

    // const {data: categories, metadata} = useCategoriesListState();
    // const metadataState = useMetadata(metadata)
    // const {sortAsc, sortField} = metadataState;

    // useEffect(() => {
    //     dispatch(fetchCategories(metadataState.toAEMetadata()))
    // }, [dispatch, sortField, sortAsc])


    return (
        <>

            <div className="top-box" data-toggle="sticky-onscroll">

                <div className="container header-container">
                    <div className="top-bar color-primary">
                        <div className="container clearfix">
                            <div className="pull-left">
                                <LoggedInView/>
                            </div>
                            <div className="pull-right">
                                <ul className="social-nav clearfix">
                                    <li>
                                        <a href="https://www.facebook.com/share.php?u=http%3A%2F%2Ftest.com%2F"
                                        >Facebook</a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/home?status=http://test.com"
                                        >Twitter</a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/shareArticle?mini=true&url=&title=&summary=&source="
                                        >LinkedIn</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <section className="header-inner">
                        <div className="container header-container">
                            <div className="logo pull-left pull-sm-up col-sm-2 col-xs-12 text-left">
                                <Link to="/annonces" className="nav-link">
                                    <img src={toAbsoluteUrl("/frontoffice/img/logos/logo.png")} alt="Onix.ma"
                                         style={{width: 10.8 + "em"}}/>
                                    <img src={toAbsoluteUrl("/frontoffice/img/logos/logo.png")} alt="Onix.ma"
                                         className="mini-logo"/>
                                </Link>
                            </div>

                            <div className="pull-right pull-sm-up col-sm-2 col-xs-12 websitetitle focus-color">
                                <Link target="_blank" to="/admin/posts" className="row">
                                    <div className="sub-title">Bienvenue!</div>
                                    <h2 className="title">Déposez une annonce</h2>
                                </Link>
                            </div>


                            <div className="pull-right pull-sm-up col-sm-1 col-xs-12 websitetitle p-0 pl-1">
                                <div className="row">
                                    <Link to="/boutiques" className="col-lg-12 p-2 h-50 bg-success">
                                        <div className="title">
                                            <i className="fas fa-store text-white p-2"/>Boutiques
                                        </div>
                                    </Link>

                                    <Link to="/boutiques" className="col-lg-12 p-2 h-50 bg-info">
                                        <div className="title">
                                            <i className="fas fa-box text-white p-2"/>Packs
                                        </div>
                                    </Link>
                                </div>
                            </div>

                            <div className="pull-left menu col-sm-6 col-xs-12 pt-0">
                                <nav className="navbar text-color-primary">
                                    {/*<ul className="main-navigation" id="main-nav">*/}
                                    {/*    <li>*/}
                                    {/*        <a className="main-navigation_link" href="/">*/}
                                    {/*            <i className="fa fa-home text-dark"> Acceuil</i>*/}
                                    {/*        </a>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <a className="main-navigation_link" href="#">*/}
                                    {/*            <i className="fa fa-th text-dark"> Catégories</i>*/}
                                    {/*        </a>*/}
                                    {/*        <ul className="main-navigation">*/}
                                    {/*            {*/}
                                    {/*                categories &&*/}
                                    {/*                categories.map(category => {*/}
                                    {/*                    if (!category.parrent) {*/}
                                    {/*                        return (*/}
                                    {/*                            <li>*/}
                                    {/*                                <Link*/}
                                    {/*                                    to={'/annonces/by/category/:id'.replace(':id', category.id)}*/}
                                    {/*                                    className="main-navigation_link">*/}
                                    {/*                                    <img*/}
                                    {/*                                        src={toEntityFileNameUrl(category, 'categories')}*/}
                                    {/*                                        className={"mr-2"} alt={` `}*/}
                                    {/*                                        style={{maxWidth: 16 + "px"}}/>*/}
                                    {/*                                    {category.label}*/}
                                    {/*                                </Link>*/}
                                    {/*                            </li>*/}
                                    {/*                        )*/}
                                    {/*                    }*/}
                                    {/*                })*/}
                                    {/*            }*/}

                                    {/*        </ul>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <a className="main-navigation_link" href="/">*/}
                                    {/*            <i className="fa fa-store text-dark"> Boutiques</i>*/}
                                    {/*        </a>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <a className="main-navigation_link" href="#">*/}
                                    {/*            <i className="fa fa-info-circle text-dark"> A propos</i>*/}
                                    {/*        </a>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                </nav>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className="top-box-mask"/>

        </>
    );

}


export default Header;
