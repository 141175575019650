import React from "react";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import {AESVG, SVG_ICON} from "../../../../_ae/components/svg";
import {useIntl} from "react-intl";
import {AELink} from "../../../../_ae/components/AELink";
import {DropdownCustomToggler,} from "../../../../_metronic/_partials/dropdowns";
import {Dropdown} from "react-bootstrap";
import {AERole} from "../../../../_ae/components/AERole";
import {useRoutesForAppState} from "../../../../redux/store/routes";
import {AETruncate} from "../../../../_ae/helpers/UIHelper";
import {Label} from "./Label";
import {Expiration} from "./Expiration";
import {Icon} from "./Icon";

export const DetailsCard = ({
  entity,
  size= "md",
  className = '',
  avatar,
  editAction,
  deleteAction,
  changeStatusAction,
}) => {

  const { formatMessage } = useIntl()
  const {data: routes, routesLoading} = useRoutesForAppState();
  const classes = {
    xs:{
      card: 'shadow-none ',
      cardBody: 'p-1',
      symbol: 'symbol-40 mr-2',
      title: 'h6',
    },
    sm:{
      card: 'shadow-none ',
      cardBody: 'p-2',
      symbol: 'symbol-70 mr-2',
      title: 'h5',
    },
    md:{
      symbol: 'symbol-70 mr-5',
      cardBody: 'p-3 d-flex flex-column',
      title: 'h4',
    },
    lg:{
      symbol: 'symbol-80 mr-5',
      title: 'h3',
      cardBody: 'p-4',
    },
  }

  const sizeClasses = classes[size];
  const isLg = size === 'lg'
  const isMd = size === 'md'
  const isXs = size === 'xs'

  const actions = [
    {id: 'CATEGORIES.EDIT', show: editAction},
    {id: 'CATEGORIES.DELETE', show: deleteAction},
  ].filter(action=>action.show);


  return (
    <>
      <Card className={`${className}`}>
        <CardBody className={`${sizeClasses.cardBody}`}>
          <div className={`d-flex`}>
           
            <div className={`${isXs ? '' : '-d-flex align-items-start'}`}>
              { 
                <Icon entity={entity} className={sizeClasses.symbol}/>
              }
            </div>

            <div className="flex-grow-1">
              <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                <Label entity={entity} fontSize={sizeClasses.title} fontWeight={'bold'}/>
              </div>
              <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                Expiration : 
                  <Expiration entity={entity} fontSize={sizeClasses.isMd} fontWeight={'bold'}/>
                  ({formatMessage({id:'DAYS'})})
                </div>

                <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                  {formatMessage({id: 'ORDER'})} : {entity.classification}
                </div>
                
                <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                  {formatMessage({id: 'SUBCATEGORIES'})} : {entity.subcategories.length}
                </div>

                {
                  actions.length > 0 &&
                  <Dropdown>
                    <Dropdown.Toggle as={DropdownCustomToggler} >
                      <AESVG path={SVG_ICON.DOTS} variant={'primary'} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {
                        actions
                          .map(action=>{
                          const route = routes.find(r=>r.id === action.id);
                            if (! route) return '';
                            const path = route.path.replace(':id', entity.id)

                            return (
                              <AERole key={route.id} roles={route.roles}>
                                <AELink to={path} className="dropdown-item" >
                                  <AESVG className={'pr-2'} path={route.svg}/>
                                  {formatMessage({id: route.id})}
                                </AELink>
                              </AERole>
                            )
                          })
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                }
              </div>


              <div className="flex-grow-1">
               
                <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                  <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                    {formatMessage({ id: "PRICE" })}: {entity.price}
                  </div>
                </div>
                <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                  <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                    {formatMessage({ id: "TYPE" })}: {entity.type}
                  </div>
                </div>
                <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                  <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                    {formatMessage({ id: "STATUS" })}: {entity.status}
                  </div>
                </div>
              </div>

              <div className="flex-grow-1">
                <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                  {
                    entity.description &&
                    <div className={'bg-light p-4'}>
                      <AETruncate
                        text={entity.description}
                      />
                    </div>
                  }
                </div>
              </div>

              {
            (isMd || isLg) &&
            entity.subCategories &&
            entity.subCategories.length > 0 &&
            entity.subCategories.map(subCat=>{
                return (
                  <div>{
                  actions.length > 0 &&
                  <Dropdown>
                    - <Label entity={subCat} fontSize={sizeClasses.md} fontWeight={'bold'}/>
                    <Dropdown.Toggle as={DropdownCustomToggler} >
                      <AESVG path={SVG_ICON.DOTS} variant={'primary'} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {
                        actions
                          .map(action=>{
                            const route = routes.find(r=>r.id === action.id);
                            if (! route) return '';
                            const path = route.path.replace(':id', subCat.id)

                            return (
                              <AERole key={route.id} roles={route.roles}>
                                <AELink to={path} className="dropdown-item" >
                                  <AESVG className={'pr-2'} path={route.svg}/>
                                  {formatMessage({id: route.id})}
                                </AELink>
                              </AERole>
                            )
                          })
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                  }</div>
                )
              })
            }
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}