import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {useMetadata} from "../../../../../_ae/AEPagination";
import {fetchFeaturedPosts, useFeaturedPostsListState} from "../../../../../redux/store/posts";
import {FeaturedPostCard} from "./FeaturedPostCard";


export function PostsFeatured() {

    const dispatch = useDispatch();

    const {data: featuredPosts, metadata} = useFeaturedPostsListState()

    const metadataState = useMetadata(metadata)
    const {page, perPage, sortAsc, sortField, select, filters} = metadataState;

    const getEntities = () => {
        dispatch(fetchFeaturedPosts({
            ...metadataState.toAEMetadata(),
            sort: {...metadataState.toAEMetadata().sort, field: "createdAt", asc: false}
        }));

    }

    useEffect(() => {
        getEntities()
    }, [dispatch, page, perPage, select, sortField, sortAsc]);


    return (
        <>
            <div className="col-md-3 sidebar-right">
                <div className="widget widget-box box-container widget-properties">
                    <div className="widget-header text-uppercaser">
                        <h2>Recommandés</h2>
                    </div>
                    <div className="properties">
                        <div className="row">
                            {
                                featuredPosts &&
                                featuredPosts.slice(0, 3).map((entity, index) => {
                                    return (
                                        <FeaturedPostCard key={index} entity={entity}/>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                {/*<div className="widget widget-ads-right">*/}
                {/*    <img src={toAbsoluteUrl("frontoffice/img/placeholders/265x220.png")} alt=""*/}
                {/*         className="center-block"/>*/}
                {/*</div>*/}
            </div>
        </>
    );
}



