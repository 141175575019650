import React from 'react';
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import "bootstrap/dist/css/bootstrap.css";

export const ProductCard = ({entity}) => {

    return (
        <>
            <div className="col-md-6 col-sm-6">
                <div className="property-card card">
                    <div className="property-card-header image-box">
                        {
                            entity.fileName ?
                                <img src={toEntityFileNameUrl(entity, 'posts')} alt={` `}
                                     className="mx-auto img-responsive align-self-center"/>
                                :
                                <SVG
                                    className="mx-auto img-responsive align-self-center"
                                    src={toAbsoluteUrl("/media/svg/gallery.svg")}
                                />
                        }
                        {/*<i className="symbol-badge bg-primary"/>*/}
                        {
                            entity.state != "" &&
                            <div className={`budget_${entity.state} budget`}><span
                                className={"budget_label"}>{entity.state}</span></div>
                        }
                        {/*<Link to={'/annonces/:id/details'.replace(':id', entity.id)} className="property-card-hover">*/}
                        {/*    <img src={toAbsoluteUrl("/frontoffice/img/plus.png")} alt="" className="center-icon"/>*/}
                        {/*</Link>*/}

                        {/*<span className="property-card-value text-white bg-primary p-1">*/}
                        {/*    {hasvideos && <i className="fa fa-video text-white p-1"/>}*/}
                        {/*    <i className="fa fa-images text-white p-1"/>*/}
                        {/*    {attachmentsLength}*/}
                        {/*</span>*/}

                    </div>

                    <div className="property-card-box card-box card-block mt-2 text-center">
                        <h3 className="property-card-title m-2 ">
                            {entity.title}
                        </h3>

                        <div className="property-card-descr text-truncate">
                            {entity.description ? entity.description : 'Aucune Description.'}
                        </div>
                        <div className="property-preview-footer  clearfix">
                            {
                                entity.city &&
                                <div className="property-preview-f-left text-color-primary">
                                    <span className="property-card-value">
                                        <i className="fa fa-map-marker"/>
                                        {entity.city.name}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}