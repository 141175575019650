import React, {useEffect, useState} from "react";
import {FormikProvider, useFormik} from "formik";
import {useDispatch} from "react-redux";
import {useIntl} from "react-intl";
import {AEAutocompleteField, AEField} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {
    fetchAttributesByCategory,
    useAttributesByCategoryListState
} from "../../../../../redux/store/attributes";
import {
    fetchCityForLocationsFilter,
    useCitiesLocationsFilterState
} from "../../../../../redux/store/cities";
import {AttributeCard} from "./AttributeCard";
import {AEButton} from "../../../../../_ae/components/buttons";
import {AESVG} from "../../../../../_ae/components/svg";
import {
    fetchCategoryForEdit,
    useCategoriesEditState
} from "../../../../../redux/store/categories";
import {
    fetchSpecificationsByCategory,
    useSpecificationsByCategoryListState
} from "../../../../../redux/store/specifications";
import {useMetadata} from "../../../../../_ae/AEPagination";


const types = [
    {id: "OFFRE", label: "Offre"},
    {id: "DEMANDE", label: "Demande"},
    {id: "LOCATION", label: "Location"},
]


export const Filter = ({setFilters, setSort, sort, setSortAsc, id}) => {

    const dispatch = useDispatch();
    const {formatMessage} = useIntl()
    const {data: category} = useCategoriesEditState();
    const {data: cities, metadata} = useCitiesLocationsFilterState();
    const metadataState = useMetadata(metadata)

    const {data: attributes} = useAttributesByCategoryListState();
    const {data: specifications} = useSpecificationsByCategoryListState();
    //const [locations, setLocations] = React.useState([]);


    const formik = useFormik({
        initialValues: {
            search: "",
            category: undefined,
            city: undefined,
            attributes: [],
            specifications: [],
            type: "",
            priceRange: [0, 1000000000]
        },
        onSubmit: (values) => {
            const {search, type, city, category, attributes, specifications, priceRange} = values;
            let filters = {search};
            //filters.category = {id: id};
            if (category) filters.category = {id: category.id};
            if (city) filters.city = {id: city.id};
            let filteredAttributes = [];
            let filteredSpecification = [];
            // if (min && max) filters.priceRange = [min, max]
            priceRange[0] = priceRange[0] != null ? priceRange[0] : 0;
            priceRange[1] = priceRange[1] ? priceRange[1] : 1000000000
            filters.priceRange = priceRange;

            if (attributes) {
                attributes.forEach((value) => {
                    if (Array.isArray(value)) {
                        value.forEach((val) => {
                            if (val != null) {
                                filteredAttributes.push(val.id);
                            }
                        })
                    } else {
                        if (value != null) {
                            filteredAttributes.push(value.id);
                        }
                    }
                });
            }
            if (filteredAttributes && filteredAttributes.length > 0) filters.attributes = filteredAttributes;


            if (specifications) {
                specifications.forEach((value) => {
                    if (value != null) {
                        filteredSpecification.push(value.id);
                    }
                });
            }
            if (filteredSpecification && filteredSpecification.length > 0) filters.specifications = filteredSpecification;

            // if (specifications && specifications.length > 0) {
            //     filters.specifications = specifications.map(specification => ({id: specification.id}))
            // }
            //if (city) filters.location = {city:{id:city.id }};
            //if (location) filters.location = {id: location.id, city:{id:city.id }};
            if (type) filters.type = type.id;
            setFilters(filters)
        }
    })

    useEffect(() => {
        dispatch(fetchCityForLocationsFilter(metadataState.toAEMetadata({
            ...metadataState.toAEMetadata(),
            sort: {...metadataState.toAEMetadata().sort, field: "name"},
            pagination: {...metadataState.toAEMetadata().pagination, perPage: 999}
        })));
    }, [dispatch])


    useEffect(() => {
        dispatch(fetchCategoryForEdit(id));
        if (formik.values && formik.values.category) {
            dispatch(fetchAttributesByCategory(formik.values.category.id))
            dispatch(fetchSpecificationsByCategory(formik.values.category.id))
        } else {
            dispatch(fetchAttributesByCategory(id))
            dispatch(fetchSpecificationsByCategory(id))
        }
    }, [id, formik.values, dispatch])


    /* let city = null;
     useEffect(() => {
         city = formik.values.city
         if (city)
         {
             setLocations(city.locations);
         }
     }, [formik.values.city])
     */

    /*
        useEffect(() => {
            formik.handleSubmit()
        }, [formik.values])
*/


    const fieldProps = {
        withFeedbackLabel: false,
        validation: false,
        className: 'text-white'
    }
    const style = {
        //backgroundColor: '#004790',
        color: 'white'
    }

    return (

        <div className="col-md-3 sidebar-right ">
            <div className="widget widget-box box-container widget-properties">
                <div className="widget-header text-uppercaser">
                    <h2>Filtrer</h2>
                </div>
                <div className="properties">
                    <FormikProvider value={formik}>
                        <div className="d-flex row">
                            {
                                category &&
                                category.subCategories &&
                                category.subCategories.length > 0 &&
                                <div className={`flex-fill col-lg-12 mb-3`}>
                                    <AEAutocompleteField
                                        style={style}
                                        name="category"
                                        label={'CATEGORY'}
                                        options={category.subCategories}
                                        getOptionLabel={opt => opt.label}
                                        {...fieldProps}
                                    />
                                </div>
                            }
                            <div className={`flex-fill col-lg-12 mb-3`}>
                                <AEAutocompleteField
                                    style={style}
                                    name="type"
                                    label={'TRANSACTION'}
                                    options={types}
                                    getOptionLabel={opt => opt.label}
                                    {...fieldProps}
                                />
                            </div>
                            <div className={`flex-fill col-lg-6 mb-3`}>
                                <AEField
                                    type="number"
                                    name="priceRange.0"
                                    label={'MIN_PRICE'}
                                    {...fieldProps}
                                />
                            </div>
                            <div className={`flex-fill col-lg-6 mb-3`}>
                                <AEField
                                    type="number"
                                    name="priceRange.1"
                                    label={'MAX_PRICE'}
                                    {...fieldProps}
                                />
                            </div>

                            <div className={`flex-fill col-lg-12 mb-3`}>
                                <AEAutocompleteField
                                    style={style}
                                    name="city"
                                    label={'CITY'}
                                    options={cities}
                                    getOptionLabel={opt => opt.name}
                                    {...fieldProps}
                                />
                            </div>

                            {
                                attributes &&
                                attributes.map((attribute, index) => (
                                    <>
                                        <AttributeCard entity={attribute} index={index}
                                                       attributes={formik.values.attributes}/>
                                    </>
                                ))
                            }

                            {
                                specifications &&
                                specifications.length > 0 &&
                                <div className="d-flex form-group col-lg-12">
                                    <div className={'flex-grow-1'}>
                                        <AEAutocompleteField
                                            name={`specifications`}
                                            label={"SPECIFICATIONS"}
                                            options={specifications}
                                            getOptionLabel={opt => opt.label}
                                            {...fieldProps}
                                            multiple={"multiple"}
                                        />
                                    </div>
                                </div>
                            }

                            {/*
                                attributes &&
                                attributes.map(attribute => {
                                    return (
                                        attribute.parrent == null ?
                                            <div className={`flex-fill col-lg-12 mb-3`}>
                                                <AEAutocompleteField
                                                    style={style}
                                                    name="attribute"
                                                    label={attribute.label}
                                                    options={attribute.subOptions}
                                                    getOptionLabel={opt => opt.label}
                                                    {...fieldProps}
                                                />
                                            </div>
                                            :
                                            ''

                                    )
                                })
                            }

                            <div className={`flex-fill col-lg-12 mb-3`}>
                            {
                                locations &&
                                locations.length > 0 &&
                                <AEAutocompleteField
                                    style={style}
                                    name="location"
                                    label={'LOCATION'}
                                    options={locations}
                                    getOptionLabel={opt => opt.name}
                                    {...fieldProps}
                                />
                            }
                        </div>*/}
                            <div className={`flex-fill col-lg-12 mb-3`}>
                                <AEButton
                                    variant={"light"}
                                    fontWeight={"bolder"}
                                    size={"sm"}
                                    onClick={formik.handleSubmit}
                                >
                                    <AESVG path={"/General/Search.svg"} variant={"primary"}/>
                                    {formatMessage({id: 'SEARCH'})}
                                </AEButton>
                            </div>
                        </div>
                    </FormikProvider>
                </div>
            </div>
            {/*<div className="widget widget-ads-right">*/}
            {/*    <img src={toAbsoluteUrl("frontoffice/img/placeholders/265x220.png")} alt=""*/}
            {/*         className="center-block"/>*/}
            {/*</div>*/}
        </div>
    )
}