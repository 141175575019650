import React, {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {
  Card,
  CardBody, CardFooter,
  ModalProgressBar,
} from "../../../../../_metronic/_partials/controls";
import {useIntl} from "react-intl";
import {Formik} from "formik";
import * as Yup from "yup";
import {
  fetchPostForEdit , resetPostForEdit,
  savePost,
  usePostsEditState
} from "../../../../../redux/store/posts";
import {Form} from "./Form";
import {AEButton} from "../../../../../_ae/components/buttons";
import {useSelector} from "react-redux";

//todo server validation errors
export const Edit = ({ history, match: { params: { id }, }}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { isLoading, data, validationErrors } = usePostsEditState();
  const {user} = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(
      id ?
        fetchPostForEdit(id) :
        resetPostForEdit()
    );
  }, [id, dispatch]);

  const saveBtnRef = useRef();
  const saveBtnRefClick = () => {
    if (saveBtnRef && saveBtnRef.current) {
      const {current} = saveBtnRef;
      current.click()
    }
  };

  return (
    <Card>
      {isLoading && <ModalProgressBar />}
      <CardBody className={'pt-10'}>
        <Formik
          enableReinitialize
          initialErrors={validationErrors}
          validationSchema={
            Yup.object().shape({
              title: Yup.string().required(),
              description: Yup.string().required(),
              price: Yup.number(),
              type: Yup.string().required(),
              state: Yup.string().required(),
              fileName: Yup.mixed(),
              postAttachements: Yup.array(),
              category: Yup.object().required(),
              city: Yup.object().required(),
              author: Yup.object(),
              announcer: Yup.object(),
              resume: Yup.object(),
              attributes: Yup.array(),
              specifications: Yup.array(),
            })
          }
          initialValues={data}
          onSubmit={({postAttachements,fileName, ...values})=>{
            values.author = user;
            dispatch(savePost(values, {fileName, postAttachements})).then(() => {
              history.goBack();
            });
          }}
          render={formik=>(
            <Form
              {...formik}
              btnRef={saveBtnRef}
            />)}
        />
      </CardBody>
      <CardFooter className={'d-flex justify-content-end'}>
        <AEButton
          variant={'light'}
          onClick={history.goBack}
        >
          <i className="fa fa-arrow-left"/>
          {formatMessage({id:'BACK'})}
        </AEButton>
        <AEButton
          className="ml-2"
          onClick={saveBtnRefClick}
        >
          {formatMessage({id:'SAVE'})}
        </AEButton>
      </CardFooter>
    </Card>
  );
}

