import React from 'react';
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import {Link} from "react-router-dom";
import SVG from "react-inlinesvg";
import {AECurrency} from "../../../../_ae/components/Currency"
// import {useIntl} from "react-intl";
import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import {useIntl} from "react-intl";


//
// const useModal = () => {
//     const [isShowing, setIsShowing] = useState(false);
//
//     function toggle() {
//         setIsShowing(!isShowing);
//     }
//
//     return {
//         isShowing,
//         toggle
//     };
// };

export const PostCard = ({entity}) => {

    const {formatMessage} = useIntl();

    /*
    const [show, setShow] = useState(false);
    const {
        isShowing: isRegistrationFormShowed,
        toggle: toggleRegistrationForm
    } = useModal();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);*/

    let hasvideos = false
    hasvideos = entity.postAttachements.some(function (attachment) {
        const splittedFileName = attachment.fileName.split(".");
        return splittedFileName[splittedFileName.length - 1] === "mp4";
    });

    let attachmentsLength = 0;
    if (entity.postAttachements) {
        if (entity.fileName) {
            attachmentsLength = entity.postAttachements.length + 1;
        } else {
            attachmentsLength = entity.postAttachements.length;
        }
    }
    return (
        <>
            <div className="col-md-4 col-sm-6">
                <div className="property-card card">
                    <div className="property-card-header image-box">
                        {
                            entity.fileName ?
                                <img src={toEntityFileNameUrl(entity, 'posts')} alt={` `}
                                     className="mx-auto img-responsive align-self-center"/>
                                :
                                <SVG
                                    className="mx-auto img-responsive align-self-center"
                                    src={toAbsoluteUrl("/media/svg/gallery.svg")}
                                />
                        }
                        {/*<i className="symbol-badge bg-primary"/>*/}

                        {
                            entity.state !== "" &&
                            <div className={`budget_${entity.state} budget`}><span
                                className={"budget_label"}>{entity.state}</span></div>
                        }
                        <Link to={'/annonces/:id/details'.replace(':id', entity.id)} className="property-card-hover">
                            <img src={toAbsoluteUrl("/frontoffice/img/plus.png")} alt="" className="center-icon"/>
                        </Link>

                        <span className="property-card-value text-white bg-primary p-1">
                            {hasvideos && <i className="fa fa-video text-white p-1"/>}
                            <i className="fa fa-images text-white p-1"/>
                            {attachmentsLength}
                        </span>


                    </div>
                    <div className="property-card-tags">
                        <i className="fas fa-clock"/>{moment(entity.createdAt).fromNow()}
                    </div>

                    {/*<div className="property-card-tags">
                        <span className="label label-default label-tag-warning" style={{justifyContent: " start"}}>
                            <AEButton variant="light" onClick={handleShow}>
                                <img src={toAbsoluteUrl("/frontoffice/img/icon-notice.png")} alt=""
                                     className="right-icon"/>
                            </AEButton>
                        </span>
                    </div>*/}
                    <div className="property-card-box card-box card-block mt-2">
                        <h3 className="property-card-title m-2">
                            <Link to={'/annonces/:id/details'.replace(':id', entity.id)}>
                                {entity.title}
                            </Link>
                        </h3>

                        <div className="property-card-descr text-truncate">
                            {entity.description ? entity.description : 'Aucune Description.'}
                        </div>
                        <div className="property-preview-footer  clearfix">
                            <div className="property-preview-f-left text-color-primary">
                                <span className="property-card-value">
                                    <AECurrency value={entity.price}/>
                                </span>

                            </div>
                            {
                                entity.city ?
                                    <div className="property-preview-f-left text-color-primary">
                                    <span className="property-card-value">
                                        <i className="fa fa-map-marker"/>
                                        {entity.city.name}
                                    </span>
                                    </div> :
                                    <div className="property-preview-f-left text-color-primary">
                                    <span className="property-card-value">
                                        <i className="fa fa-map-marker"/>
                                        {formatMessage({id: 'CITY.NOT.SPECIFIED'})}
                                    </span>
                                    </div>
                            }
                            {/*<div className="property-preview-f-right text-color-primary">*/}
                            {/*    <ul className="nav navbar-nav">*/}
                            {/*        <li className="nav-item dropdown">*/}
                            {/*            <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#"*/}
                            {/*               role="button" aria-haspopup="true" aria-expanded="false">*/}
                            {/*                Partager*/}
                            {/*            </a>*/}
                            {/*            <div className="dropdown-menu">*/}
                            {/*                <AEShareButtons pathname={'/annonces/:id/details'.replace(':id', entity.id)}*/}
                            {/*                                facebook={true} whatsapp={true} telegram={true}/>*/}
                            {/*            </div>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}

                            {/*/!*<div className="separator separator-solid my-2"/>*!/*/}
                            {/*<div className="property-preview-f-left text-color-primary mt-3">*/}
                            {/*    /!*<span className="property-card-value">*!/*/}
                            {/*    /!*    Partager :*!/*/}
                            {/*    /!*</span>*!/*/}

                            {/*    /!*<AEShareButtons pathname={'/annonces/:id/details'.replace(':id', entity.id)}*!/*/}
                            {/*    /!*                facebook={true} whatsapp={true} telegram={true}/>*!/*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>

            {/*<Modal show={show} onHide={handleClose}
                    className={'max-w-100'}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
            >
                <Modal.Body>

                    <BidProcessForm entity={entity}/>

                </Modal.Body>
                <Modal.Footer>
                    <AEButton
                        variant={'light'}
                        onClick={handleClose}
                    >
                        {formatMessage({id: 'CANCEL'})}
                    </AEButton>
                    {/*
                    <AEButton
                        variant={'warning'}
                        onClick={deleteEntity}
                    >
                        {formatMessage({id: 'CONFIRM_DELETION'})}
                    </AEButton>* /}
                </Modal.Footer>
            </Modal>*/}
        </>
    );
}