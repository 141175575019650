import axios from "axios";
import {axiosFormDataConfigs, getFormData} from "../../../_ae/helpers/AxiosHelpers";
import {toAbsoluteApiUrl} from "../../../_metronic/_helpers";
import {MODULES} from "../helpers";

// import {normalize} from "../users/api";

export const API_URI = toAbsoluteApiUrl(`/${MODULES.AUTH}`);

const normalize = _entity => {
    const {id, firstName, lastName, email, gender} = _entity;
    const city = {id: _entity.city ? _entity.city.id : undefined}
    const roles = []

    if (_entity.roles) {
        roles.push({id: _entity.roles.id})
    }

    const username = email

    return {id, firstName, lastName, email, roles, city, username, gender}
}
export const all = metadata => {
    const {filters} = metadata
    // const {parent} = filters

    let meta = {
        ...metadata,
        filters: {
            ...filters,
            // parent: parent ? {id: parent.id} : undefined
        }
    };

    return axios.get(API_URI, {params: {meta}});
}
export const find = (id, metadata) => axios.get(`${API_URI}/${id}`, {params: {meta: metadata}});
export const destroy = id => axios.delete(`${API_URI}/${id}`);
export const create = (entity, files) => axios.post(API_URI, getFormData(normalize(entity), files), axiosFormDataConfigs);
export const update = (entity, files) => axios.post(`${API_URI}/profile?_method=PUT`, getFormData(normalize(entity), files), axiosFormDataConfigs);
export const updatePassword = entity => axios.put(`${API_URI}/password`, entity);


export const requestPassword = email => axios.post(`${API_URI}/password/forgot`, {email});
export const resetPassword = (token, data) => axios.post(`${API_URI}/password/reset/${token}`, data);
export const getUserByToken = () => axios.get(`${API_URI}/me`);
export const getAuthUserRoutes = () => axios.get(`${API_URI}/routes`);



