import React, {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {
    Card,
    CardBody, CardFooter,
    ModalProgressBar,
} from "../../../../../_metronic/_partials/controls";
import {useIntl} from "react-intl";
import {Formik} from "formik";
import * as Yup from "yup";
import {
    fetchResumeForEdit, resetResumeForEdit,
    saveResume,
    useResumesEditState
} from "../../../../../redux/store/resumes";
import {Form} from "./Form";
import {AEButton} from "../../../../../_ae/components/buttons";
import {useSelector} from "react-redux";

//todo server validation errors
export const Edit = ({history, match: {params: {id},}}) => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    const {isLoading, data, validationErrors} = useResumesEditState();
    const {user} = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(
            id ?
                fetchResumeForEdit(id) :
                resetResumeForEdit()
        );
    }, [id, dispatch]);

    const saveBtnRef = useRef();
    const saveBtnRefClick = () => {
        if (saveBtnRef && saveBtnRef.current) {
            const {current} = saveBtnRef;
            current.click()
        }
    };

    return (
        <Card>
            {isLoading && <ModalProgressBar/>}
            <CardBody className={'pt-10'}>
                <Formik
                    enableReinitialize
                    initialErrors={validationErrors}
                    validationSchema={
                        Yup.object().shape({
                            fullName: Yup.string().required(),
                            profile: Yup.string().required(),
                            email: Yup.string().email().required(),
                            phone: Yup.string().required(),
                            address: Yup.string().required(),
                            fileName: Yup.mixed(),
                            formations: Yup.array().of(
                                Yup.object().shape({
                                title: Yup.string().required(),
                                description: Yup.string(),
                                startDate: Yup.date().required(),
                                endDate: Yup.date().required(),
                                })
                            ),
                            experiences: Yup.array().of(
                                Yup.object().shape({
                                    title: Yup.string().required(),
                                    description: Yup.string(),
                                    startDate: Yup.date().required(),
                                    endDate: Yup.date(),
                                })
                            ),
                            skills: Yup.array().of(
                                Yup.object().shape({
                                    skill: Yup.string().required(),
                                    level: Yup.string(),
                                })
                            ),
                            languages: Yup.array().of(
                                Yup.object().shape({
                                    language: Yup.string().required(),
                                    level: Yup.string(),
                                })
                            ),
                            hobbies: Yup.array().of(
                                Yup.object().shape({
                                    label: Yup.string().required(),
                                })
                            ),
                            user: Yup.object(),
                        })
                    }
                    initialValues={data}
                    onSubmit={({fileName, ...values}) => {
                        values.user = user;
                        dispatch(saveResume(values, {fileName}))
                            .then(() => {
                                history.goBack();
                            });
                    }}
                    render={formik => (
                        <Form
                            {...formik}
                            btnRef={saveBtnRef}
                        />)}
                />
            </CardBody>
            <CardFooter className={'d-flex justify-content-end'}>
                <AEButton
                    variant={'light'}
                    onClick={history.goBack}
                >
                    <i className="fa fa-arrow-left"/>
                    {formatMessage({id: 'BACK'})}
                </AEButton>
                <AEButton
                    className="ml-2"
                    onClick={saveBtnRefClick}
                >
                    {formatMessage({id: 'SAVE'})}
                </AEButton>
            </CardFooter>
        </Card>
    );
}

