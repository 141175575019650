import axios from "axios";
import {axiosFormDataConfigs, getFormData} from "../../../_ae/helpers/AxiosHelpers";
import {toAbsoluteApiUrl} from "../../../_metronic/_helpers";
import {MODULES} from "../helpers";

export const API_URI = toAbsoluteApiUrl(`/${MODULES.RESUMES}`);

const normalize = _entity => {
    const {id, fullName, fileName, email, phone, profile, address} = _entity;

    const user = {id: _entity.user ? _entity.user.id : undefined}

    let formations = undefined;
    if (_entity.formations) {
        formations = _entity.formations.map(formation => formation)
    }
    let skills = undefined;
    if (_entity.skills) {
        skills = _entity.skills.map(skill => skill)
    }
    let experiences = undefined;
    if (_entity.experiences) {
        experiences = _entity.experiences.map(experience => experience)
    }
    let languages = undefined;
    if (_entity.languages) {
        languages = _entity.languages.map(language => language)
    }

    let hobbies = undefined;
    if (_entity.hobbies) {
        hobbies = _entity.hobbies.map(hobby => hobby)
    }


    return {
        id, fullName, fileName, email, phone, profile, address,
        formations, skills, experiences, languages, hobbies, user
    }
}

export const all = (metadata) => axios.get(API_URI, {params: {meta: metadata}});
export const find = (id, metadata) => axios.get(`${API_URI}/${id}`, {params: {meta: metadata}});
export const destroy = id => axios.delete(`${API_URI}/${id}`);
export const create = (entity, files) => axios.post(API_URI, getFormData(normalize(entity), files), axiosFormDataConfigs);
export const update = (entity, files) => axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(normalize(entity), files), axiosFormDataConfigs);
