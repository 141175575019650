import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AEAutocompleteField, AEField, AEFileField} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {
    fetchCategoriesForSubCategoriesFilter,
    useSubCategoriesFilterState
} from "../../../../../redux/store/categories";
import {fetchPostsByUser, usePostsByUserListState} from "../../../../../redux/store/posts";
import {MODULES} from "../../../../../redux/store/helpers";
import {useMetadata} from "../../../../../_ae/AEPagination";

export const Form = ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         setFieldValue,
                         btnRef,
                     }) => {
    const dispatch = useDispatch();
    const {data: categories} = useSubCategoriesFilterState();
    // const [selectedCity, setSelectedCity] = React.useState(undefined);
    const [selected, setSelected] = React.useState("");
    // const {data: locations} = useLocationsUsersFilterState();
    const {data: postsByUser, isLoading, metadata} = usePostsByUserListState()
    const {user} = useSelector(state => state.auth);

    const metadataState = useMetadata(metadata)
    const {page, perPage, sortAsc, filters, sortField, select} = metadataState;


    let options = null;
    const changeSelectOptionHandler = (event, value) => {
        setSelected(value);
    };

    useEffect(() => {
        if (user) {
            dispatch(fetchPostsByUser(user.id, metadataState.toAEMetadata()));
        }
    }, [dispatch, user])

    useEffect(() => {
        // dispatch(fetchLocationForUsersFilter())
        dispatch(fetchCategoriesForSubCategoriesFilter())
    }, [dispatch])


    if (selected) {
        let cat = [];
        cat.push(selected);
        options = selected.subCategories.length > 0 ? selected.subCategories : cat;
    }

    return (
        <div className="form form-label-right">

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        type="text"
                        name="title"
                        label={'TITLE'}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        name="description"
                        label={'DESCRIPTION'}
                        multiline
                        type={'textarea'}
                        rows={4}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        name="price"
                        label={'PRICE'}
                    />
                </div>
            </div>

            <div className="form-group row py-1">
                <div className={`col-lg-6`}>
                    {<AEAutocompleteField
                        name="supercategory"
                        label={'SUPER_CATEGORY'}
                        options={categories.filter(category => category.parrent == null)}
                        getOptionLabel={o => o.label}
                        onChange={changeSelectOptionHandler}
                    />}
                </div>

                <div className={`col-lg-6`}>
                    {
                        options &&
                        <AEAutocompleteField
                            name="category"
                            label={'CATEGORY'}
                            options={options}
                            getOptionLabel={o => o.label}
                            // onChange={categoryChangeHandler}
                        />
                    }
                </div>
            </div>

            <div className="form-group row py-1">
                <div className={`col-lg-12`}>
                    <AEAutocompleteField
                        name="posts"
                        label={'POSTS'}
                        options={postsByUser}
                        getOptionLabel={o => o.title}
                        multiple
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEFileField
                        name="fileName"
                        label={'Miniature'}
                        preview
                        previewPath={`/${MODULES.GROUPS}`}
                    />
                </div>
            </div>

            <button
                style={{display: "none"}}
                ref={btnRef}
                onClick={handleSubmit}
            />

        </div>
    );
}

