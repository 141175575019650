import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import {useIntl} from "react-intl";
import {AEAutocompleteField, AEField} from "../../../../../_metronic/_partials/controls/forms/AEField";
import { Currency } from "../../components/Currency";
import { Name } from "../../components/Name";

/*const CURRENCIES = {
  EUR : {
    'id': 
  }
}
*/
//todo server validation errors
export const Form = ({
                       values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                       handleSubmit,
                       isSubmitting,
                       btnRef,
}) => {

  return (
    <div className="form form-label-right">
      <div className="form-group row">
        <div className={"col-lg-12"}>
          <AEField
            name="name"
            label={'NAME'}
          />
        </div>
      </div>
      <div className="form-group row py-1">
        <div className={`col-lg-12`}>
          <AEAutocompleteField
            name="currency"
            label={'CURRENCY'}
            options={['dollar','Moroccan dirham','euro']}
            getOptionLabel={o=>o}
          />
        </div>
      </div>
      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
    </div>
  );
}

