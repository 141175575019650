import React from "react";
import {AEField, AEFileField} from "../../../../../_metronic/_partials/controls/forms/AEField";

export const Form = ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         btnRef,
                     }) => {

    return (
        <div className="form form-label-right">

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        name="title"
                        label={'TITLE'}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        name="detail"
                        label={'DESCRIPTION'}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        name="link"
                        label={'LINK'}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEFileField
                        name="fileName"
                        label={'IMAGE'}
                    />
                </div>
            </div>

            <button
                style={{display: "none"}}
                ref={btnRef}
                onClick={handleSubmit}
            />
        </div>
    );
}

