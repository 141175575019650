import React from "react";


export const Title = ({entity, fontSize='md', fontWeight='bold'})=>{
  
  return (
    <span className={`font-size-${fontSize} font-weight-${fontWeight}`}>
      {entity.title} 
    </span>
  )
}