import {getSlice, MODULES, useCustomSelector} from "../../helpers";
import * as API from "../api";
import {AEMetadata} from "../../../../_ae/AEPagination";

const {actions, name, reducer} = getSlice({
  name: `${MODULES.GROUPS}.details`,
  data: {},
  metadata: new AEMetadata(
    { perPage: 12 },
    {field: 'title'},
    {},
    {"*": [], "author": {"*":[]}, "category": {"*":[]}},
  )
})

const {startCall, endCall, fetched, catchError, reset} = actions;

const resetDispatcher = () => dispatch => {
  dispatch(reset());
}

const fetchDispatcher = (id, metadata) => dispatch => {
  dispatch(startCall());


  return API
    .find(id, metadata)
    .then(response => {

      dispatch(fetched(response));
    })
    .catch(response => {
      dispatch(catchError(response));
    }).then(response => {
      dispatch(endCall(response));
    })
    ;
};
const useSelector = () => useCustomSelector(name)

export {
  actions,
  name,
  reducer,
  fetchDispatcher,
  resetDispatcher,
  useSelector
}
