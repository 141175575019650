import {getSlice, MODULES, useCustomSelector, useMetadata} from "../../helpers";
import {AEMetadata} from "../../../../_ae/AEPagination";
import * as API from "../api";

const {actions, name, reducer} = getSlice({
    name: `${MODULES.POSTS}.list`,
    data: [],
    metadata: new AEMetadata(
        {perPage: 12},
        {field: 'createdAt', asc: false},
        {
            "status": "enabled"
        },
        {
            '*': [],
            "category": {'*': [], "subCategories": {'*': []}},
            "city": {"*": []}
        }
    )
})

const {startCall, endCall, fetched, catchError, reset} = actions;


const resetDispatcher = () => dispatch => {
    dispatch(reset());
}

const fetchDispatcher = metadata => dispatch => {
    dispatch(startCall());

    return API
        .all(metadata)
        .then(response => {
            dispatch(fetched(response));

            return response;
        })
        .catch(response => {
            dispatch(catchError(response));

            return response;
        }).then(response => {
            dispatch(endCall(response));
        })
        ;
};

const useSelector = () => useCustomSelector(name)
// const useMeta = () => useMetadata(metadata)

export {
    actions,
    name,
    reducer,
    fetchDispatcher,
    resetDispatcher,
    useSelector,
}


