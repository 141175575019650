import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchStories, useStoriesListState} from "../../../redux/store/stories";
import {useMetadata} from "../../../_ae/AEPagination";
import {StoryCarousel} from "./Stories/StoryCarousel";
import {StoriesModal} from "./Stories/StoriesModal";
import {toEntityFileNameUrl} from "../../../_metronic/_helpers";
import {Loader} from "../../../_ae/components/loader";
import {DeleteStory} from "./Stories/deleteStory";
import {Link} from "react-router-dom";
import {StoryAvatar} from "./Stories/Avatar";
// import {StoryAvatar} from "./Stories/Avatar";
// import OwlCarousels from "../../../_ae/components/OwlCarousel";
// import {toEntityFileNameUrl} from "../../../_metronic/_helpers";
// import {Link} from "react-router-dom";

export function Stories() {

    const {user} = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const {data: stories, metadata} = useStoriesListState()
    const metadataState = useMetadata(metadata)
    const {sortAsc, sortField} = metadataState;
    const [groupedStories, setGroupedStories] = useState([]);
    const [active, setActive] = useState(0)

    const modalRef = useRef();
    const closeModalRef = useRef();
    const handleCloseModalRef = () => {
        if (closeModalRef && closeModalRef.current) {
            if (modalRef && modalRef.current) {
                const {current} = modalRef;
                current.classList.remove("model-shown");
            }
        }
    };


    useEffect(() => {
        dispatch(fetchStories(metadataState.toAEMetadata()));
    }, [dispatch, sortField, sortAsc]);


    let i;
    for (i = 0; i < document.getElementsByClassName("story-item").length; i++) {
        let img = document.getElementsByClassName("story-item")[i];
        img.onclick = function () {
            let activeStory = img.closest('.owl-item')
            //  console.log('story_' + this.id)
            if (modalRef && modalRef.current) {
                const {current} = modalRef;

                for (i = 0; i < document.getElementsByClassName("story_carousel_item").length; i++) {
                    let img = document.getElementsByClassName("story_carousel_item")[i];
                    img.classList.remove("active");
                }

                setActive(this.id)
                let activeStory = document.getElementById('story_'+ this.id);
                activeStory.classList.add("active");
                current.classList.add("model-shown");
            }
        }
    }

    useEffect(() => {
        if (stories && stories.length > 0)
            setGroupedStories(
                stories.reduce((r, a) => {
                    r[a.author.id] = [...r[a.author.id] || [], a];
                    return r;
                }, {}))
    }, [dispatch, stories]);


    return (
        <>
            <div className="container">
                {
                    groupedStories &&
                    <StoryCarousel adClass="owl-simple stories-carousel" stories={groupedStories}/>
                }
            </div>
            <div id="image-cover-modal" className="image-cover-modal" ref={modalRef}>
                <span className={"stories-modal-close"} ref={closeModalRef} onClick={handleCloseModalRef}>
                    <i className="fa fa-close fa-2x"/>
                </span>
                {/*<span className="slide-progress" id="slide-progress">-</span>*/}
                {/*<div className="circular">*/}
                {/*    <div className="inner">-</div>*/}
                {/*    <div className="number">100%</div>*/}
                {/*    <div className="circle">*/}
                {/*        <div className="bar left">*/}
                {/*            <div id="progress" className="progress">-</div>*/}
                {/*        </div>*/}
                {/*        <div className="bar right">*/}
                {/*            <div className="progress">-</div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {

                    <div id="stories-slider" className="carousel slide" data-ride="carousel">
                        {/*
                           <ol className="carousel-indicators">
                            {
                                groupedStories &&
                                Object.values(groupedStories).map((stories, index) => {
                                    return stories.map((story, index) => {
                                        return (
                                            <li key={index} data-target="#header-slider" data-slide-to={index}
                                                className={`${index === 0 ? 'active' : ''}`}/>
                                        );
                                    })
                                })
                            }
                        </ol>*/}
                        <div className="carousel-inner" role="listbox" style={{'width': 20 +"rem"}}>
                            {
                                groupedStories &&
                                Object.values(groupedStories).map((stories, index) => {
                                    return stories.map((story, index) => {
                                        return (
                                            <div id={'story_' + story.id} key={index}
                                                 className={`item story_carousel_item ${story.id === active || index === 0 ? 'active' : ''}`}>
                                                <div className="card-body image-box">
                                                    {
                                                        (story.fileName.split(".")).pop() === "mp4" ?
                                                            <video width="600" height="210"
                                                                   className="align-self-center story-modal-item"
                                                                   style={{maxWidth: 100 + '%'}}
                                                                   muted controls>
                                                                <source
                                                                    src={toEntityFileNameUrl(story, 'stories')}
                                                                    type="video/mp4"/>
                                                                Your browser does not support the video tag.
                                                            </video>

                                                            :

                                                            <img className="card-img-top story-modal-item"
                                                                 src={toEntityFileNameUrl(story, 'stories')}
                                                                 alt=""/>
                                                    }


                                                    {
                                                        user &&
                                                        story.author.id === user.id &&
                                                        <DeleteStory id={story.id}/>
                                                    }

                                                    {
                                                        story.author &&
                                                        <span className={"story-label_image"}>
                                                         <Link
                                                             to={'/annonces/by/user/:id'.replace(':id', story.author.id)}
                                                             className="agent-name text-color-primary">
                                                            <StoryAvatar
                                                                entity={story.author}
                                                                className={"rounded-circle mr-2 border border-4 border-info"}
                                                                size={30}/>
                                                        </Link>
                                                </span>
                                                        // <span>
                                                        //     {story.author.firstName}{` `}{story.author.lastName}
                                                        // </span>
                                                    }
                                                    {
                                                        story.author &&
                                                        <span className={"story-label_username"}>
                                                    {story.author.firstName} {story.author.lastName}
                                                </span>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })
                                })
                            }
                        </div>

                        <a className="right carousel-control" href="#stories-slider" role="button" data-slide="next">
                            <span className="icon-next" aria-hidden="true"/>
                            <span className="sr-only">Suivant</span>
                        </a>
                        <a className="left carousel-control" href="#stories-slider" role="button" data-slide="prev">
                            <span className="icon-prev" aria-hidden="true"/>
                            <span className="sr-only">Précedant</span>
                        </a>
                    </div>
                    // <StoriesModal stories={groupedStories} adClass="owl-simple stories-modal w-75"/>
                }
                {/*<img id="image-cover-image" className="image-cover-modal-content"/>*/}
                {/*<video width="600" height="500"*/}
                {/*       className="align-self-center story-item image-cover-modal-content"*/}
                {/*       id="video-cover-video"*/}
                {/*       style={{maxWidth: 100 + '%'}}*/}
                {/*       autoPlay muted controls>*/}
                {/*    <source*/}
                {/*        src="#"*/}
                {/*        type="video/mp4"*/}
                {/*        className="image-cover-modal"*/}
                {/*        id="video-cover-image"/>*/}
                {/*    Your browser does not support the video tag.*/}
                {/*</video>*/}
                {/*<div id="image-cover-caption"/>*/}
            </div>
        </>
    )
}