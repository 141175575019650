import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {AEAutocompleteField, AEField} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {AEButton} from "../../../../../_ae/components/buttons";
import {FieldArray} from "formik";
import {AESVG, SVG_ICON} from "../../../../../_ae/components/svg";

import {
    useSubOptionsFilterState,
    fetchAttributesForSubOptionsFilter,
    useSubOptionsEditState
} from "../../../../../redux/store/attributes";

import {
    fetchCategoriesForSubCategoriesFilter,
    useSubCategoriesFilterState
} from "../../../../../redux/store/categories";

//todo server validation errors
export const Form = ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         btnRef,
                     }) => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    const {data: parrent} = useSubOptionsFilterState();
    const {data: subOptions} = useSubOptionsEditState();
    const {data: categories} = useSubCategoriesFilterState();
    const [selected, setSelected] = React.useState("");

    let options = null;
    const changeSelectOptionHandler = (event, value) => {
        setSelected(value);
    };


    useEffect(() => {
        dispatch(fetchCategoriesForSubCategoriesFilter())
    }, [dispatch])


    useEffect(() => {
        dispatch(fetchAttributesForSubOptionsFilter())
    }, [dispatch])


    if (selected) {
        let cat = [];
        cat.push(selected);
        if (selected.subCategories.length > 0) {
            selected.subCategories.map(
                subcategory => cat.push(subcategory)
            )
           // cat.push(selected.subCategories);
        }

        options = cat;
        // options = selected.subCategories.length > 0 ? selected.subCategories : cat;
    }

    return (
        <div className="form form-label-right">

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        name="label"
                        label={'LABEL'}
                    />
                </div>
            </div>

            <div className="form-group row py-1">
                <div className={`col-lg-12`}>
                    <AEAutocompleteField
                        name="multiple"
                        label={'Attribut_A_Choix'}
                        options={['multiple', 'single']}
                        getOptionLabel={o => o}
                    />
                </div>
            </div>

            <div className="form-group row py-1">
                <div className={`col-lg-12`}>
                    <AEAutocompleteField
                        name="parrent"
                        label={'PARRENT'}
                        options={parrent}
                        getOptionLabel={o => o.label}
                    />
                </div>
            </div>

            <div className="form-group row py-1">
                <div className={`col-lg-6`}>
                    {<AEAutocompleteField
                        name="supercategory"
                        label={'SUPER_CATEGORY'}
                        options={categories.filter(category => category.parrent == null)}
                        getOptionLabel={o => o.label}
                        onChange={changeSelectOptionHandler}
                    />}
                </div>

                <div className={`col-lg-6`}>
                    {
                        options &&
                        <AEAutocompleteField
                            name="category"
                            label={'CATEGORY'}
                            options={options}
                            getOptionLabel={o => o.label}
                        />
                    }
                </div>
            </div>

            <div className="form-group">
                {<FieldArray
                    name="subOptions"
                    render={(helpers) => (
                        <div>
                            <div className={'d-flex align-items-center form-group'}>
                <span className="font-size-h3 font-weight-bold">
                  {formatMessage({id: 'SUBOPTIONS'})}
                </span>
                                <AEButton
                                    variant={'clean'}
                                    icon
                                    onClick={() => {
                                        helpers.push({
                                            label: ''
                                        })
                                    }}
                                >
                                    <AESVG
                                        variant={'primary'}
                                        path={SVG_ICON.PLUS}
                                        size={'lg'}
                                    />
                                </AEButton>
                            </div>
                            {
                                values.subOptions.map((subOption, index) => (
                                    <div key={index} className="d-flex form-group">
                                        <div className={'flex-grow-1'}>
                                            <AEAutocompleteField
                                                name={`subOptions.${index}`}
                                                label={'SUBOPTION'}
                                                options={subOptions}
                                                getOptionLabel={o => o.label}
                                            />
                                        </div>
                                        <div className={''}>
                                            <AEButton
                                                variant={'clean'}
                                                icon
                                                onClick={() => {
                                                    helpers.remove(index)
                                                }}
                                            >
                                                <AESVG
                                                    variant={'danger'}
                                                    path={SVG_ICON.DELETE}
                                                    size={'lg'}
                                                />
                                            </AEButton>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )}
                />
                }
            </div>

            <button
                style={{display: "none"}}
                ref={btnRef}
                onClick={handleSubmit}
            />

        </div>
    );
}

