import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {
    useBidProcessesListState,
    fetchBidProcesses,
} from "../../../../redux/store/bidProcess";
import {BidRow} from "./BidRow";
import {Link} from "react-router-dom";
import Header from "../Header";


export const BidList = () => {
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.auth);
    const {data: bid_data} = useBidProcessesListState()

    useEffect(() => {
        if (user) dispatch(fetchBidProcesses(user.id));
    }, [user, dispatch]);


    return (
        <>
            <Header/>
            <main className="main section-color-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <section className="top-title">
                                <ul className="breadcrumb">
                                    <li className="item">
                                        <Link to="/">Acceuil</Link>
                                    </li>
                                    <li className="item">Votre Enchére</li>
                                </ul>

                            </section>

                            <div className="widget widget-box box-container widget-mylistings">
                                <div className="widget-header text-uppercase">
                                    <h2>Mes propositions d'achat</h2>
                                </div>
                                <div className="">
                                    <table className="table table-striped footable-sort" data-sorting="true">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th data-breakpoints="xs sm md" datatype="html">Titre de l'annonce</th>
                                            <th data-breakpoints="xs sm md" datatype="html">Date de l'envoi de l'offre
                                            </th>
                                            <th data-breakpoints="xs sm md" datatype="html">Prix Proposé</th>
                                            <th data-breakpoints="xs sm md" datatype="html">Notes</th>
                                            <th data-breakpoints="xs md" className="control" datatype="html">Actions
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            bid_data &&
                                            bid_data.map((entity, index) => (
                                                <BidRow key={entity.id} entity={entity} index={index}/>
                                            ))
                                        }
                                        </tbody>
                                    </table>

                                </div>
                            </div>

                            <div className="widget widget-box box-container">
                                <div className="widget-header text-uppercase">
                                    <h3>Vos informations</h3>
                                </div>
                                <div className="widget-content">
                                    Nom & Prénom: {user.firstName}{` `}{user.lastName}<br/>
                                    Email: {user.email}<br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
