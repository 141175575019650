import React from 'react';
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../../_metronic/_helpers";
import {Link} from "react-router-dom";
import SVG from "react-inlinesvg";
import {AECurrency} from "../../../../../_ae/components/Currency"
import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";


export const FeaturedPostCard = ({entity}) => {

    let hasvideos = false
    hasvideos = entity.postAttachements.some(function (attachment) {
        const splittedFileName = attachment.fileName.split(".");
        return splittedFileName[splittedFileName.length - 1] === "mp4";
    });

    let attachmentsLength = 0;
    if (entity.postAttachements) {
        if (entity.fileName) {
            attachmentsLength = entity.postAttachements.length + 1;
        } else {
            attachmentsLength = entity.postAttachements.length;
        }
    }
    return (
        <>
            <div className="col-md-12">
                <div className="property-card card">
                    <div className="property-card-header image-box">
                        {
                            entity.fileName ?
                                <img src={toEntityFileNameUrl(entity, 'posts')} alt={` `}
                                     className="mx-auto img-responsive align-self-center"/>
                                :
                                <SVG
                                    className="mx-auto img-responsive align-self-center"
                                    src={toAbsoluteUrl("/media/svg/gallery.svg")}
                                />
                        }
                        {/*<i className="symbol-badge bg-primary"/>*/}

                        <div className="budget"><i className="fa fa-bolt"/></div>

                        <Link to={'/annonces/:id/details'.replace(':id', entity.id)} className="property-card-hover">
                            <img src={toAbsoluteUrl("/frontoffice/img/plus.png")} alt="" className="center-icon"/>
                        </Link>

                        <span className="property-card-value text-white bg-primary p-1">
                            {hasvideos && <i className="fa fa-video text-white p-1"/>}
                            <i className="fa fa-images text-white p-1"/>
                            {attachmentsLength}
                        </span>

                    </div>
                    <div className="property-card-tags">
                        <i className="fas fa-clock"/>{moment(entity.createdAt).fromNow()}
                    </div>

                    <div className="property-card-box card-box card-block mt-2">
                        <h3 className="property-card-title m-2">
                            <Link to={'/annonces/:id/details'.replace(':id', entity.id)}>
                                {entity.title}
                            </Link>
                        </h3>

                        <div className="property-card-descr text-truncate">
                            {entity.description ? entity.description : 'Aucune Description.'}
                        </div>
                        <div className="property-preview-footer  clearfix">
                            <div className="property-preview-f-left text-color-primary">
                                <span className="property-card-value">
                                    <AECurrency value={entity.price}/>
                                </span>
                                {entity.city &&
                                <span className="property-card-value">
                                    <i className="fa fa-map-marker"/>
                                    {entity.city.name}
                                </span>}
                            </div>
                            {/*<div className="property-preview-f-left text-color-primary">*/}
                            {/*    <span className="property-card-value">*/}
                            {/*        <i className="fa fa-map-marker"/>*/}
                            {/*        <Location location={entity.location} fontSize='sm'/>*/}
                            {/*    </span>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}