import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import Header from "../Home/Header";
import {fetchPostsByUser, usePostsByUserListState} from "../../../../../redux/store/posts";
import {fetchUserForEdit, useUsersEditState} from "../../../../../redux/store/users";
import {useMetadata} from "../../../../../_ae/AEPagination";
import {AELabel} from "../../../../../_ae/components/AELabel";
import {toAbsoluteUrl, toEntityFileNameUrl, toQrCodeFileNameUrl} from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import {AECurrency} from "../../../../../_ae/components/Currency";
import {Pagination} from "../../../../../_metronic/_partials/controls";
import {getNonAccentString} from "../../../../../_ae/components/AEUtils";

export function StoreDetails() {
    const dispatch = useDispatch();
    const {id} = useParams();
    // console.log("useParams", useParams()); #id, storeName
    const {data: postsByUser, metadata} = usePostsByUserListState()
    const {data} = useUsersEditState();

    const metadataState = useMetadata(metadata)
    const {page, perPage, sortAsc, filters, sortField, select} = metadataState;

    let storeName = "";
    if (data && data.store) storeName = getNonAccentString(data.store?.name);

    const getEntities = () => {
        dispatch(fetchPostsByUser(id, metadataState.toAEMetadata()));
    }

    useEffect(() => {
        getEntities()
    }, [id, dispatch, page, perPage, filters.search, select, sortField, sortAsc]);

    useEffect(() => {
        dispatch(fetchUserForEdit(id));
    }, [id, dispatch]);


    return (
        <>
            <header className="header">
                <Header/>
                <div className="top-box-mask"/>
                <section className="top-title-widget color-primary">
                    <div className="container">
                        <ul className="breadcrumb">
                            <li className="item"><a href="/"> Acceuil </a></li>
                            <li className="item"> Boutiques</li>
                        </ul>
                        <h1 className="top-title-t">Boutique : {data && data.store ? data.store.name : ''}</h1>
                    </div>
                </section>
            </header>


            <main className="main main-container section-color-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <section className="widget widget-blog-listing widget-overflow widget-ask">
                                <div className="box-overflow-container box-container">

                                    <div className="">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="agent-detail-picture">
                                                    {
                                                        data.fileName ?
                                                            <img src={toEntityFileNameUrl(data, 'users')}
                                                                 alt={` `}
                                                                 className="mx-auto img-responsive"/>
                                                            :
                                                            <img
                                                                src={toAbsoluteUrl("/frontoffice/img/placeholders/180x265.png")}
                                                                alt=""
                                                                className="mx-auto img-responsive"/>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="box-container-title">
                                                    <h2 className="title"> {data && data.store ? data.store.name : ''}</h2>
                                                </div>

                                                <div className="box-container-title">
                                                    <h4 className="sub-title">
                                                        {data && data.store ? data.store.description : ''}
                                                    </h4>
                                                </div>

                                                <div className="agent">
                                                    {
                                                        data && data.phone &&
                                                        <div className="phone text-color-primary">
                                                            <span>
                                                                <i className="fa fa-phone p-2">  Téléphone: </i>
                                                            </span>
                                                            {data.phone}
                                                        </div>
                                                    }
                                                    <div className="mail">
                                                        <a href="mailto:grace@estatepoint.com"
                                                           className="primary-hover">
                                                            <span>
                                                                <i className="fa fa-envelope p-2"> Email: </i>
                                                            </span>
                                                            {data && data.email ? data.email : '-'}
                                                        </a>
                                                    </div>
                                                    <div className="addresse mt-1">
                                                        <div className="text-color-primary">
                                                            <span>
                                                                <i className="fa fa-map-marker p-2"> Ville: </i>
                                                            </span>
                                                            {data.city?.name}
                                                        </div>
                                                    </div>
                                                </div>

                                                <span className="text-color-primary">
                                                    <i className="fas fa-th-large p-2 d-block"> Catégories vendues dans cette boutique: </i>
                                                </span>
                                                {
                                                    data && data.store && data.store.categories &&
                                                    data.store.categories.map(category => {
                                                        return (
                                                            <AELabel
                                                                key={category.id}
                                                                size={"lg"}
                                                                variant={"light-secondary"}
                                                            >
                                                                {category.label}
                                                            </AELabel>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {
                                                data.store &&
                                                <div className="col-sm-3">
                                                    <div className="agent-detail-picture">
                                                        <img src={toQrCodeFileNameUrl(data.store, 'stores')}
                                                             alt={`QrCode`}
                                                             className="mx-auto img-responsive"/>
                                                        {/*<AEQRCode*/}
                                                        {/*    pathname={'boutiques/:id/:storeName'.replace(':id', data.id).replace(':storeName', storeName)}/>*/}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="widget widget-profilelisting">
                                <div className="widget-body">
                                    <div className="widget-header text-uppercase">
                                        <h2>Toutes les annonces de la
                                            boutique: {data && data.store ? data.store.name : ''}</h2>
                                    </div>
                                </div>
                                <div className="properties">
                                    <div className="row">
                                        {
                                            postsByUser &&
                                            postsByUser.map((post, index) => {
                                                return (
                                                    <div key={index} className="col-md-4 col-sm-6">
                                                        <div className="property-card card">
                                                            <div className="property-card-header image-box">
                                                                {
                                                                    post.fileName ?
                                                                        <img src={toEntityFileNameUrl(post, 'posts')}
                                                                             alt={` `}
                                                                             className=""/>
                                                                        :
                                                                        <SVG
                                                                            className=""
                                                                            src={toAbsoluteUrl("/media/svg/gallery.svg")}
                                                                        />
                                                                }
                                                                <Link
                                                                    to={'/annonces/:id/details'.replace(':id', post.id)}
                                                                    className="property-card-hover">
                                                                    <img
                                                                        src={toAbsoluteUrl("/frontoffice/img/icon-notice.png")}
                                                                        alt="" className="center-icon"/>
                                                                </Link>
                                                            </div>
                                                            <div className="property-card-tags">

                                                            </div>
                                                            <div className="property-card-box card-box card-block">
                                                                <h3 className="property-card-title">
                                                                    <Link
                                                                        to={'/annonces/:id/details'.replace(':id', post.id)}>
                                                                        {post.title}
                                                                    </Link>
                                                                </h3>
                                                                <div className="property-card-descr text-truncate">
                                                                    {post.description ? post.description : 'Pas de description'}
                                                                </div>

                                                                <div className="property-preview-footer  clearfix">
                                                                    <div
                                                                        className="property-preview-f-left text-color-primary">
                                                                        <span className="property-card-value">
                                                                            <AECurrency value={post.price}/>
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        className="property-preview-f-left text-color-primary">
                                                                        {
                                                                            post.city &&
                                                                            <div
                                                                                className="property-preview-f-left text-color-primary">
                                                                                <span className="property-card-value">
                                                                                    <i className="fa fa-map-marker"/>
                                                                                    {post.city.name}
                                                                                </span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    <nav className="text-center">
                                        <Pagination {...metadataState} counts={[12, 24, 48]}/>
                                    </nav>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}