import React, {useEffect} from "react";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import {AESVG, SVG_ICON} from "../../../../_ae/components/svg";
import {useIntl} from "react-intl";
import {AELink} from "../../../../_ae/components/AELink";
import {Title} from "./Title";
import {DropdownCustomToggler,} from "../../../../_metronic/_partials/dropdowns";
import {Dropdown} from "react-bootstrap";
import {AERole} from "../../../../_ae/components/AERole";
import {useRoutesForAppState} from "../../../../redux/store/routes";
import {AETruncate} from "../../../../_ae/helpers/UIHelper";
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import {AELabel} from "../../../../_ae/components/AELabel";
import {BidRow} from "./BidRow";
import {useDispatch} from "react-redux";
import {fetchBidProcesses, useBidProcessesListState} from "../../../../redux/store/bidProcess";
import {useMetadata} from "../../../../_ae/AEPagination";

export const DetailsCard = ({
                                entity,
                                size = "md",
                                className = '',
                                avatar,
                                editAction,
                                deleteAction,
                                changeStatusAction,
                            }) => {

    const dispatch = useDispatch();
    const {formatMessage} = useIntl()
    const {data: routes} = useRoutesForAppState();
    const {data: bid_data, metadata} = useBidProcessesListState()

    const metadataState = useMetadata(metadata)
    const {page, perPage, sortAsc, filters, sortField, select} = metadataState;


    useEffect(() => {
        if (entity){
            dispatch(fetchBidProcesses({
                ...metadataState.toAEMetadata(),
                filters: {...metadataState.toAEMetadata().filters, post: {id: entity.id}},
                columns: {...metadataState.toAEMetadata().columns, post: {"*": []}}
            }));
        }
    }, [entity, dispatch, page, perPage, filters.post, select, sortField, sortAsc, metadataState]);



    const classes = {
        xs: {
            card: 'shadow-none ',
            cardBody: 'p-1',
            symbol: 'symbol-40 mr-2',
            title: 'h6',
        },
        sm: {
            card: 'shadow-none ',
            cardBody: 'p-2',
            symbol: 'symbol-70 mr-2',
            title: 'h5',
        },
        md: {
            symbol: 'symbol-70 mr-5',
            cardBody: 'p-3 d-flex flex-column',
            title: 'h4',
        },
        lg: {
            symbol: 'symbol-80 mr-5',
            title: 'h3',
            cardBody: 'p-4',
        },
    }

    const sizeClasses = classes[size];
    const isLg = size === 'lg'
    const isMd = size === 'md'
    const isXs = size === 'xs'

    const actions = [
        {id: 'POSTS.EDIT', show: editAction},
        {id: 'POSTS.DELETE', show: deleteAction},
    ].filter(action => action.show);


    return (
        <>
            <Card className={`${className}`}>
                <CardBody className={`${sizeClasses.cardBody}`}>
                    <div className={`d-flex`}>

                        <div className={`${isXs ? '' : '-d-flex align-items-start'}`} style={{width: 35 + '%'}}>
                            <div className={`symbol symbol-70 mr-5 symbol-150 h-100`}>
                                {
                                    entity.fileName ?
                                        <img src={toEntityFileNameUrl(entity, 'posts')} alt={` `}
                                             className="mw-100 h-100 align-self-center"/>
                                        :
                                        <span className="symbol-label">
                                          <SVG
                                              className="mw-100 h-100 align-self-center"
                                              src={toAbsoluteUrl("/media/svg/gallery.svg")}
                                          />
                                        </span>
                                }
                            </div>
                        </div>

                        <div className="flex-grow-1">
                            <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                                <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                                    <Title entity={entity} fontSize={sizeClasses.title} fontWeight={'bold'}/>
                                    - {formatMessage({id: "PRICE"})}: {entity.price} MAD
                                </div>

                                {
                                    actions.length > 0 &&
                                    <Dropdown>
                                        <Dropdown.Toggle as={DropdownCustomToggler}>
                                            <AESVG path={SVG_ICON.DOTS} variant={'primary'}/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {
                                                actions
                                                    .map(action => {
                                                        const route = routes.find(r => r.id === action.id);
                                                        if (!route) return '';
                                                        const path = route.path.replace(':id', entity.id)

                                                        return (
                                                            <AERole key={route.id} roles={route.roles}>
                                                                <AELink to={path} className="dropdown-item">
                                                                    <AESVG className={'pr-2'} path={route.svg}/>
                                                                    {formatMessage({id: route.id})}
                                                                </AELink>
                                                            </AERole>
                                                        )
                                                    })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                            </div>
                            <div className="flex-grow-1">
                                <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                                    {
                                        entity.description &&
                                        <div className={'bg-light p-4'}>
                                            <AETruncate
                                                text={entity.description}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>

                            {
                                entity.category &&
                                <AELabel variant={"warning"} size={"lg"}>
                                    {formatMessage({id: "CATEGORY"})}: {entity.category.label}
                                </AELabel>
                            }

                            <AELabel variant={"info"} size={"lg"}>
                                {formatMessage({id: "TYPE"})}: {entity.type}
                            </AELabel>

                            {
                                entity.postAttributes.map(postAttribute => {
                                    return (
                                        <AELabel
                                            key={postAttribute.id}
                                            size={"lg"}
                                            variant={"light-secondary"}
                                        >
                                            {postAttribute.attribute.parrent.label} : {postAttribute.attribute.label}

                                        </AELabel>
                                    )
                                })
                            }

                        </div>
                    </div>
                    {
                        bid_data &&
                        bid_data.length > 0  &&
                        <div className={`d-flex`}>

                        <div className="flex-grow-1">
                            <div className="separator separator-solid my-2"/>
                            <h3 className={"m-4"}> Propositions d'enchere : </h3>
                            <table className="table table-striped footable-sort" data-sorting="true">
                                <thead>
                                <tr>
                                    <th>#</th>

                                    <th data-breakpoints="xs sm md" datatype="html">
                                        Proposeur de l'Offre
                                    </th>
                                    <th data-breakpoints="xs sm md" datatype="html">Email</th>
                                    <th data-breakpoints="xs sm md" datatype="html">Téléphone</th>
                                    <th data-breakpoints="xs sm md" datatype="html">
                                        Date de l'envoi de l'offre
                                    </th>
                                    <th data-breakpoints="xs sm md" datatype="html">Prix Proposé</th>
                                    <th data-breakpoints="xs sm md" datatype="html">Notes</th>

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    bid_data &&
                                    bid_data.length > 0  &&
                                    bid_data.map((entity, index) => (
                                        <BidRow key={entity.id} entity={entity} index={index}/>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>}

                    <div className={`d-flex mt-4`}>

                        <div className="flex-grow-1">
                            <div className="separator separator-solid my-2"/>
                            <h3 className={"m-4"}> Gallerie des Images : </h3>
                            {
                                (isMd || isLg) &&
                                entity.postAttachements &&
                                entity.postAttachements.length > 0 &&
                                <div className={'mt-auto'}>
                                    <div className="separator separator-solid my-2"/>
                                    <div className="">
                                        {
                                            entity.postAttachements.map(postAttachment =>
                                                (
                                                    <div className={`symbol ${className} symbol-${size}`}>
                                                        {
                                                            postAttachment.fileName.split('.').pop() === 'mp4' ?

                                                                <video width="350" height="300"
                                                                       className="h-75 align-self-center my-4 p-2"
                                                                       style={{maxWidth: 17 + 'em'}}
                                                                       autoPlay muted controls>
                                                                    <source
                                                                        src={toEntityFileNameUrl(postAttachment, 'postAttachements')}
                                                                        type="video/mp4"/>
                                                                    Your browser does not support the video tag.
                                                                </video>

                                                                :

                                                                <img
                                                                    src={toEntityFileNameUrl(postAttachment, 'postAttachements')}
                                                                    alt={` `}
                                                                    className="h-75 align-self-center my-4 p-2 order-lg-last d-lg-flex "
                                                                    style={{maxWidth: 17 + 'em'}}/>

                                                        }
                                                    </div>
                                                    /*  <AEFileDownload
                                                        key={postAttachment.id}
                                                        id={postAttachment.id}
                                                        target={'post_attachements'}
                                                        fileName={postAttachment.fileName}
                                                    />*/
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}