import React, {useState} from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import TextField from "@material-ui/core/TextField";
import {DateTimePicker, DatePicker} from "@material-ui/pickers";
import moment from "moment";
import {useIntl} from "react-intl";
import {DateTimeFormat} from "../../../../_ae/helpers/momentHelper";

const getFieldCSSClasses = (touched, errors) => {
  const classes = [];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};


export const AEDateSerialize = (dateMoment, format = DateTimeFormat)=>dateMoment.format(format)
export const AEDateDeserialize = (dateTimeSting, format = DateTimeFormat)=>{
  const date = moment(dateTimeSting, format)
  // console.log(moment("2020-01-01 02-15-00","YYYY-MM-DD HH-mm-ss"))
  if(!date.isValid()) return null;
  return date
}

export function AEDatePicker({
  field: { name, value , ...field},
  form: { touched, errors, values, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "datetime",
  validation = true,
  shrinkLabel = false,
                          disabled = false,
                               inputVariant = "outlined",
  size="small",
  ...props
}) {
  const {formatMessage} = useIntl()
  label = formatMessage({id:label})
  let InputLabelProps =  {}
  if(shrinkLabel) InputLabelProps.shrink = true;
  let Picker = DateTimePicker;
  let _props = {
    format : "DD/MM/YYYY HH:mm"
  }
  switch (type){
    case "date": {
      Picker = DatePicker;
      _props.format = "LL";
    } break;
  }

  return (
    <>
      <Picker
        clearable
        okLabel={formatMessage({id:'OK'})}
        cancelLabel={formatMessage({id:'CANCEL'})}
        clearLabel={formatMessage({id:'CLEAR'})}
        className={`s ${disabled ? 'bg-light' : ''}`+(validation && getFieldCSSClasses(touched[name], errors[name]))}
        inputVariant={inputVariant}
        InputLabelProps={InputLabelProps}
        size={size}
        fullWidth
        disabled={disabled}
        label={label}
        onChange={date=>{
          setFieldTouched(name, true)
          setFieldValue(name, date ? AEDateSerialize(date) : null)
        }}
        value={AEDateDeserialize(value)}
        helperText={''}
        {...props}
        {..._props}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[name]}
          touched={touched[name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
