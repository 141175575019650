import React from "react";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import {AESVG, SVG_ICON} from "../../../../_ae/components/svg";
import {useIntl} from "react-intl";
import {AELink} from "../../../../_ae/components/AELink";
import {Label} from "./Label";
import {DropdownCustomToggler,} from "../../../../_metronic/_partials/dropdowns";
import {Dropdown} from "react-bootstrap";
import {AERole} from "../../../../_ae/components/AERole";
import {useRoutesForAppState} from "../../../../redux/store/routes";
import {Category} from "../../../pages/Category";
import clsx from "clsx";


export const EntityCard = ({
  entity,
  size= "md",
  className = '',
  avatar,
  editAction,
  deleteAction,
}) => {

  const { formatMessage } = useIntl()
  const {data: routes, routesLoading} = useRoutesForAppState();
  const classes = {
    xs:{
      card: 'shadow-none ',
      cardBody: 'p-1',
      symbol: 'symbol-40 mr-2',
      title: 'h6',
    },
    sm:{
      card: 'shadow-none ',
      cardBody: 'p-2',
      symbol: 'symbol-70 mr-2',
      title: 'h5',
    },
    md:{
      symbol: 'symbol-70 mr-5',
      cardBody: 'p-3 d-flex flex-column',
      title: 'h4',
    },
    lg:{
      symbol: 'symbol-80 mr-5',
      title: 'h3',
      cardBody: 'p-4',
    },
  }

  const sizeClasses = classes[size];
  const isXs = size === 'xs'

  const actions = [
    {id: 'ATTRIBUTES.EDIT', show: editAction},
    {id: 'ATTRIBUTES.DELETE', show: deleteAction},
  ].filter(action=>action.show);
  

  return (
    <>
      <Card className={`${className}`}>
        <CardBody className={`${sizeClasses.cardBody}`}>
          <div className={`d-flex`}>

            <div className="flex-grow-1">
              <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                 
                  <Label entity={entity} fontSize={sizeClasses.title} fontWeight={'bold'}/>
                  <div className={clsx('flex-grow-1', !isXs && 'pt-1')} >
                    {
                      [
                        {id: formatMessage({id:'CATEGORY'}), value: <Category category={entity.category} />},
                      ]
                        .filter(op=>!op.hidden)
                          .map(op=>(
                            <div key={op.id} className="d-flex pb-1">
                              <span className="">
                                {op.value}
                                {
                                  !isXs &&
                                 <span className={"text-muted ml-2"}> {formatMessage({id: op.id})} </span>
                                }
                              </span>
                            </div>
                          ))  }      
                  </div>
                </div>
                
                <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                </div>
                {
                  actions.length > 0 &&
                  <Dropdown>
                    <Dropdown.Toggle as={DropdownCustomToggler} >
                      <AESVG path={SVG_ICON.DOTS} variant={'primary'} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {
                        actions
                          .map(action=>{
                           const route = routes.find(r=>r.id === action.id);
                            if (! route) return '';
                            const path = route.path.replace(':id', entity.id)

                            return (
                              <AERole key={route.id} roles={route.roles}>
                                <AELink to={path} className="dropdown-item" >
                                  <AESVG className={'pr-2'} path={route.svg}/>
                                  {formatMessage({id: route.id})}
                                </AELink>
                              </AERole>
                            )
                          })
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                }
              </div>
              {/*
              <hr/>
              <fieldset className="border">
               <legend fontSize={sizeClasses.sm} style={{fontsize:"20px"}}>Options :</legend>
                {
                 entity.subOptions &&
                 entity.subOptions.length > 0 &&
                 entity.subOptions.map(subOption=>{
                  return (
                     <div>{
                      actions.length > 0 &&
                      <Dropdown>
                        - <Label entity={subOption} fontSize={sizeClasses.md} fontWeight={'bold'}/>
                        <Dropdown.Toggle as={DropdownCustomToggler} >
                          <AESVG path={SVG_ICON.DOTS} variant={'primary'} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {
                            actions
                              .map(action=>{
                               const route = routes.find(r=>r.id === action.id);
                                if (! route) return '';
                                const path = route.path.replace(':id', subOption.id)
    
                                return (
                                  <AERole key={route.id} roles={route.roles}>
                                    <AELink to={path} className="dropdown-item" >
                                      <AESVG className={'pr-2'} path={route.svg}/>
                                      {formatMessage({id: route.id})}
                                    </AELink>
                                  </AERole>
                                )
                              })
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                     }</div>
                  )
                })
              }
              </fieldset>
               */}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}