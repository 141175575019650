import React, {useState} from 'react';
import {AEAutocompleteField} from "../../../../../_metronic/_partials/controls/forms/AEField";


export const AttributeField = ({entity, index, attributes = [], setFieldValue}) => {

    const [subOptions, setSubOptions] = useState([]);
    const [selected, setSelected] = useState(undefined);

    let key = (index * 2)

    const fieldProps = {
        withFeedbackLabel: false,
        validation: false,
        className: 'text-white'
    }
    const changeSelectOptionHandler = (event, value) => {
        console.log(value)
        // attributes[index] = value
        setFieldValue(`attributes.${key}`, value)
        setSelected((entity.subOptions.find(item => value === item)));
    };

    return (
        <>

            <div key={key} className="d-flex form-group col-lg-6">
                <div className={'flex-grow-1'}>
                    <AEAutocompleteField
                        name={`attributes.${key}`}
                        label={entity.label}
                        options={entity.subOptions}
                        getOptionLabel={o => o.label}
                        {...fieldProps}
                        onChange={changeSelectOptionHandler}
                        multiple={entity.multiple === "multiple" ? true : false}
                    />
                </div>
            </div>
            {
                selected &&
                selected.subOptions &&
                selected.subOptions.length > 0 &&
                <div key={key} className="d-flex form-group col-lg-6">
                    <div className={'flex-grow-1'}>
                        <AEAutocompleteField
                            name={`attributes.${key+1}`}
                            label={selected.label}
                            options={selected.subOptions}
                            getOptionLabel={o => o.label}
                            {...fieldProps}
                            multiple={selected.multiple === "multiple" ? true : false}
                        />
                    </div>
                </div>
            }
        </>
    );
}