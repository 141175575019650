import React from 'react';
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../../_metronic/_helpers";
import {Link} from "react-router-dom";
import SVG from "react-inlinesvg";
import {Location} from "../../../../pages/Location";
import moment from 'moment'
import {getNonAccentString} from "../../../../../_ae/components/AEUtils";

export const StoreCard = ({entity}) => {

    let storeName = getNonAccentString(entity.store?.name);
    // alert(getNonAccentString(chaine));

    return (
        <div className="col-xs-12" style={{display: "grid"}}>
            <div className="property-card card  property-card-list row-fluid clearfix" style={{display: "inline"}}>
                <div className="property-card-header image-box col-sm-4 bg-light">
                    {
                        entity.fileName ?
                            <img src={toEntityFileNameUrl(entity, 'users')} alt={` `}
                                 className="w-100 mx-auto img-responsive"/>
                            :
                            <SVG
                                className="mw-100 mx-auto img-responsive"
                                src={toAbsoluteUrl("/media/svg/gallery.svg")}
                            />
                    }
                    {/*<Link to={'/annonces/:id/details'.replace(':id', entity.id)} className="property-card-hover">*/}
                    {/*    <img src={toAbsoluteUrl("/frontoffice/img/property-hover-arrow.png")} alt=""*/}
                    {/*         className="left-icon"/>*/}
                    {/*    <img src={toAbsoluteUrl("/frontoffice/img/plus.png")} alt="" className="center-icon"/>*/}
                    {/*    <img src={toAbsoluteUrl("/frontoffice/img/icon-notice.png")} alt="" className="right-icon"/>*/}
                    {/*</Link>*/}
                </div>
                <div className="col-sm-8 ">
                    <div className="property-card-tags">
                        <span className="label label-default"
                              style={{backgroundColor: "transparent", display: "inline"}}>
                            <i className="fas fa-clock"/> Inscrit {moment(entity.createdAt).fromNow()}
                        </span>
                    </div>
                    <div className="property-card-box card-box card-block">
                        <h3 className="property-card-title">
                            <Link to={'/boutiques/:id/:storeName'.replace(':id', entity.id).replace(':storeName', storeName)}
                                  className="agent-name text-color-primary">
                                {entity.store.name}
                            </Link>
                        </h3>
                        <div className="property-card-descr text-truncate">

                        </div>
                        <div className="property-preview-footer  clearfix">
                            <div className="property-preview-f-right">

                                {/*<Link to={'/annonces/:id/details'.replace(':id', entity.id)}>
                                    Plus de details
                                </Link>*/}
                                <span className="property-card-value">
                                    <i className="fa fa-map-marker"/>
                                    {/*<Location location={entity.location} fontSize='sm'/>*/}
                                    {entity.city?.name}
                                </span>

                            </div>
                            <div className="property-preview-f-left text-color-primary">

                                <span className="property-card-value">
                                    <span className={`border label label-lg label-inline label-success m-1 py-2 font-weight-bold`}>
                                         {entity.posts ? entity.posts.length : '0'} Article(s)
                                     </span>
                                </span>
                                {/*
                                <span className="property-card-value">
                                    <Location location={entity.location} fontSize='sm'/>
                                </span>*/}
                                {/*<span className="property-card-value">*/}
                                {/*   <AECurrency value={entity.price}/>*/}
                                {/*</span>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}