import React, {lazy, Suspense, useEffect} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {ContentRoute, Layout, LayoutSplashScreen} from "../_metronic/layout";
import {Logout} from "./modules/Auth";
import ErrorsPage from "./pages/ErrorsPages/ErrorsPage";
import {SVG_ICON} from "../_ae/components/svg";
import {useIntl} from "react-intl";
import {SITE_NAME} from "../_ae/helpers/UIHelper";
import {matchPath} from "react-router";
import {AuthLayout} from "./modules/Auth/pages/AuthLayout";
import {UsersDelete, UsersEdit, UsersList} from "./modules/Users";
import {MODULES} from "../redux/store/helpers";
import {LocationsDelete, LocationsEdit, LocationsList} from "./modules/Locations";
import {CitiesDelete, CitiesEdit, CitiesList} from "./modules/Cities";
import {CountriesDelete, CountriesEdit, CountriesList} from "./modules/Countries";
import {fetchRoutesForApp, useRoutesForAppState} from "../redux/store/routes";
import {RolesDelete, RolesEdit, RolesList} from "./modules/Roles";
import {SpecificationsDelete, SpecificationsEdit, SpecificationsList} from "./modules/Specifications";
import {CategoriesDelete, CategoriesEdit, CategoriesList, CategoryDetails} from "./modules/Categories";
import {AttributesDelete, AttributesEdit, AttributesList} from "./modules/Attributes";
import {PostsDelete, PostsEdit, PostsList, PostDetails, PostsEnable} from "./modules/Posts";
import {GroupsDelete, GroupsEdit, GroupsList, GroupsDetails} from "./modules/Groups";
import {SlidesDelete, SlidesEdit, SlidesList} from "./modules/Slides";
import {ResumesDelete, ResumesEdit, ResumesList, ResumeDetails} from "./modules/Resumes";
import {AnnouncersList, AnnouncersEdit, AnnouncersDelete} from "./modules/Announcers";
import {EditPassword, EditProfile} from "./modules/Profile";

// Auth
const Login = lazy(() => import('./modules/Auth/pages/Login'));
const Registration = lazy(() => import('./modules/Auth/pages/Registration'));
const ForgotPassword = lazy(() => import('./modules/Auth/pages/ForgotPassword'));
// Users
export const VIEWS = {
    MENU: 'MENU',
    ACTION: 'ACTION',
    DIALOG: 'DIALOG'
}

const ROUTES = [
    {
        id: 'AUTH.LOGIN',
        path: `/admin/auth/login`,
        svg: SVG_ICON.ADD_USER,
        component: Login,
        context: 'auth',
        views: []
    },
    {
        id: 'AUTH.FORGOT',
        path: `/admin/auth/forgot-password`,
        svg: SVG_ICON.ADD_USER,
        component: ForgotPassword,
        context: 'auth',
        views: []
    },
    {
        id: 'AUTH.REGISTER',
        path: `/admin/auth/register`,
        svg: SVG_ICON.ADD_USER,
        component: Registration,
        context: 'auth',
        views: []
    },
    // Profile
    {
        id: 'USER.PROFILE.EDIT',
        path: `/admin/${MODULES.PROFILE}/edit`,
        svg: SVG_ICON.EDIT,
        component: EditProfile,
        context: MODULES.PROFILE,
        views: []
    },
    {
        id: 'USER.PASSWORD.EDIT',
        path: `/admin/${MODULES.PROFILE}/password/edit`,
        svg: SVG_ICON.EDIT,
        component: EditPassword,
        context: MODULES.PROFILE,
        views: []
    },
    // users
    {
        id: 'USERS.NEW',
        path: `/admin/${MODULES.USERS}/new`,
        svg: SVG_ICON.ADD_USER,
        component: UsersEdit,
        context: MODULES.USERS,
        views: [VIEWS.ACTION]
    },
    {
        id: 'USERS.EDIT',
        path: `/admin/${MODULES.USERS}/:id/edit`,
        svg: SVG_ICON.EDIT,
        component: UsersEdit,
        context: MODULES.USERS,
        views: []
    },
    {
        id: 'USERS.DELETE',
        path: `/admin/${MODULES.USERS}/:id/delete`,
        svg: SVG_ICON.DELETE,
        component: UsersDelete,
        context: MODULES.USERS,
        views: [VIEWS.DIALOG]
    },
    {
        id: 'USERS.LIST',
        path: `/admin/${MODULES.USERS}/list`,
        svg: SVG_ICON.USERS,
        component: UsersList,
        context: MODULES.USERS,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    // locations
    {
        id: 'LOCATIONS.NEW',
        path: `/admin/${MODULES.LOCATIONS}/new`,
        svg: SVG_ICON.PLUS,
        component: LocationsEdit,
        context: MODULES.LOCATIONS,
        views: [VIEWS.ACTION]
    },
    {
        id: 'LOCATIONS.EDIT',
        path: `/admin/${MODULES.LOCATIONS}/:id/edit`,
        svg: SVG_ICON.EDIT,
        component: LocationsEdit,
        context: MODULES.LOCATIONS,
        views: []
    },
    {
        id: 'LOCATIONS.DELETE',
        path: `/admin/${MODULES.LOCATIONS}/:id/delete`,
        svg: SVG_ICON.DELETE,
        component: LocationsDelete,
        context: MODULES.LOCATIONS,
        views: [VIEWS.DIALOG]
    },
    {
        id: 'LOCATIONS.LIST',
        path: `/admin/${MODULES.LOCATIONS}/list`,
        svg: SVG_ICON.LOCATIONS,
        component: LocationsList,
        context: MODULES.LOCATIONS,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    // countries
    {
        id: 'COUNTRIES.NEW',
        path: `/admin/${MODULES.COUNTRIES}/new`,
        svg: SVG_ICON.PLUS,
        component: CountriesEdit,
        context: MODULES.COUNTRIES,
        views: [VIEWS.ACTION]
    },
    {
        id: 'COUNTRIES.EDIT',
        path: `/admin/${MODULES.COUNTRIES}/:id/edit`,
        svg: SVG_ICON.EDIT,
        component: CountriesEdit,
        context: MODULES.COUNTRIES,
        views: []
    },
    {
        id: 'COUNTRIES.DELETE',
        path: `/admin/${MODULES.COUNTRIES}/:id/delete`,
        svg: SVG_ICON.DELETE,
        component: CountriesDelete,
        context: MODULES.COUNTRIES,
        views: [VIEWS.DIALOG]
    },
    {
        id: 'COUNTRIES.LIST',
        path: `/admin/${MODULES.COUNTRIES}/list`,
        svg: SVG_ICON.COUNTRIES,
        component: CountriesList,
        context: MODULES.COUNTRIES,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    // cities
    {
        id: 'CITIES.NEW',
        path: `/admin/${MODULES.CITIES}/new`,
        svg: SVG_ICON.PLUS,
        component: CitiesEdit,
        context: MODULES.CITIES,
        views: [VIEWS.ACTION]
    },
    {
        id: 'CITIES.EDIT',
        path: `/admin/${MODULES.CITIES}/:id/edit`,
        svg: SVG_ICON.EDIT,
        component: CitiesEdit,
        context: MODULES.CITIES,
        views: []
    },
    {
        id: 'CITIES.DELETE',
        path: `/admin/${MODULES.CITIES}/:id/delete`,
        svg: SVG_ICON.DELETE,
        component: CitiesDelete,
        context: MODULES.CITIES,
        views: [VIEWS.DIALOG]
    },
    {
        id: 'CITIES.LIST',
        path: `/admin/${MODULES.CITIES}/list`,
        svg: SVG_ICON.CITIES,
        component: CitiesList,
        context: MODULES.CITIES,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    // Roles
    {
        id: 'ROLES.NEW',
        path: `/admin/${MODULES.ROLES}/new`,
        svg: SVG_ICON.PLUS,
        component: RolesEdit,
        context: MODULES.ROLES,
        views: [VIEWS.ACTION]
    },
    {
        id: 'ROLES.EDIT',
        path: `/admin/${MODULES.ROLES}/:id/edit`,
        svg: SVG_ICON.EDIT,
        component: RolesEdit,
        context: MODULES.ROLES,
        views: []
    },
    {
        id: 'ROLES.DELETE',
        path: `/admin/${MODULES.ROLES}/:id/delete`,
        svg: SVG_ICON.DELETE,
        component: RolesDelete,
        context: MODULES.ROLES,
        views: [VIEWS.DIALOG]
    },
    {
        id: 'ROLES.LIST',
        path: `/admin/${MODULES.ROLES}/list`,
        svg: SVG_ICON.ROLES,
        component: RolesList,
        context: MODULES.ROLES,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    // Specifications
    {
        id: 'SPECIFICATIONS.NEW',
        path: `/admin/${MODULES.SPECIFICATIONS}/new`,
        svg: SVG_ICON.PLUS,
        component: SpecificationsEdit,
        context: MODULES.SPECIFICATIONS,
        views: [VIEWS.ACTION]
    },
    {
        id: 'SPECIFICATIONS.EDIT',
        path: `/admin/${MODULES.SPECIFICATIONS}/:id/edit`,
        svg: SVG_ICON.EDIT,
        component: SpecificationsEdit,
        context: MODULES.SPECIFICATIONS,
        views: []
    },
    {
        id: 'SPECIFICATIONS.DELETE',
        path: `/admin/${MODULES.SPECIFICATIONS}/:id/delete`,
        svg: SVG_ICON.DELETE,
        component: SpecificationsDelete,
        context: MODULES.SPECIFICATIONS,
        views: [VIEWS.DIALOG]
    },
    {
        id: 'SPECIFICATIONS.LIST',
        path: `/admin/${MODULES.SPECIFICATIONS}/list`,
        svg: SVG_ICON.SPECIFICATIONS,
        component: SpecificationsList,
        context: MODULES.SPECIFICATIONS,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    // Categories
    {
        id: 'CATEGORIES.NEW',
        path: `/admin/${MODULES.CATEGORIES}/new`,
        svg: SVG_ICON.PLUS,
        component: CategoriesEdit,
        context: MODULES.CATEGORIES,
        views: [VIEWS.ACTION]
    },
    {
        id: 'CATEGORIES.EDIT',
        path: `/admin/${MODULES.CATEGORIES}/:id/edit`,
        svg: SVG_ICON.EDIT,
        component: CategoriesEdit,
        context: MODULES.CATEGORIES,
        views: []
    },
    {
        id: 'CATEGORIES.DELETE',
        path: `/admin/${MODULES.CATEGORIES}/:id/delete`,
        svg: SVG_ICON.DELETE,
        component: CategoriesDelete,
        context: MODULES.CATEGORIES,
        views: [VIEWS.DIALOG]
    },
    {
        id: 'CATEGORIES.LIST',
        path: `/admin/${MODULES.CATEGORIES}/list`,
        svg: SVG_ICON.CATEGORIES,
        component: CategoriesList,
        context: MODULES.CATEGORIES,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    {
        id: 'CATEGORIES.DETAILS',
        path: `/admin/${MODULES.CATEGORIES}/:id/details`,
        svg: SVG_ICON.LIST,
        component: CategoryDetails,
        context: MODULES.CATEGORIES,
        views: [VIEWS.DIALOG]
    },
    // Attributes
    {
        id: 'ATTRIBUTES.NEW',
        path: `/admin/${MODULES.ATTRIBUTES}/new`,
        svg: SVG_ICON.PLUS,
        component: AttributesEdit,
        context: MODULES.ATTRIBUTES,
        views: [VIEWS.ACTION]
    },
    {
        id: 'ATTRIBUTES.EDIT',
        path: `/admin/${MODULES.ATTRIBUTES}/:id/edit`,
        svg: SVG_ICON.EDIT,
        component: AttributesEdit,
        context: MODULES.ATTRIBUTES,
        views: []
    },
    {
        id: 'ATTRIBUTES.DELETE',
        path: `/admin/${MODULES.ATTRIBUTES}/:id/delete`,
        svg: SVG_ICON.DELETE,
        component: AttributesDelete,
        context: MODULES.ATTRIBUTES,
        views: [VIEWS.DIALOG]
    },
    {
        id: 'ATTRIBUTES.LIST',
        path: `/admin/${MODULES.ATTRIBUTES}/list`,
        svg: SVG_ICON.ATTRIBUTES,
        component: AttributesList,
        context: MODULES.ATTRIBUTES,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },// ANNONCES
    {
        id: 'POSTS.LIST',
        path: `/admin/${MODULES.POSTS}/list`,
        svg: SVG_ICON.POSTS,
        component: PostsList,
        context: MODULES.POSTS,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    {
        id: 'POSTS.NEW',
        path: `/admin/${MODULES.POSTS}/new`,
        svg: SVG_ICON.PLUS,
        component: PostsEdit,
        context: MODULES.POSTS,
        views: [VIEWS.ACTION]
    },
    {
        id: 'POSTS.EDIT',
        path: `/admin/${MODULES.POSTS}/:id/edit`,
        svg: SVG_ICON.EDIT,
        component: PostsEdit,
        context: MODULES.POSTS,
        views: []
    },
    {
        id: 'POSTS.ENABLE',
        path: `/admin/${MODULES.POSTS}/:id/enable`,
        svg: SVG_ICON.EDIT,
        component: PostsEnable,
        context: MODULES.POSTS,
        views: []
    },
    {
        id: 'POSTS.DELETE',
        path: `/admin/${MODULES.POSTS}/:id/delete`,
        svg: SVG_ICON.DELETE,
        component: PostsDelete,
        context: MODULES.POSTS,
        views: [VIEWS.DIALOG]
    },
    {
        id: 'POSTS.DETAILS',
        path: `/admin/${MODULES.POSTS}/:id/details`,
        svg: SVG_ICON.LIST,
        component: PostDetails,
        context: MODULES.POSTS,
        views: [VIEWS.DIALOG]
    },// GROUPS
    {
        id: 'GROUPS.LIST',
        path: `/admin/${MODULES.GROUPS}/list`,
        svg: SVG_ICON.GROUPS,
        component: GroupsList,
        context: MODULES.GROUPS,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    {
        id: 'GROUPS.NEW',
        path: `/admin/${MODULES.GROUPS}/new`,
        svg: SVG_ICON.GROUPS,
        component: GroupsEdit,
        context: MODULES.GROUPS,
        views: [VIEWS.ACTION]
    },
    {
        id: 'GROUPS.EDIT',
        path: `/admin/${MODULES.GROUPS}/:id/edit`,
        svg: SVG_ICON.EDIT,
        component: GroupsEdit,
        context: MODULES.GROUPS,
        views: []
    },
    {
        id: 'GROUPS.DELETE',
        path: `/admin/${MODULES.GROUPS}/:id/delete`,
        svg: SVG_ICON.DELETE,
        component: GroupsDelete,
        context: MODULES.GROUPS,
        views: [VIEWS.DIALOG]
    },
    {
        id: 'GROUPS.DETAILS',
        path: `/admin/${MODULES.GROUPS}/:id/details`,
        svg: SVG_ICON.LIST,
        component: GroupsDetails,
        context: MODULES.GROUPS,
        views: [VIEWS.DIALOG]
    },// SLIDES
    {
        id: 'SLIDES.LIST',
        path: `/admin/${MODULES.SLIDES}/list`,
        svg: SVG_ICON.SLIDES,
        component: SlidesList,
        context: MODULES.SLIDES,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    {
        id: 'SLIDES.NEW',
        path: `/admin/${MODULES.SLIDES}/new`,
        svg: SVG_ICON.PLUS,
        component: SlidesEdit,
        context: MODULES.SLIDES,
        views: [VIEWS.ACTION]
    },
    {
        id: 'SLIDES.EDIT',
        path: `/admin/${MODULES.SLIDES}/:id/edit`,
        svg: SVG_ICON.EDIT,
        component: SlidesEdit,
        context: MODULES.SLIDES,
        views: []
    },
    {
        id: 'SLIDES.DELETE',
        path: `/admin/${MODULES.SLIDES}/:id/delete`,
        svg: SVG_ICON.DELETE,
        component: SlidesDelete,
        context: MODULES.SLIDES,
        views: [VIEWS.DIALOG]
    },
    //RESUMES
    {
        id: 'RESUMES.NEW',
        path: `/admin/${MODULES.RESUMES}/new`,
        svg: SVG_ICON.ADD_USER,
        component: ResumesEdit,
        context: MODULES.RESUMES,
        views: [VIEWS.ACTION]
    },
    {
        id: 'RESUMES.EDIT',
        path: `/admin/${MODULES.RESUMES}/:id/edit`,
        svg: SVG_ICON.EDIT,
        component: ResumesEdit,
        context: MODULES.RESUMES,
        views: []
    },
    {
        id: 'RESUMES.DELETE',
        path: `/admin/${MODULES.RESUMES}/:id/delete`,
        svg: SVG_ICON.DELETE,
        component: ResumesDelete,
        context: MODULES.RESUMES,
        views: [VIEWS.DIALOG]
    },
    {
        id: 'RESUMES.LIST',
        path: `/admin/${MODULES.RESUMES}/list`,
        svg: SVG_ICON.RESUMES,
        component: ResumesList,
        context: MODULES.RESUMES,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    {
        id: 'RESUMES.DETAILS',
        path: `/admin/${MODULES.RESUMES}/:id/details`,
        svg: SVG_ICON.RESUMES,
        component: ResumeDetails,
        context: MODULES.RESUMES,
        views: [VIEWS.DIALOG]
    },
    //ANNOUNCERS
    {
        id: 'ANNOUNCERS.NEW',
        path: `/admin/${MODULES.ANNOUNCERS}/new`,
        svg: SVG_ICON.ADD_USER,
        component: AnnouncersEdit,
        context: MODULES.ANNOUNCERS,
        views: [VIEWS.ACTION]
    },
    {
        id: 'ANNOUNCERS.EDIT',
        path: `/admin/${MODULES.ANNOUNCERS}/:id/edit`,
        svg: SVG_ICON.EDIT,
        component: AnnouncersEdit,
        context: MODULES.ANNOUNCERS,
        views: []
    },
    {
        id: 'ANNOUNCERS.DELETE',
        path: `/admin/${MODULES.ANNOUNCERS}/:id/delete`,
        svg: SVG_ICON.DELETE,
        component: AnnouncersDelete,
        context: MODULES.ANNOUNCERS,
        views: [VIEWS.DIALOG]
    },
    {
        id: 'ANNOUNCERS.LIST',
        path: `/admin/${MODULES.ANNOUNCERS}/list`,
        svg: SVG_ICON.ANNOUNCERS,
        component: AnnouncersList,
        context: MODULES.ANNOUNCERS,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
]

export const getRoutes = (context) => {
    if (!context) return ROUTES

    return ROUTES.filter(route => route.context === context)
}


export function Routes() {
    const dispatch = useDispatch();
    const {isAuthorized, user} = useSelector(({auth}) => ({
        user: auth.user,
        isAuthorized: auth.user != null,
    }), shallowEqual);
    const {data: routes, isLoading} = useRoutesForAppState();
    const {formatMessage} = useIntl();
    const location = useLocation();
    useEffect(() => {
        const currentRoute = routes.find(r => matchPath(location.pathname, {path: r.path}))
        let title = SITE_NAME;
        if (currentRoute) {
            title += ` | ${formatMessage({id: currentRoute.id})}`
        }

        document.title = title;
    }, [routes, location.pathname])

    useEffect(() => {
        dispatch(fetchRoutesForApp())
    }, [])


    if (isLoading || routes.length === 0) return <LayoutSplashScreen/>

    return (
        <>
            <Switch>


                <Route path="/admin/error" component={ErrorsPage}/>
                <Route path="/admin/logout" component={Logout}/>

                {
                    !isAuthorized ?
                        <AuthLayout>
                            <Suspense fallback={<LayoutSplashScreen/>}>
                                <Switch>
                                    {/*FIX ME*/}
                                    <Redirect exact from="/admin" to="/admin/auth/login"/>
                                    <Redirect exact from="/admin/auth" to="/admin/auth/login"/>
                                    {
                                        getRoutes('auth').map(route => (
                                            <ContentRoute
                                                key={route.id}
                                                {...route} />
                                        ))
                                    }
                                    {
                                        Object.values(MODULES).map(moduleName => (
                                            <Redirect
                                                key={moduleName}
                                                from={`/admin/${moduleName}`}
                                                to={`/admin/auth`}
                                            />
                                        ))
                                    }
                                </Switch>
                            </Suspense>
                        </AuthLayout>
                        :
                        <Layout>
                            <Suspense fallback={<LayoutSplashScreen/>}>
                                <Switch>
                                    {/*todo redirect */}
                                    <Redirect exact from="/" to="/annonces"/>
                                    <Redirect exact from="/admin/auth/login" to="/"/>
                                    <Redirect exact from="/admin" to="/admin/posts"/>
                                    {
                                        Object.values(MODULES).map(moduleName => (
                                            <Redirect
                                                key={moduleName}
                                                exact
                                                from={`/admin/${moduleName}`}
                                                to={`/admin/${moduleName}/list`}
                                            />
                                        ))
                                    }

                                    {
                                        routes
                                            .map((route, i) => {
                                                return (
                                                    <ContentRoute
                                                        key={i}
                                                        {...route}
                                                    />
                                                )
                                            })
                                    }
                                    <Redirect to="/admin/error"/>
                                </Switch>
                            </Suspense>
                        </Layout>
                }
            </Switch>
        </>
    );
}
        