import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import {AEAutocompleteField, AEField, AEFileField} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {
  fetchCategoriesForSubCategoriesFilter,
  useSubCategoriesFilterState
} from "../../../../../redux/store/categories";


//todo server validation errors
export const Form = ({
                       values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                       handleSubmit,
                       isSubmitting,
                       btnRef,
}) => {
  const dispatch = useDispatch();
  const {data: categories} = useSubCategoriesFilterState()
  const [selected, setSelected] = React.useState("");

 
  let options = null;
  const changeSelectOptionHandler = (event,value) => {
    setSelected(value);
  };


  useEffect(()=>{
    dispatch(fetchCategoriesForSubCategoriesFilter())
  }, [dispatch])


    if (selected) {
        let cat = [];
        cat.push(selected);
        if (selected.subCategories.length > 0) {
            selected.subCategories.map(
                subcategory => cat.push(subcategory)
            )

        }
        options = cat;
    }

  return (
    <div className="form form-label-right">
      
      <div className="form-group row">
        <div className={"col-lg-12"}>
          <AEField
            name="label"
            label={'LABEL'}
          />
        </div>
      </div>
      
      <div className="form-group row py-1">

        <div className={`col-lg-6`}>
          {<AEAutocompleteField
            name="supercategory"
            label={'SUPER_CATEGORY'}
            options={categories.filter(category => category.parrent == null)}
            getOptionLabel={o=>o.label}
            onChange={changeSelectOptionHandler}
          />}
        </div>
       
        <div className={`col-lg-6`}>
        {
          options &&
          <AEAutocompleteField
            name="category"
            label={'CATEGORY'}
            options={options}
            getOptionLabel={o=>o.label}
          />
        }
        
        </div>


        {  /*  <select className="form-control" name="category">{
                categories.length > 0 &&
                categories.map(category=>{
                  return (
                   
                      category.subCategories.length > 0 ?
                        <optgroup label={category.label}>
                        {
                         category.subCategories.map(subcategory=>{
                         
                          return ( 
                            subcategory.subCategories.length > 0 ?
                            <optgroup label={subcategory.label}>
                            {
                             subcategory.subCategories.map(_subcategory=>{
                             
                              return ( <option value={_subcategory.id} >{_subcategory.label}</option>)
                             })
                             }
                            </optgroup> 
                            : 
                            <option value={subcategory.id} >{subcategory.label}</option>)
                         })
                         }
                        </optgroup>  
                        :
                        <option value={category.id} >{category.label}</option>
                  )
                  }
                  )
                })
                </select>
              */ }
      </div>


      <div className="form-group row">
          <div className={"col-lg-12"}>
            <AEFileField
              name="fileName"
              label={'ICON'}
            />
          </div>
      </div>

      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
    </div>
  );
}

