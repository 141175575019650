import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {useMetadata} from "../../../../_ae/AEPagination";
import {toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import {fetchCategories, useCategoriesListState} from "../../../../redux/store/categories";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";


function CategoriesList() {

    const dispatch = useDispatch();

    const {data, isLoading, metadata} = useCategoriesListState()

    const metadataState = useMetadata(metadata)
    const {page, perPage} = metadataState;

    const getEntities = () => {
        dispatch(fetchCategories(metadataState.toAEMetadata()));
    }

    /* Hooks */
    useEffect(() => {
        getEntities()
    }, [dispatch, page, perPage]);

    function randomColor(index) {
        const colors = [
            //Onix Logo Colors
            //"#6d2277",
            //"#614899",
            //"#977cb1",
            //"#15649d",
            //"#3782bb",
            //"#14abe0",
            //"#6fb62a",
            //"#547d5f",
            //"#f69729",
            //"#e76420",
            //"#da6629",
            //New colors
            '#F80507',
            '#F06804',
            '#1A8BF4',
            '#FADE01',
            '#27B803',
            '#AD03FC',
            '#F86EDD',

            // #6C2471 #614898 #967CB7  BLEU : #14649D #3087C8 #0FADE2  VERT: #537D61 #71B62B ORANGE: #EB6124 #F4982E
        ];

        return `${colors[(index + colors.length) % colors.length]}`;
    };

    return (

        <section className="search-form color-primary parallax">
            <div className="container">
                <div className="row row-cols-3 row-cols-sm-6 row-cols-xs-2">
                    {
                        data.map((category, index) => {
                            if (category.parrent == null) {
                                return (
                                    <div key={index} className="col my-2">
                                         <span
                                             className={`border label label-lg label-inline font-weight-bold`}
                                             style={{
                                                 width: 100 + "%",
                                                 height: 100 + "%",
                                                 background: randomColor(index)
                                             }}
                                         >
                                            <Link
                                                to={'/annonces/by/category/:id'.replace(':id', category.id)}
                                                className="main-navigation_link">
                                                <img
                                                    src={toEntityFileNameUrl(category, 'categories')}
                                                    className={"mr-2"} alt={` `}
                                                    style={{maxWidth: 25 + "px"}}/>
                                                    <span className={"d-block m-2"}>
                                                        {category.label}
                                                    </span>
                                            </Link>
                                        </span>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </section>
    );
}

export default CategoriesList;