import axios from "axios";
import {axiosFormDataConfigs, getFormData} from "../../../_ae/helpers/AxiosHelpers";
import {toAbsoluteApiUrl} from "../../../_metronic/_helpers";
import {MODULES} from "../helpers";

export const API_URI = toAbsoluteApiUrl(`/${MODULES.CATEGORIES}`);

const normalize = _entity => {
    console.log(_entity);
    const {id, label, expiration, classification, fileName} = _entity;
    const parrent = {id: _entity.parrent ? _entity.parrent.id : undefined}
   
    let subCategories = undefined;
    if (_entity.subCategories) {
      subCategories = _entity.subCategories.map(subCategory=>({id: subCategory.id}))
    }
  
    let attributes = undefined;
    if (_entity.attributes) {
      attributes = _entity.attributes.map(attribute=>({id: attribute.id}))
    }

    return {id, label, expiration, classification, parrent, subCategories, fileName, attributes}
  }


export const all = (metadata) => axios.get(API_URI, { params: { meta: metadata }});
export const find = (id, metadata) => axios.get(`${API_URI}/${id}`, { params: { meta: metadata }});
export const destroy = id => axios.delete(`${API_URI}/${id}`);
export const create = (entity, files) => axios.post(API_URI, getFormData(normalize(entity), files), axiosFormDataConfigs );
export const update = (entity, files) => axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(normalize(entity), files), axiosFormDataConfigs);
