import React from 'react';

export const TosItem = ({children = [], ...entity}) => {
    const hasChildren = children.length > 0
    return (
        <>

            <div className="panel panel-default">
                <span className="panel-heading">
                    <h4 className="panel-title">
                        {entity.title}
                    </h4>
                </span>
                <div id={`div${entity.number}`} className="panel-collapse">
                    <div className="panel-body">
                        {
                            hasChildren &&
                            children.map(item => {
                                return (
                                    <div>
                                        <span><b>{item.title}</b></span>
                                        <p className="mw-100">
                                            {item.description}
                                        </p>
                                        {
                                            item.children && item.children.length > 0 &&
                                            <ul>
                                                {
                                                    item.children.map((child, index) => {
                                                        return (
                                                            <li key={index}>{child.title}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

