/* eslint-disable no-restricted-imports */
import React, {useEffect} from "react";
import {Modal} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {useIntl} from "react-intl";
import {
    fetchPostForEnable,
    enablePost,
    usePostsEnableState
} from "../../../../../redux/store/posts";
import {AEButton} from "../../../../../_ae/components/buttons";
import {Loader} from "../../../../../_ae/components/loader";
import {EntityCard} from "../../components/EntityCard";
import * as Yup from "yup";
import {Formik} from "formik";
import {AEAutocompleteField} from "../../../../../_metronic/_partials/controls/forms/AEField";

export function Enable({history, match}) {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    // const { isLoading, data } = usePostsDeleteState()
    const {isLoading, data, validationErrors} = usePostsEnableState();
    const id = match.params.id;

    const onHide = () => {
        history.goBack()
    }

    useEffect(() => {
        dispatch(fetchPostForEnable(id));
    }, [id, dispatch]);

    return (
        <>
            <Loader isLoading={isLoading}/>
            <Modal.Dialog
                className={'max-w-100'}
            >
                <Modal.Body>
                    {!isLoading && data && (
                        <EntityCard
                            entity={data}
                            className="mb-0 card-border"
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Formik
                        enableReinitialize
                        initialErrors={validationErrors}
                        validationSchema={
                            Yup.object().shape({
                                status: Yup.string().required(),
                            })
                        }
                        initialValues={data}
                        onSubmit={(values) => {
                            dispatch(enablePost(values)).then(() => {
                                onHide();
                            });
                        }}
                        render={formik => (
                            <div className="form form-label-right w-50">

                                <div className="form-group row py-1">
                                    <div className={`col-lg-12`}>
                                        <AEAutocompleteField
                                            name="status"
                                            label={'STATUS'}
                                            options={['enabled', 'disabled']}
                                            getOptionLabel={o => o}
                                        />
                                    </div>
                                </div>

                                <AEButton
                                    variant={'primary'}
                                    onClick={formik.handleSubmit}
                                >
                                    {formatMessage({id: 'SUBMIT'})}

                                </AEButton>
                                <AEButton
                                    variant={'light'}
                                    onClick={onHide}
                                >
                                    {formatMessage({id: 'CANCEL'})}
                                </AEButton>
                            </div>
                        )}
                    />

                    {/*<AEButton*/}
                    {/*  variant={'warning'}*/}
                    {/*  onClick={deleteEntity}*/}
                    {/*>*/}
                    {/*    {formatMessage({id: 'CONFIRM_DELETION'})}*/}
                    {/*</AEButton>*/}
                </Modal.Footer>
            </Modal.Dialog>
            {/*</Modal>*/}
        </>
    );
}

