import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {AEAutocompleteField, AEField, AEFileField} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {AEButton} from "../../../../../_ae/components/buttons";
import {FieldArray, Formik} from "formik";
import {AESVG, SVG_ICON} from "../../../../../_ae/components/svg";
import {AEInput} from "../../../../../_metronic/_partials/controls/forms/AEInput";

import {
  useSubCategoriesFilterState,
  fetchCategoriesForSubCategoriesFilter,
  useSubCategoriesEditState
} from "../../../../../redux/store/categories";

//todo server validation errors
export const Form = ({
                       values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                       handleSubmit,
                       isSubmitting,
                       btnRef,
}) => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const {data: parrent} = useSubCategoriesFilterState();
  const {data: subCategories} = useSubCategoriesEditState();

  
  useEffect(()=>{
    dispatch(fetchCategoriesForSubCategoriesFilter())
  }, [dispatch])

  return (
    <div className="form form-label-right">

      <div className="form-group row">
        <div className={"col-lg-12"}>
          <AEField
            name="label"
            label={'LABEL'}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className={"col-lg-12"}>
          <AEField
            type= "number"
            name="expiration"
            label={'EXPIRATION(in days)'}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className={"col-lg-12"}>
          <AEField
            type= "number"
            name="classification"
            label={'ORDER'}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className={"col-lg-12"}>
          <AEFileField
            name="fileName"
            label={'ICON'}
          />
        </div>
      </div>

      <div className="form-group row py-1">
         <div className={`col-lg-6`}>
          <AEAutocompleteField
            name="parrent"
            label={'PARRENT'}
            options={parrent}
            getOptionLabel={o=>o.label}
          />
        </div>
      </div>

      <div className="form-group">
      { <FieldArray
          name="subCategories"
          render={(helpers) => (
            <div>
              <div className={'d-flex align-items-center form-group'}>
                <span className="font-size-h3 font-weight-bold">
                  {formatMessage({id: 'SUBCATEGORIES'})}
                </span>
                <AEButton
                  variant={'clean'}
                  icon
                  onClick={()=>{
                    helpers.push( {
                      label: ''
                    })
                  }}
                >
                  <AESVG
                    variant={'primary'}
                    path={SVG_ICON.PLUS}
                    size={'lg'}
                  />
                </AEButton>
              </div>
              {
                values.subCategories.map((subCategory, index)=>(
                  <div key={index} className="d-flex form-group">
                    <div className={'flex-grow-1'}>
                      <AEAutocompleteField
                        name={`subCategories.${index}`}
                        label={'SUBCATEGORY'}
                        options={subCategories}
                        getOptionLabel={o=> o.label}
                      />
                    </div>
                    <div className={''}>
                      <AEButton
                        variant={'clean'}
                        icon
                        onClick={()=>{
                          helpers.remove(index)
                        }}
                      >
                        <AESVG
                          variant={'danger'}
                          path={SVG_ICON.DELETE}
                          size={'lg'}
                        />
                      </AEButton>
                    </div>
                  </div>
                ))
              }
            </div>
          )}
            />
            }
      </div>

      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
      
    </div>
  );
}

