import React from 'react';
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import {Link} from "react-router-dom";
import SVG from "react-inlinesvg";
import {AECurrency} from "../../../../_ae/components/Currency";


export const FavoriteCard = ({entity}) => {
    return (
        <div className="col-xs-12" style={{display: "grid"}}>
            <div className="property-card card  property-card-list row-fluid clearfix" style={{display: "inline"}}>
                <div className="property-card-header image-box col-sm-4">
                    {
                        entity.fileName ?
                            <img src={toEntityFileNameUrl(entity, 'posts')} alt={` `}
                                 className=""/>
                            :
                            <SVG
                                className=""
                                src={toAbsoluteUrl("/media/svg/gallery.svg")}
                            />
                    }
                    <Link to={'/annonces/:id/details'.replace(':id', entity.id)} className="property-card-hover">
                        <img src={toAbsoluteUrl("/frontoffice/img/property-hover-arrow.png")} alt=""
                             className="left-icon"/>
                        <img src={toAbsoluteUrl("/frontoffice/img/plus.png")} alt="" className="center-icon"/>
                        <img src={toAbsoluteUrl("/frontoffice/img/icon-notice.png")} alt="" className="right-icon"/>
                    </Link>
                </div>
                <Link to={'/annonces/:id/details'.replace(':id', entity.id)}>

                    <div className="col-sm-8 ">
                        <div className="property-card-box card-box card-block">
                            <h3 className="property-card-title">
                                <Link to={'/annonces/:id/details'.replace(':id', entity.id)}>
                                    {entity.title}
                                </Link>
                            </h3>
                            <div className="property-card-descr text-truncate">
                                {entity.description}
                            </div>
                            <div className="property-preview-footer  clearfix">
                                {
                                    entity.city &&
                                    <div className="property-preview-f-right">
                                    <span className="property-card-value">
                                        <i className="fa fa-map-marker"/>
                                        {entity.city.name}
                                    </span>
                                    </div>
                                }
                                <div className="property-preview-f-left text-color-primary">
                                    {/*
                                <span className="property-card-value">
                                    <Location location={entity.location} fontSize='sm'/>
                                </span>*/}
                                    <span className="property-card-value">
                                   <AECurrency value={entity.price}/>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
}