import React from "react";
import {useDispatch} from "react-redux";
import {
    AEDatepickerField,
    AEField,
    AEFileField, AERaterField
} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {MODULES} from "../../../../../redux/store/helpers";
import {FieldArray} from "formik";
import {AEButton} from "../../../../../_ae/components/buttons";
import {AESVG, SVG_ICON} from "../../../../../_ae/components/svg";
import {useIntl} from "react-intl";

export const Form = ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         setFieldValue,
                         btnRef,
                     }) => {

    const dispatch = useDispatch();
    const {formatMessage} = useIntl()

    return (
        <div className="form form-label-right">
            {/* Personal Informations */}
            <div className={`d-flex mb-3`}>

                <div className={'-d-flex align-items-start'}>
                    <AEFileField
                        name="fileName"
                        label={'RESUME_IMAGE'}
                        preview
                        previewPath={`/${MODULES.RESUMES}`}
                    />
                </div>

                <div className="flex-grow-1 ml-3">
                    <div className={`d-flex justify-content-between flex-wrap mt-2 mx-4`}>
                        <AEField
                            type="text"
                            name="fullName"
                            label={'FULLNAME'}
                        />
                    </div>

                    <div className="flex-grow-1 mt-2 mx-4">
                        <AEField
                            name="profile"
                            label={'PROFILE_DESCRIPTION'}
                            multiline
                            type={'textarea'}
                            rows={4}
                        />
                    </div>

                    <div className="flex-grow-1 mt-3 mx-4">
                        <AEField
                            type="text"
                            name="phone"
                            label={'PHONE'}
                        />
                    </div>

                    <div className="flex-grow-1 mt-3 mx-4">
                        <AEField
                            type="text"
                            name="email"
                            label={'EMAIL'}
                        />
                    </div>

                    <div className="flex-grow-1 mt-3 mx-4">
                        <AEField
                            type="text"
                            name="address"
                            label={'ADDRESS'}
                        />
                    </div>
                </div>
            </div>


            {/* formations */}
            <FieldArray
                name="formations"
                render={helpers => {
                    const formations = values.formations;
                    return (
                        <div>
                            <div className={'d-flex align-items-center form-group'}>
                                <span className="font-size-h3 font-weight-bold">
                                    {formatMessage({id: 'FORMATIONS'})}
                                </span>
                                <AEButton
                                    variant={'clean'}
                                    icon
                                    onClick={() => {
                                        helpers.push({
                                            title: 'formations X',
                                            description: 'description de la formations x',
                                            startDate: undefined,
                                            endDate: undefined,
                                        })
                                    }}
                                >
                                    <AESVG
                                        variant={'primary'}
                                        path={SVG_ICON.PLUS}
                                        size={'lg'}
                                    />
                                </AEButton>
                            </div>
                            <div className="row">
                                {
                                    formations && formations.length > 0 ?
                                        formations.map((formation, index) => (
                                            <div key={index} className={"col-lg-6"}>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-10 row border ml-2">
                                                            <div className={"col-lg-4 ml-1 my-1"}>
                                                                <AEDatepickerField
                                                                    name={`formations.${index}.startDate`}
                                                                    label={'START_DATE'}
                                                                />
                                                            </div>

                                                            <div className={"col-lg-4 ml-1 my-1"}>
                                                                <AEDatepickerField
                                                                    name={`formations.${index}.endDate`}
                                                                    label={'END_DATE'}
                                                                />
                                                            </div>

                                                            <div className={"col-lg-12 m-1"}>
                                                                <AEField
                                                                    type="text"
                                                                    name={`formations.${index}.title`}
                                                                    label={'TITLE'}
                                                                />
                                                            </div>

                                                            <div className={"col-lg-12 m-1"}>
                                                                <AEField
                                                                    name={`formations.${index}.description`}
                                                                    label={'DESCRIPTION'}
                                                                    multiline
                                                                    type={'textarea'}
                                                                    rows={4}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={'col-lg-2'}>
                                                            <AEButton
                                                                variant={'clean'}
                                                                icon
                                                                onClick={() => {
                                                                    helpers.remove(index)
                                                                }}
                                                            >
                                                                <AESVG
                                                                    variant={'danger'}
                                                                    path={SVG_ICON.DELETE}
                                                                    size={'lg'}
                                                                />
                                                            </AEButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        : null}
                            </div>
                        </div>
                    );
                }}
            />

            {/* experiences */}
            <FieldArray
                name="experiences"
                render={helpers => {
                    const experiences = values.experiences;
                    return (
                        <div>
                            <div className={'d-flex align-items-center form-group'}>
                                <span className="font-size-h3 font-weight-bold">
                                    {formatMessage({id: 'EXPERIENCES'})}
                                </span>
                                <AEButton
                                    variant={'clean'}
                                    icon
                                    onClick={() => {
                                        helpers.push({
                                            title: '',
                                            description: '',
                                            startDate: undefined,
                                            endDate: undefined,
                                        })
                                    }}
                                >
                                    <AESVG
                                        variant={'primary'}
                                        path={SVG_ICON.PLUS}
                                        size={'lg'}
                                    />
                                </AEButton>
                            </div>
                            <div className="row">
                                {
                                    experiences && experiences.length > 0 ?
                                        experiences.map((experience, index) => (
                                            <div key={index} className={"col-lg-6"}>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-10 row border ml-2">
                                                            <div className={"col-lg-4 ml-1 my-1"}>
                                                                <AEDatepickerField
                                                                    name={`experiences.${index}.startDate`}
                                                                    label={'START_DATE'}
                                                                />
                                                            </div>

                                                            <div className={"col-lg-4 ml-1 my-1"}>
                                                                <AEDatepickerField
                                                                    name={`experiences.${index}.endDate`}
                                                                    label={'END_DATE'}
                                                                />
                                                            </div>

                                                            <div className={"col-lg-12 m-1"}>
                                                                <AEField
                                                                    type="text"
                                                                    name={`experiences.${index}.title`}
                                                                    label={'TITLE'}
                                                                />
                                                            </div>

                                                            <div className={"col-lg-12 m-1"}>
                                                                <AEField
                                                                    name={`experiences.${index}.description`}
                                                                    label={'DESCRIPTION'}
                                                                    multiline
                                                                    type={'textarea'}
                                                                    rows={4}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={'col-lg-2'}>
                                                            <AEButton
                                                                variant={'clean'}
                                                                icon
                                                                onClick={() => {
                                                                    helpers.remove(index)
                                                                }}
                                                            >
                                                                <AESVG
                                                                    variant={'danger'}
                                                                    path={SVG_ICON.DELETE}
                                                                    size={'lg'}
                                                                />
                                                            </AEButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        : null}
                            </div>
                        </div>
                    );
                }}
            />

            {/* skills */}
            <FieldArray
                name="skills"
                render={helpers => {
                    const skills = values.skills;
                    return (
                        <div>
                            <div className={'d-flex align-items-center form-group'}>
                                <span className="font-size-h3 font-weight-bold">
                                    {formatMessage({id: 'SKILLS'})}
                                </span>
                                <AEButton
                                    variant={'clean'}
                                    icon
                                    onClick={() => {
                                        helpers.push({
                                            skill: '',
                                            level: 0
                                        })
                                    }}
                                >
                                    <AESVG
                                        variant={'primary'}
                                        path={SVG_ICON.PLUS}
                                        size={'lg'}
                                    />
                                </AEButton>
                            </div>
                            <div className="row">
                                {
                                    skills && skills.length > 0 ?
                                        skills.map((skill, index) => (
                                            <div key={index} className={"col-lg-6"}>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-10 row border ml-2">
                                                            <div className={"col-lg-4 ml-1 my-1"}>
                                                                <AEField
                                                                    name={`skills.${index}.skill`}
                                                                    label={'SKILL'}
                                                                />
                                                            </div>

                                                            <div className={"col-lg-4 ml-1 my-1"}>
                                                                <AERaterField
                                                                    name={`skills.${index}.level`}
                                                                    label={"LEVEL"}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={'col-lg-2'}>
                                                            <AEButton
                                                                variant={'clean'}
                                                                icon
                                                                onClick={() => {
                                                                    helpers.remove(index)
                                                                }}
                                                            >
                                                                <AESVG
                                                                    variant={'danger'}
                                                                    path={SVG_ICON.DELETE}
                                                                    size={'lg'}
                                                                />
                                                            </AEButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        : null}
                            </div>
                        </div>
                    );
                }}
            />

            {/* languages */}
            <FieldArray
                name="languages"
                render={helpers => {
                    const languages = values.languages;
                    return (
                        <div>
                            <div className={'d-flex align-items-center form-group'}>
                                <span className="font-size-h3 font-weight-bold">
                                    {formatMessage({id: 'LANGUAGES'})}
                                </span>
                                <AEButton
                                    variant={'clean'}
                                    icon
                                    onClick={() => {
                                        helpers.push({
                                            language: '',
                                            level: 0
                                        })
                                    }}
                                >
                                    <AESVG
                                        variant={'primary'}
                                        path={SVG_ICON.PLUS}
                                        size={'lg'}
                                    />
                                </AEButton>
                            </div>
                            <div className="row">
                                {
                                    languages && languages.length > 0 ?
                                        languages.map((language, index) => (
                                            <div key={index} className={"col-lg-6"}>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-10 row border ml-2">
                                                            <div className={"col-lg-4 ml-1 my-1"}>
                                                                <AEField
                                                                    name={`languages.${index}.language`}
                                                                    label={'LANGUAGE'}
                                                                />
                                                            </div>

                                                            <div className={"col-lg-4 ml-1 my-1"}>
                                                                <AERaterField
                                                                    name={`languages.${index}.level`}
                                                                    label={"LEVEL"}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={'col-lg-2'}>
                                                            <AEButton
                                                                variant={'clean'}
                                                                icon
                                                                onClick={() => {
                                                                    helpers.remove(index)
                                                                }}
                                                            >
                                                                <AESVG
                                                                    variant={'danger'}
                                                                    path={SVG_ICON.DELETE}
                                                                    size={'lg'}
                                                                />
                                                            </AEButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        : null}
                            </div>
                        </div>
                    );
                }}
            />


            {/* hobbies */}
            <FieldArray
                name="hobbies"
                render={helpers => {
                    const hobbies = values.hobbies;
                    return (
                        <div>
                            <div className={'d-flex align-items-center form-group'}>
                                <span className="font-size-h3 font-weight-bold">
                                    {formatMessage({id: 'HOBBIES'})}
                                </span>
                                <AEButton
                                    variant={'clean'}
                                    icon
                                    onClick={() => {
                                        helpers.push({
                                            label: '',
                                        })
                                    }}
                                >
                                    <AESVG
                                        variant={'primary'}
                                        path={SVG_ICON.PLUS}
                                        size={'lg'}
                                    />
                                </AEButton>
                            </div>
                            <div className="row">
                                {
                                    hobbies && hobbies.length > 0 ?
                                        hobbies.map((hobby, index) => (
                                            <div key={index} className={"col-lg-3"}>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-8 border ml-2">
                                                            <div className={"ml-1 my-1"}>
                                                                <AEField
                                                                    name={`hobbies.${index}.label`}
                                                                    label={'LABEL'}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={'col-lg-2'}>
                                                            <AEButton
                                                                variant={'clean'}
                                                                icon
                                                                onClick={() => {
                                                                    helpers.remove(index)
                                                                }}
                                                            >
                                                                <AESVG
                                                                    variant={'danger'}
                                                                    path={SVG_ICON.DELETE}
                                                                    size={'lg'}
                                                                />
                                                            </AEButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        : null}
                            </div>
                        </div>
                    );
                }}
            />


            <button
                style={{display: "none"}}
                ref={btnRef}
                onClick={handleSubmit}
            />

        </div>
    )
        ;
}

