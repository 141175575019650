export {
  actions as citiesListActions,
  reducer as citiesListReducer,
  name as citiesListName,
  fetchDispatcher as fetchCities,
  resetDispatcher as resetCities,
  useSelector as useCitiesListState
} from './slices/list'

export {
  actions as citiesEditActions,
  reducer as citiesEditReducer,
  name as citiesEditName,
  useSelector as useCitiesEditState,
  fetchDispatcher as fetchCityForEdit,
  resetDispatcher as resetCityForEdit,
  saveDispatcher as saveCity
} from './slices/edit'

export {
  actions as citiesDeleteActions,
  reducer as citiesDeleteReducer,
  name as citiesDeleteName,
  useSelector as useCitiesDeleteState,
  fetchDispatcher as fetchCityForDelete,
  resetDispatcher as resetCityForDelete,
  deleteDispatcher as deleteCity
} from './slices/delete'

export {
  actions as citiesLocationsFilterActions,
  reducer as citiesLocationsFilterReducer,
  name as citiesLocationsFilterName,
  useSelector as useCitiesLocationsFilterState,
  fetchDispatcher as fetchCityForLocationsFilter,
  resetDispatcher as resetCityForLocationsFilter
} from './slices/locationsFilter'

export {
  actions as citiesLocationsEditActions,
  reducer as citiesLocationsEditReducer,
  name as citiesLocationsEditName,
  useSelector as useCitiesLocationsEditState,
  fetchDispatcher as fetchCitiesForLocationsEdit,
  resetDispatcher as resetCityForLocationsEdit
} from './slices/listForLocationsEdit'