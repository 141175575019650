// export function getNonAccentString(str){
//     var accent = [
//         /[\300-\306]/g, /[\340-\346]/g, // A, a
//         /[\310-\313]/g, /[\350-\353]/g, // E, e
//         /[\314-\317]/g, /[\354-\357]/g, // I, i
//         /[\322-\330]/g, /[\362-\370]/g, // O, o
//         /[\331-\334]/g, /[\371-\374]/g, // U, u
//         /[\321]/g, /[\361]/g, // N, n
//         /[\307]/g, /[\347]/g, // C, c
//         /['\s']/g, // space
//     ];
//     var noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c','_'];
//
//
//     for(var i = 0; i < accent.length; i++){
//         str = str.replace(accent[i], noaccent[i]);
//     }
//
//     return str.replace(' ','_').replace('\'','_');
// }

export function getNonAccentString(value) {
   let newVal = ("/&([a-z])[a-z]+;/i", "$1", htmlentities(value)).replace(/\s+/g, '-');
        return encodeURIComponent((newVal.replace(" ","-",)).toLowerCase());
}
function htmlentities(str) {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}