import axios from "axios";
import {axiosFormDataConfigs, getFormData} from "../../../../_ae/helpers/AxiosHelpers";
import {AEMetadata} from "../../../../_ae/AEPagination";

export const API_URI = process.env.REACT_APP_API_URL + "/auth";

const normalize = _entity => {
    const {id, firstName, lastName, email, password, gender, type} = _entity;
    const city = {id: _entity.city ? _entity.city.id : undefined}

    let magazine = undefined;
    if (_entity.type === 'MAGAZINE') {
        _entity.magazine.category = {id: _entity.magazine? _entity.magazine.category.id : undefined}
        magazine = _entity.magazine;
        return {id, firstName, lastName, email, password, gender, city, magazine, type}
    }

    let store = undefined;
    if (_entity.type === 'STORE') {
        _entity.store.categories = _entity.store.categories.map(category => ({
            id: category.id
        }))

        store = _entity.store;
        return {id, firstName, lastName, email, password, gender, city, store, type}
    }

    return {id, firstName, lastName, email, password, gender, city, type}
}

export const login = (email, password) => axios.post(`${API_URI}/login`, {username: email, password});
export const facebookLogin = (accessToken) => axios.post(`${API_URI}/login/facebook`, {accessToken});
export const googleLogin = (accessToken) => axios.post(`${API_URI}/login/google`, {accessToken});
export const register = (entity, files) => axios.post(`${API_URI}/register`, getFormData(normalize(entity), files), axiosFormDataConfigs);
//export const register = entity => axios.post(`${API_URI}/register`, entity);
export const requestPassword = email => axios.post(`${API_URI}/password/forgot`, {email});
export const requestEmailVerificationLink = () => axios.post(`${API_URI}/request_email_verification`);
export const verifyEmail = (token, object) => axios.post(`${API_URI}/verify_email/${token}`, object);
export const getUserByToken = () => axios.get(`${API_URI}/me`, {params: {meta: new AEMetadata({}, {}, {}, ['*', 'location'])}});

