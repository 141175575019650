import React from 'react';
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import {Link} from "react-router-dom";
import SVG from "react-inlinesvg";
import moment from 'moment'

export const PackCard = ({entity}) => {
    return (
        <div className="col-xs-12" style={{display: "grid"}}>
            <div className="property-card card  property-card-list row-fluid clearfix" style={{display: "inline"}}>
                <div className="property-card-header image-box col-sm-4 bg-light">
                    {
                        entity.fileName ?
                            <img src={toEntityFileNameUrl(entity, 'groups')} alt={` `}
                                 className="w-100 mx-auto img-responsive"/>
                            :
                            <SVG
                                className="mw-100 mx-auto img-responsive"
                                src={toAbsoluteUrl("/media/svg/gallery.svg")}
                            />
                    }
                    <Link to={'/packs/:id'.replace(':id', entity.id)} className="property-card-hover">
                        <img src={toAbsoluteUrl("/frontoffice/img/icon-notice.png")} alt="" className="center-icon"/>
                    </Link>
                </div>
                <div className="col-sm-8 ">
                    <div className="property-card-tags">
                        <span className="label label-default"
                              style={{backgroundColor: "transparent", display: "inline"}}>
                            <i className="fas fa-clock"/> {moment(entity.createdAt).fromNow()}
                        </span>
                    </div>
                    <div className="property-card-box card-box card-block">
                        <h3 className="property-card-title">
                            <Link to={'/packs/:id'.replace(':id', entity.id)}
                                  className="agent-name text-color-primary">
                                {entity.title}
                            </Link>
                        </h3>
                        <div className="property-card-descr text-truncate">
                            {entity.description}
                        </div>
                        <div className="property-preview-footer  clearfix">
                            <div className="property-preview-f-right">
                                {
                                    entity.category &&
                                    <span className="property-card-value">
                                        <i className="fa fa-boxes"/>
                                        {entity.category.label}
                                        {/*<Location location={entity.location} fontSize='sm'/>*/}
                                    </span>
                                }
                            </div>
                            <div className="property-preview-f-left text-color-primary">
                                <span className="property-card-value">
                                    <span
                                        className={`border label label-lg label-inline label-success m-1 py-2 font-weight-bold`}>
                                         {entity.posts ? entity.posts.length : '0'} Produit(s) dans le pack.
                                     </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}