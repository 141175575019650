import React from "react";
import {AEField} from "../../../../_metronic/_partials/controls/forms/AEField";


//todo server validation errors
export const Form = ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         btnRef,
                     }) => {

    const fieldProps = {
        withFeedbackLabel: false,
        validation: false
    }

    return (
        <div className="form form-label-right">

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        name="name"
                        label={'NAME'}
                        {...fieldProps}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        name="phone"
                        label={'PHONE'}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        name="email"
                        label={'EMAIL'}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        name="price"
                        label={'PRICE-PROPOSITION'}
                        {...fieldProps}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        name="note"
                        label={'NOTE'}
                        multiline
                        type={'textarea'}
                        rows={4}
                        {...fieldProps}
                    />
                </div>
            </div>

            <button
                style={{display: "none"}}
                ref={btnRef}
                onClick={handleSubmit}
            />

        </div>
    );
}

