import React, {useEffect, useMemo, useState} from "react";
import {PaginationLinks} from "./PaginationLinks";
import {PaginationToolbar} from "./PaginationToolbar";
import {AEButton} from "../../../../_ae/components/buttons";
import {useIntl} from "react-intl";
import {Dropdown} from "react-bootstrap";
import {getArrayOfNumbers} from "../../../../_ae/helpers/UIHelper";
import {Card, CardBody} from "../Card";

export function Pagination(props) {

  // const { children, isLoading, paginationProps, metadata } = props;
  const {formatMessage} = useIntl();
  const {
    page, pages, perPage, total,
    first, last, next, prev, to,
    setPages, setPerPage, setTotal, setSortField, setSortAsc, setFilters,

    counts = [10,20,30,50,100], pager = true, isLocal = false
  } = props;

  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(pages)

  const range = 5;
  useEffect(()=>{
    if(pages >= range){
      setStart(Math.max(1, page-parseInt(range/2)))
      setEnd(Math.min(pages, page+parseInt(range/2)))
    }
    // setEnd(Math.min(start + range, pages - 1))
  }, [pages, page])

  const Button = ({icon = true, disabled, onClick, className,...props}) => (
    <AEButton
      icon={icon}
      disabled={disabled}
      onClick={onClick}
      variant={"light"}
      variantHover={"primary"}
      size={"sm"}
      className={`${className} mr-2`}
      {...props}
    />
  )

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          {
            pages > 1 &&
            <div className="d-flex flex-wrap mr-3">
              <Button
                disabled={page === 1}
                onClick={first}
              >
                <i className="ki ki-bold-double-arrow-back icon-xs"/>
              </Button>
              <Button
                disabled={page === 1}
                onClick={prev}
              >
                <i className="ki ki-bold-arrow-back icon-xs"/>
              </Button>
              {
                start > 1 &&
                <Button
                  onClick={() => {
                    setEnd(start);
                    setStart(Math.max(1, start-range));
                  }}
                >
                  ...
                </Button>
              }
              {
                getArrayOfNumbers(pages).slice(start-1, end).map(k=>(
                  <Button
                    active={k === page}
                    key={k}
                    onClick={() => { to(k) }}
                  >
                    {k}
                  </Button>
                ))
              }
              {
                pages > end &&
                <Button
                  onClick={() => {
                    setStart(end);
                    setEnd(Math.min(pages, end+range));
                  }}
                >
                  ...
                </Button>
              }

              <Button
                disabled={page === pages}
                onClick={next}
              >
                <i className="ki ki-bold-arrow-next icon-xs"/>
              </Button>
              <Button
                disabled={page === end}
                onClick={last}
              >
                <i className="ki ki-bold-double-arrow-next icon-xs"/>
              </Button>
            </div>
          }
          {
            pager &&
            <div className="d-flex align-items-center">
              <Dropdown className="dropdown-inline" drop="up" alignRight >
                <Dropdown.Toggle
                  variant="transparent"
                  className="btn btn-light btn-sm font-weight-bolder dropdown-toggle">
                  {/*{formatMessage({id:activeTab})}*/}
                  {perPage}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu py-0 min-w-10px">
                  {
                    counts.map(o=>(
                      <Dropdown.Item
                        key={o}
                        onClick={() => setPerPage(o)}
                        className={`text-capitalize rounded ${perPage === o ? 'text-primary':''}`}>
                        {o}
                      </Dropdown.Item>
                    ))
                  }
                </Dropdown.Menu>
              </Dropdown>
              <span className="text-muted pl-3">
                {formatMessage({id:"METADATA.PAGINATION.DISPLAY_OF"}, {display:perPage, total:total})} <br/>
              </span>
            </div>

          }

        </div>
      </CardBody>
    </Card>
  );
}
