import React from "react";
import {toEntityFileNameUrl} from "../../../../_metronic/_helpers";

export const ResumeImg =({entity, className = '', size = 35}) =>{
    return (
        <div className={`${className}`}>
          {
            entity.fileName ?
              <img src={toEntityFileNameUrl(entity, 'resumes')} alt={` `}
              className="mw-75 h-50 align-self-center"/>
              :
              ""
          }
          {/*<i className="symbol-badge bg-primary"/>*/}
        </div>
        )
    }   