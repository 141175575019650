import React from "react";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import {AESVG, SVG_ICON} from "../../../../_ae/components/svg";
import {useIntl} from "react-intl";
import {AELink} from "../../../../_ae/components/AELink";
import {Title} from "./Title";
import {DropdownCustomToggler,} from "../../../../_metronic/_partials/dropdowns";
import {Dropdown} from "react-bootstrap";
import {AERole} from "../../../../_ae/components/AERole";
import {useRoutesForAppState} from "../../../../redux/store/routes";
import {Miniature} from "./Miniature";
import {FullName} from "../../Users/components/FullName";

export const EntityCard = ({
                               entity,
                               size = "md",
                               className = '',
                               avatar,
                               editAction,
                               deleteAction,
                               detailsAction,
                               changeStatusAction,
                           }) => {

    const {formatMessage} = useIntl()
    const {data: routes, routesLoading} = useRoutesForAppState();
    const classes = {
        xs: {
            card: 'shadow-none ',
            cardBody: 'p-1',
            symbol: 'symbol-40 mr-2',
            title: 'h6',
        },
        sm: {
            card: 'shadow-none ',
            cardBody: 'p-2',
            symbol: 'symbol-70 mr-2',
            title: 'h5',
        },
        md: {
            symbol: 'symbol-70 mr-5',
            cardBody: 'p-3 d-flex flex-column',
            title: 'h4',
        },
        lg: {
            symbol: 'symbol-80 mr-5',
            title: 'h3',
            cardBody: 'p-4',
        },
    }

    const sizeClasses = classes[size];
    const isXs = size === 'xs'

    const actions = [
        {id: 'GROUPS.EDIT', show: editAction},
        {id: 'GROUPS.DELETE', show: deleteAction},
        {id: 'GROUPS.DETAILS', show: detailsAction},
    ].filter(action => action.show);


    return (
        <>
            <Card className={`${className}`}>
                <CardBody className={`${sizeClasses.cardBody}`}>
                    <div className={`d-flex`}>

                        <div className={`${isXs ? '' : '-d-flex align-items-start'}`}>
                            {
                                <Miniature entity={entity} className={sizeClasses.symbol} size={'120'}/>
                            }
                        </div>

                        <div className="flex-grow-1">
                            <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                                <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                                    <Title entity={entity} fontSize={sizeClasses.title} fontWeight={'bold'}/>
                                    - par {entity.author ?
                                    <FullName user={entity.author} fontSize={sizeClasses.sm} fontWeight={'10'}/>
                                    :
                                    'Unknown'}
                                </div>

                                {
                                    actions.length > 0 &&
                                    <Dropdown>
                                        <Dropdown.Toggle as={DropdownCustomToggler}>
                                            <AESVG path={SVG_ICON.DOTS} variant={'primary'}/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {
                                                actions
                                                    .map(action => {
                                                        const route = routes.find(r => r.id === action.id);
                                                        if (!route) return '';
                                                        const path = route.path.replace(':id', entity.id)
                                                        return (
                                                            <AERole key={route.id} roles={route.roles}>
                                                                <AELink to={path} className="dropdown-item">
                                                                    <AESVG className={'pr-2'} path={route.svg}/>
                                                                    {formatMessage({id: route.id})}
                                                                </AELink>
                                                            </AERole>
                                                        )
                                                    })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                            </div>

                            <div className="flex-grow-1">
                                {entity.category &&
                                <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                                    <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                                        -{formatMessage({id: "CATEGORIE"})}: {entity.category.label}
                                    </div>
                                </div>}
                                <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                                    <div className={`${isXs ? '' : '-d-flex align-items-center'}`}>
                                        -{formatMessage({id: "PRICE"})}: {entity.price} MAD
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}