import axios from "axios";
import {axiosFormDataConfigs, getFormData} from "../../../_ae/helpers/AxiosHelpers";
import {toAbsoluteApiUrl} from "../../../_metronic/_helpers";
import {MODULES} from "../helpers";

export const API_URI = toAbsoluteApiUrl(`/${MODULES.SPECIFICATIONS}`);

const normalize = _entity => {
  const {id, label, fileName} = _entity;
  const category = {id: _entity.category.id}
  
    return {id, label, fileName, category}
}

export const all = (metadata) => axios.get(API_URI, { params: { meta: metadata }});
export const find = (id, metadata) => axios.get(`${API_URI}/${id}`, { params: { meta: metadata }});
export const destroy = id => axios.delete(`${API_URI}/${id}`);
export const create = (entity, files) => axios.post(API_URI, getFormData(normalize(entity), files), axiosFormDataConfigs );
export const update = (entity, files) => axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(normalize(entity), files), axiosFormDataConfigs);
export const findByCategory = (id, metadata) => axios.get(`${API_URI}/by/category/${id}`, {params: {meta: metadata}});
