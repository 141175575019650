import {AEMetadata} from "../../_ae/AEPagination";
import {createSlice} from "@reduxjs/toolkit";
import {shallowEqual, useSelector} from "react-redux";


export const getSlice = ({name, metadata, data}) => {
    const isArray = data instanceof Array;
    const isObject = data instanceof Object;

    const initialState = {
        initialData: data,
        data,
        isLoading: false,
        error: null
    }

    switch (true) {
        case isArray:
            if (metadata) {
                initialState.metadata = metadata
            }
            break;
        // case isObject:
    }

    return createSlice({
        name,
        initialState,
        reducers: {
            startCall: (state, action) => {
                state.error = null;
                state.isLoading = true;
            },
            endCall: (state, action) => {
                state.isLoading = false;
            },
            catchError: (state, action) => {
                state.error = true;
            },
            reset: (state, action) => {
                state.data = data;
            },
            fetched: (state, action) => {
                const {_data, _metadata} = action.payload.data;

                state.error = null;
                state.data = _data;

                switch (true) {
                    case isArray:
                        if (metadata && _metadata) {
                            const {total, pages} = _metadata.pagination;

                            state.metadata.pagination.total = total;
                            state.metadata.pagination.pages = pages;
                        }
                        break;
                }
            },
        }
    });
}


export const useCustomSelector = (sliceName) => {
    return useSelector(s => s[sliceName], shallowEqual)
}
export const MODULES = {
    USERS: 'users',
    LOCATIONS: 'locations',
    CITIES: 'cities',
    COUNTRIES: 'countries',
    ROUTES: 'routes',
    ROLES: 'roles',
    SPECIFICATIONS: 'specifications',
    CATEGORIES: 'categories',
    ATTRIBUTES: 'attributes',
    POSTS: 'posts',
    GROUPS: 'groups',
    SLIDES: 'slides',
    FAVORITES: 'favorites',
    BIDPROCESSES: 'bidprocesses',
    REVIEWS: 'reviews',
    STORIES: 'stories',
    RESUMES: 'resumes',
    ANNOUNCERS: 'announcers',
    NOTIFICATIONS: 'notifications',
    AUTH: 'auth',
    PROFILE: 'profile',
}