import {AELabel} from "../../../../_ae/components/AELabel";
import React from "react";
import {useIntl} from "react-intl";
import {CONFIG} from "../../../../_ae/config";

/* UI */
const ROLE_VARIANT = {
  [CONFIG.roles.A]: 'primary',
  [CONFIG.roles.E]: 'secondary',
  [CONFIG.roles.P]: 'light-primary',
  [CONFIG.roles.U]: 'light-secondary',
}

export const getRoleVariant = role => ROLE_VARIANT[role]

export const Role = ({role}) => {
  const {formatMessage} = useIntl()
  return (
    <AELabel
      title={formatMessage({id: role})}
      variant={getRoleVariant(role)}
    />
  )
}