import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import {useIntl} from "react-intl";
import {AEAutocompleteField, AEField} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {
  fetchCountriesForCitiesFilter,
  useCountriesCitiesFilterState
} from "../../../../../redux/store/countries";


//todo server validation errors
export const Form = ({
                       values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                       handleSubmit,
                       isSubmitting,
                       btnRef,
}) => {
  const dispatch = useDispatch();
  const {data: countries} = useCountriesCitiesFilterState()

  useEffect(()=>{
    dispatch(fetchCountriesForCitiesFilter())
  }, [dispatch])


  return (
    <div className="form form-label-right">
      <div className="form-group row">
        <div className={"col-lg-12"}>
          <AEField
            name="name"
            label={'NAME'}
          />
        </div>
      </div>
      <div className="form-group row py-1">
        <div className={`col-lg-6`}>
          <AEAutocompleteField
            name="country"
            label={'COUNTRY'}
            options={countries}
            getOptionLabel={o=>o.name}
          />
        </div>
      </div>
      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
    </div>
  );
}

