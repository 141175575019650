import React from 'react';
// import {useIntl} from "react-intl";
// import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../_metronic/_helpers";
// import SVG from "react-inlinesvg";
// import {Location} from "../../../pages/Location";
// import {Link} from "react-router-dom";
import {FullName} from "../../Users/components/FullName";

export const BidRow = ({entity, index}) => {
    return (
        <tr>
            <td data-breakpoints="xs sm md" datatype="html" >{index + 1}</td>

            <td data-breakpoints="xs sm md" datatype="html">
                {entity.user ?
                    <FullName user={entity.user} fontSize={'md'} fontWeight={'10'}/>
                    :
                    entity.name
                }
            </td>

            <td data-breakpoints="xs sm md" datatype="html">
                {entity.user ?
                    entity.user.email
                    :
                    entity.email
                }
            </td>

            <td data-breakpoints="xs sm md" datatype="html">
                {entity.user ?
                    entity.user.phone
                    :
                    entity.phone
                }
            </td>

            <td data-breakpoints="xs sm md" datatype="html">{entity.createdAt}</td>
            <td data-breakpoints="xs sm md" datatype="html">{entity.price}</td>
            <td data-breakpoints="xs sm md" datatype="html">{entity.note}</td>

        </tr>
    );
}