import {getSlice, MODULES, useCustomSelector} from "../../helpers";
import * as API from "../api";
import * as Yup from "yup";

const {actions, name, reducer} = getSlice({
  name: `${MODULES.RESUMES}.edit`,
  data: {
    fullName: 'Nom et Prénom',
    profile: 'Description de votre profile',
    email: 'test@email.com',
    phone: '0000000000',
    address: 'N° x, Rue,Ville',
    fileName: undefined,
    formations: [
      {
        title: 'formations X',
        description: 'description de la formations x',
        startDate: undefined,
        endDate: undefined,
      }
    ],
    experiences: [
      {
        title: 'experience X',
        description: 'description de l exp x',
        startDate: undefined,
        endDate: undefined,
      }
    ],
    skills: [
      {
        skill: 'skill A',
        level: 2,
      }
    ],
    languages: [
      {
        language: 'Arabe',
        level: 5,
      }
    ],
    hobbies: [
      {
        label: 'Lecture',
      }
    ],
    user: undefined,
  },
})

const {startCall, endCall, fetched, catchError, reset} = actions;


const resetDispatcher = () => dispatch => {
  dispatch(reset());
}

const fetchDispatcher = (id, metadata) => dispatch => {
  dispatch(startCall());

  return API
    .find(id, metadata)
    .then(response => {
      dispatch(fetched(response));
      console.log(response);
    })
    .catch(response => {
      dispatch(catchError(response));
    }).then(response => {
      dispatch(endCall(response));
    })
    ;
};

const saveDispatcher = (entity, files) => dispatch => {
  dispatch(startCall());

  const ApiCall = entity.id ?
    API.update:
    API.create;

  return ApiCall(entity, files)
    .then(response => {
      dispatch(fetched(response));
    })
    .catch(response => {
      dispatch(catchError(response));
    }).then(response => {
      dispatch(endCall(response));
    })
    ;
};

const useSelector = () => useCustomSelector(name)


export {
  actions,
  name,
  reducer,
  fetchDispatcher,
  resetDispatcher,
  saveDispatcher,
  useSelector
}
