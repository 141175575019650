import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useIntl} from "react-intl";
import {deleteStory, fetchStoryForDelete, useStoriesDeleteState} from "../../../../redux/store/stories";

export function DeleteStory({id}) {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();

    useEffect(() => {
        dispatch(fetchStoryForDelete(id));
    }, [id, dispatch]);

    const deleteEntity = () => {
        dispatch(deleteStory(id)).then(() => {
            window.location.reload()
        });
    };

    return (
        <>
            <span className={"story-icon_delete"}>
                <i className="fa fa-trash text-white" title="Supprimer Story?" onClick={deleteEntity}/>
            </span>
        </>

    );
}

