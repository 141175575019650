import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {
    deleteBidProcess,
    fetchBidProcessForDelete
} from "../../../../redux/store/bidProcess";
import {useDispatch} from "react-redux";
import {AEButton} from "../../../../_ae/components/buttons";
import {useIntl} from "react-intl";

export const BidRow = ({entity, index}) => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();


    useEffect(() => {
        dispatch(fetchBidProcessForDelete(entity.id))
    })

    const deleteEntity = () => {
        dispatch(deleteBidProcess(entity.id)).then(() => {
            window.location.reload();
        });
    }

    return (
        <tr>
            <td data-breakpoints="xs sm md" datatype="html">{index + 1}</td>

            <td data-breakpoints="xs sm md" datatype="html">
                <Link to={'/annonces/:id/details'.replace(':id', entity.post.id)}>
                    {entity.post.title}
                </Link>
            </td>

            <td data-breakpoints="xs sm md" datatype="html">{entity.createdAt}</td>
            <td data-breakpoints="xs sm md" datatype="html">{entity.price}</td>
            <td data-breakpoints="xs sm md" datatype="html">{entity.note}</td>


            <td data-breakpoints="xs sm md" datatype="html">
                <AEButton
                    variant={'warning'}
                    onClick={deleteEntity}
                >
                    {formatMessage({id: 'DELETE'})}
                </AEButton>
                {/*<a href="#" onClick="return confirm('Vous etes sûre de bien vouloir supprimer cette offre?')"*/}
                {/*   className="btn btn-danger"><i className="icon-remove"/> Delete</a>*/}
            </td>
        </tr>
    );
}