import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {useMetadata} from "../../../../_ae/AEPagination";
import {AELabel} from "../../../../_ae/components/AELabel";
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import {AECurrency} from "../../../../_ae/components/Currency";
import {MainHeader} from "../MainHeader";
import {fetchPostGroupsForDetails, usePostGroupsDetailsState} from "../../../../redux/store/groups";
import {ProductCard} from "./ProductCard";


export function PackDetails() {
    const dispatch = useDispatch();
    const {id} = useParams();
    const {data, metadata} = usePostGroupsDetailsState()
    const metadataState = useMetadata(metadata)
    const {page, perPage, sortAsc, filters, sortField, select} = metadataState;

    const getEntities = () => {
        dispatch(fetchPostGroupsForDetails(id, metadataState.toAEMetadata()));
    }

    useEffect(() => {
        getEntities()
    }, [id, dispatch, page, perPage, filters.search, select, sortField, sortAsc]);


    return (
        <>
            <header className="header">
                <MainHeader {...metadataState}/>
                <div className="top-box-mask"/>
                <section className="top-title-widget color-primary">
                    <div className="container">
                        <ul className="breadcrumb">
                            <li className="item"><a href="/"> Acceuil </a></li>
                            <li className="item"> Packs</li>
                        </ul>
                        <h1 className="top-title-t">Pack : {data ? data.title : ''}</h1>
                    </div>
                </section>
            </header>


            <main className="main main-container section-color-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <section className="widget widget-blog-listing widget-overflow widget-ask">
                                <div className="box-overflow-container box-container">

                                    <div className="">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="agent-detail-picture">
                                                    {
                                                        data.fileName ?
                                                            <img src={toEntityFileNameUrl(data, 'groups')}
                                                                 alt={` `}
                                                                 className="mx-auto img-responsive"/>
                                                            :
                                                            <img
                                                                src={toAbsoluteUrl("/frontoffice/img/placeholders/180x265.png")}
                                                                alt=""
                                                                className="mx-auto img-responsive"/>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="box-container-title">
                                                    <h2 className="title"> {data ? data.title : ''}</h2>

                                                </div>

                                                <div className="agent">
                                                    <h3 className="title text-color-primary">
                                                        {
                                                            data.price &&
                                                            <AECurrency value={data.price}/>
                                                        }
                                                    </h3>
                                                    {
                                                        data && data.description &&
                                                        <div className="phone text-color-primary">
                                                            {data.description}
                                                        </div>
                                                    }
                                                </div>

                                                {
                                                    data && data.category &&
                                                    <AELabel
                                                        key={data.category.id}
                                                        size={"lg"}
                                                        variant={"light-secondary"}
                                                    >
                                                        Catégorie: {data.category.label}
                                                    </AELabel>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="widget widget-profilelisting">
                                <div className="widget-body">
                                    <div className="widget-header text-uppercase">
                                        <h2>Les annonces de ce pack:</h2>
                                    </div>
                                </div>
                                <div className="properties">
                                    <div className="row">
                                        {
                                            data.posts &&
                                            data.posts.length > 0 &&
                                            data.posts.map((post, index) => {
                                                return (
                                                    <ProductCard key={index} entity={post}/>
                                                );
                                            })
                                        }
                                    </div>
                                    {/*<nav className="text-center">*/}
                                    {/*    <Pagination {...metadataState} counts={[12, 24, 48]}/>*/}
                                    {/*</nav>*/}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}