import axios from "axios";
import {axiosFormDataConfigs, getFormData} from "../../../_ae/helpers/AxiosHelpers";
import {toAbsoluteApiUrl} from "../../../_metronic/_helpers";
import {MODULES} from "../helpers";

export const API_URI = toAbsoluteApiUrl(`/${MODULES.NOTIFICATIONS}`);

const normalize = _entity => {
  const {id, message} = _entity;
    
  return {id, message}
}

export const find = (id, metadata) => axios.get(`${API_URI}/${id}`, { params: { meta: metadata }});
export const all = (metadata) => axios.get(`${API_URI}`, { params: { meta: metadata }});
export const destroy = id => axios.delete(`${API_URI}/${id}`);
export const add = (entity) => axios.post(API_URI, getFormData(normalize(entity)), axiosFormDataConfigs );
