import React, {useEffect} from "react";
import {FormikProvider, useFormik} from "formik";
import {AEButton} from "../../../../../_ae/components/buttons";
import {Card, CardBody} from "../../../../../_metronic/_partials/controls";
import {AESVG} from "../../../../../_ae/components/svg";
import {ButtonGroup, Dropdown} from "react-bootstrap";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {AEField} from "../../../../../_metronic/_partials/controls/forms/AEField";

const sorts = [
    {id: 1, prop: "firstName", label: "NAME"},
    {id: 3, prop: "createdAt", label: "SORT.CREATED_AT"}
]

export const Filter = ({setFilters, setSort, sort, setSortAsc}) => {

    const {formatMessage} = useIntl()

    const formik = useFormik({
        initialValues: {
            search: ""
        },
        onSubmit: (values) => {
            const {search} = values;
            let filters = {search};

            setFilters(filters)
        }
    })

    useEffect(() => {
        formik.handleSubmit()
    }, [formik.values])


    const fieldProps = {
        withFeedbackLabel: false,
        validation: false
    }

    return (
        <Card>
            <CardBody>
                <FormikProvider value={formik}>
                    <div className="d-flex">
                        <div className={`flex-fill`}>
                            <AEField
                                name="search"
                                label={'SEARCH'}
                                {...fieldProps}
                            />
                        </div>

                        <Dropdown as={ButtonGroup} className={"pl-2"}>
                            <AEButton variant={"light"} fontWeight={"bolder"} onClick={() => {
                                setSortAsc(!sort.asc)
                            }}>
                                <AESVG path={`/Navigation/${sort.asc ? 'UP' : 'DOWN'}-2.svg`} variant={"primary"}/>
                                {
                                    formatMessage({id: sorts.findIndex(o => o.prop === sort.field) !== -1 ? sorts.find(o => o.prop === sort.field).label : "SORT"})
                                }
                            </AEButton>
                            <Dropdown.Toggle split variant="light"/>
                            <Dropdown.Menu className={"py-5"}>
                                {
                                    sorts.map(o => (
                                        <Dropdown.Item key={o.prop} onClick={() => {
                                            setSort(o.prop, o.asc)
                                        }}>
                                            {formatMessage({id: o.label}, {asc: o.asc})}
                                        </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </FormikProvider>
            </CardBody>
        </Card>
    )
}