import React from "react";
import {toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export const Icon =({entity, className = '', size = 35}) =>{
  console.log(entity.fileName.split('.').pop() === 'svg');
    return (
       
        <div className={`symbol ${className} symbol-${size}`}>
          {
             entity.fileName.split('.').pop() === 'svg' ?
              
             <span className="symbol-label">
              <SVG
                className="mw-100 mx-auto img-responsive align-self-center"
                src={toEntityFileNameUrl(entity, 'slides')}
              />
              </span>

              :
              <img src={toEntityFileNameUrl(entity, 'slides')} alt={` `}
              className="mw-100 mx-auto img-responsive align-self-center"/>
             
          }
          {/*<i className="symbol-badge bg-primary"/>*/}
        </div>
        )
    }   