import React from 'react';
import {Link} from "react-router-dom";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

function Footer() {

    return (
        <footer className="footer">
            <div className="container footer-mask">
                <div className="container footer-contant">
                    <div className="row">
                        <div className="col-md-4 hidden-sm hidden-xs">
                            <div className="logo">
                                <Link to="/annonces" className="nav-link">
                                    <img src={toAbsoluteUrl("/frontoffice/img/logos/logo.png")} alt=""/>
                                </Link>
                            </div>
                            <div className="social">
                                <ul>
                                    <li><a href="https://www.facebook.com/Onixma-100435112399216/"><i
                                        className="fab fa-facebook-square"/></a></li>
                                    <li><a href="https://www.instagram.com/onix.ma/"><i
                                        className="fab fa-instagram-square"/></a></li>
                                    <li><a href="https://www.linkedin.com/in/onix-annonces-b90516220/"><i
                                        className="fab fa-linkedin-square"/></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className={"mb-4"}>
                                <div className="title mb-3">
                                    <h4>Contact</h4>
                                </div>
                                <ul className="list list-contact  list-news">
                                    <li>Vous avez des questions ?</li>
                                    <li>
                                        <i className="fa fa-envelope"/>
                                        <a href="mailto:info@onix.ma"> info@onix.ma</a>
                                    </li>
                                </ul>
                            </div>
                            <br/>
                            <div>
                                <div className="title mb-3">
                                    <h4>Conditions Générales d'Utilisation</h4>
                                </div>
                                <ul className="list list-contact  list-news">
                                    <li>
                                        <Link to="/termsofservice">
                                            <i className="fas fa-info-circle"/> Voir les CGU
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="title mb-0">
                                <h4 className="pb-2">
                                    Vendez et Achetez Partout!
                                </h4>
                                <h5>Téléchargez notre application mobile!</h5>
                            </div>
                            <Link to="#" className="nav-link">
                                <img src={toAbsoluteUrl("/media/logos/get_mobile_app.png")} alt="mobile app"
                                    // style={{width: 10.8 + "em"}}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container text-right">
                        <span className=""><a href="#">Focus Agency &#169; 2021</a></span>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;