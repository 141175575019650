import {getSlice, MODULES, useCustomSelector} from "../../helpers";
import * as API from "../api";
import {getRoutes} from "../../../../app/Routes";

const {actions, name, reducer} = getSlice({
  name: `${MODULES.ROUTES}.listForApp`,
  data: [],
  // metadata: new AEMetadata(
  //   {
  //     perPage: 1000
  //   },
  //   {field: 'id'},
  //   {},
  //   {"*": [], 'assignTo': {'*': [], 'location': {'*': []}}}
  // )
})

const {startCall, endCall, fetched, catchError, reset} = actions;

const resetDispatcher = () => dispatch => {
  dispatch(reset());
}

const fetchDispatcher = metadata => dispatch => {
  dispatch(startCall());

  return API
    .all(metadata)
    .then(response => {
      response.data._data = response.data._data.map(dbRoute=>{

        const definedRoute = getRoutes();
        const toMerge = definedRoute.find(appRoute=>appRoute.id === dbRoute.routeId);

        // dbRoute._appRoute = {
        //   ...toMerge,
        //   roles: dbRoute.routeRoles.map(routeRole=>routeRole.role.roleId)
        // }


        return {
          ...toMerge,
          roles: dbRoute.routeRoles.map(routeRole=>routeRole.role.roleId),
          dbRoute
        }
      })
      dispatch(fetched(response));

      return response;
    })
    .catch(response => {
      dispatch(catchError(response));

      return response;
    }).then(response=>{
      dispatch(endCall(response));
    })
    ;
};

const useSelector = () => useCustomSelector(name)

export {
  actions,
  name,
  reducer,
  fetchDispatcher,
  resetDispatcher,
  useSelector
}


