import {getSlice, MODULES, useCustomSelector} from "../../helpers";
import * as API from "../api";

const {actions, name, reducer} = getSlice({
  name: `${MODULES.FAVORITES}.add`,
  data: {
    user: undefined,
    post: undefined,
  },
})

const {startCall, endCall, fetched, catchError, reset} = actions;


const resetDispatcher = () => dispatch => {
  dispatch(reset());
}


const saveDispatcher = (entity) => dispatch => {
  dispatch(startCall());

  return API.add(entity)
    .then(response => {
      dispatch(fetched(response));
    })
    .catch(response => {
      dispatch(catchError(response));
    }).then(response => {
      dispatch(endCall(response));
    })
    ;
};

const useSelector = () => useCustomSelector(name)


export {
  actions,
  name,
  reducer,
  resetDispatcher,
  saveDispatcher,
  useSelector
}
