import {getSlice, MODULES, useCustomSelector} from "../../helpers";
import {AEMetadata} from "../../../../_ae/AEPagination";
import * as API from "../api";

const {actions, name, reducer} = getSlice({
  name: `${MODULES.USERS}.list`,
  data: [],
  metadata: new AEMetadata(
    {},
    {field: 'firstName'},
    {},
    {'*': [], 
      "userRoles" : {'*': [], "role": {'*': []}},  
      "city": {"*": []}
    }
  )
})

const {startCall, endCall, fetched, catchError, reset} = actions;

const resetDispatcher = () => dispatch => {
  dispatch(reset());
}

const fetchDispatcher = metadata => dispatch => {
  dispatch(startCall());

  return API
    .all(metadata)
    .then(response => {
      response.data._data = response.data._data.map(user=>({
        ...user,
        roles: user.userRoles ? user.userRoles.map(userRole=>userRole.role.roleId) : []
      }))
      dispatch(fetched(response));

      return response;
    })
    .catch(response => {
      dispatch(catchError(response));

      return response;
    }).then(response=>{
      dispatch(endCall(response));
    })
    ;
};

const useSelector = () => useCustomSelector(name)

export {
  actions,
  name,
  reducer,
  fetchDispatcher,
  resetDispatcher,
  useSelector
}


