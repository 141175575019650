import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useIntl} from "react-intl";
import {ROLES} from "../../../../../_ae/config";
import {Role} from "../../components/Role";
import {AEAutocompleteField, AEField} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {
    fetchCities,
    useCitiesListState
} from "../../../../../redux/store/cities";
import {
    fetchRoles,
    useRolesListState
} from "../../../../../redux/store/roles";
import {localField} from "../../../../../_ae/helpers/UIHelper";


export const Form = ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         btnRef,
                     }) => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    const {data: cities} = useCitiesListState();
    const {data: roles} = useRolesListState();

    useEffect(() => {
        dispatch(fetchCities());
        dispatch(fetchRoles());
    }, [dispatch])

    return (
        <div className="form form-label-right">
            <div className="form-group row">
                <div className={"col-lg-6"}>
                    <AEField
                        name="firstName"
                        label={'FIRST_NAME'}
                    />
                </div>
                <div className={"col-lg-6"}>
                    <AEField
                        name="lastName"
                        label={'LAST_NAME'}
                    />
                </div>
            </div>
            <div className="form-group row py-1">
                <div className={`col-lg-6`}>
                    <AEAutocompleteField
                        name="city"
                        label={'CITY'}
                        options={cities}
                        getOptionLabel={o => o.name}
                    />
                </div>
                {
                    roles &&
                    <div className={`col-lg-6`}>
                        <AEAutocompleteField
                            name="roles"
                            label={'ROLES'}
                            options={roles}
                            getOptionLabel={role => role.name}
                            // options={Object.values(ROLES)}
                            // getOptionLabel={id=>formatMessage({id})}
                            renderOption={role => <Role role={role.name}/>}
                        />
                    </div>
                }
            </div>
            <div className="form-group d-flex flex-row py-1">
                <div className="flex-fill">
                    <AEField
                        name="email"
                        label={'EMAIL'}
                    />
                </div>
                {
                    !values.id &&
                    <>
                        <div className={"pl-2 flex-fill"}>
                            <AEField
                                name="password"
                                label={'PASSWORD'}
                                type={'password'}
                            />
                        </div>
                        <div className={"pl-2 flex-fill"}>
                            <AEField
                                name="_password_confirm"
                                label={'CONFIRM'}
                                type={'password'}
                            />
                        </div>
                    </>
                }

            </div>

            <button
                style={{display: "none"}}
                ref={btnRef}
                onClick={handleSubmit}
            />
        </div>
    );
}

