import React from "react";

const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: ' ',
  decimalSeparator: '.',
  symbol: '$'
}

export const AECurrency = ({value = 0, currency='MAD', options, ...props})=>{
  if (typeof value !== 'number') value = 0.0
  options = { ...defaultOptions, ...options }
  value = value.toFixed(options.significantDigits)

  const [val, decimal] = value.split('.')
  return  `${val.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${options.decimalSeparator}${decimal} ${currency} `
}