import axios from "axios";
import {axiosFormDataConfigs, getFormData} from "../../../_ae/helpers/AxiosHelpers";
import {toAbsoluteApiUrl} from "../../../_metronic/_helpers";
import {MODULES} from "../helpers";

export const API_URI = toAbsoluteApiUrl(`/${MODULES.GROUPS}`);

const normalize = _entity => {
    const {id, title, fileName, price, description} = _entity;
    const author = {id: _entity.author ? _entity.author.id : undefined}
    const category = {id: _entity.category ? _entity.category.id : undefined}

    let posts = undefined;
    if (_entity.posts) {
        posts = _entity.posts.map(post => ({id: post.id}))
    }

    return {
        id,
        title,
        fileName,
        price,
        description,
        author,
        category,
        posts
    }
}

export const all = (metadata) => axios.get(API_URI, {params: {meta: metadata}});
export const find = (id, metadata) => axios.get(`${API_URI}/${id}`, {params: {meta: metadata}});
export const findByCategory = (id, metadata) => axios.get(`${API_URI}/by/category/${id}`, {params: {meta: metadata}});
export const destroy = id => axios.delete(`${API_URI}/${id}`);
export const create = (entity, files) => axios.post(API_URI, getFormData(normalize(entity), files), axiosFormDataConfigs);
export const update = (entity, files) => axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(normalize(entity), files), axiosFormDataConfigs);
