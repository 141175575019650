import React from 'react';
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import {Link} from "react-router-dom";
import SVG from "react-inlinesvg";
import {AECurrency} from "../../../../_ae/components/Currency"
import moment from 'moment'
import {AEShareButtons} from "../../../../_ae/components/AEShareButtons";

export const PostListCard = ({entity}) => {

    let hasvideos = false
    hasvideos = entity.postAttachements.some(function (attachment) {
        const splittedFileName = attachment.fileName.split(".");
        return splittedFileName[splittedFileName.length - 1] === "mp4";
    });

    let attachmentsLength = 0;
    if (entity.postAttachements) {
        if (entity.fileName) {
            attachmentsLength = entity.postAttachements.length + 1;
        } else {
            attachmentsLength = entity.postAttachements.length;
        }
    }


    return (
        <div className="col-xs-12" style={{display: "grid"}}>
            <div className="property-card card  property-card-list row-fluid clearfix" style={{display: "inline"}}>
                <div className="property-card-header image-box col-sm-4">
                    {
                        entity.fileName ?
                            <img src={toEntityFileNameUrl(entity, 'posts')} alt={` `}
                                 className="mx-auto img-responsive"/>
                            :
                            <SVG
                                className="mx-auto img-responsive"
                                src={toAbsoluteUrl("/media/svg/gallery.svg")}
                            />
                    }
                    {
                        entity.state != "" &&
                        <div className={`budget_${entity.state} budget`}><span
                            className={"budget_label"}>{entity.state}</span></div>
                    }
                    <Link to={'/annonces/:id/details'.replace(':id', entity.id)} className="property-card-hover">
                        {/*<img src={toAbsoluteUrl("/frontoffice/img/property-hover-arrow.png")} alt=""
                             className="left-icon"/>*/}
                        <img src={toAbsoluteUrl("/frontoffice/img/icon-notice.png")} alt="" className="center-icon"/>
                        {/*<img src={toAbsoluteUrl("/frontoffice/img/plus.png")} alt="" className="right-icon"/>*/}
                    </Link>
                    <span className="property-card-value text-white bg-primary p-1">
                        {hasvideos && <i className="fa fa-video text-white p-1"/>}
                        <i className="fa fa-images text-white p-1"/>
                        {attachmentsLength}
                    </span>
                </div>
                <div className="col-sm-8 ">
                    <div className="property-card-tags">
                        <span className="label label-default"
                              style={{backgroundColor: "transparent", display: "inline"}}>
                            <i className="fas fa-clock"/>{moment(entity.createdAt).fromNow()}
                        </span>
                    </div>
                    <div className="property-card-box card-box card-block">
                        <h3 className="property-card-title">
                            <Link to={'/annonces/:id/details'.replace(':id', entity.id)}>
                                {entity.title} - {entity.type}
                            </Link>
                        </h3>
                        <div className="property-card-descr text-truncate">
                            {entity.description}
                        </div>
                        <div className="property-preview-footer  clearfix">
                            <div className="property-preview-f-left">

                                <span className="property-card-value text-color-primary">
                                   <AECurrency value={entity.price}/>
                                </span>
                                {
                                    entity.city &&
                                    <span className="property-card-value">
                                        <i className="fa fa-map-marker"/>
                                        {entity.city.name}
                                    </span>
                                }
                                {/*
                                <span className="property-card-value">
                                    <Location location={entity.location} fontSize='sm'/>
                                </span>*/}
                            </div>
                            {/*<div className="separator separator-solid my-2"/>*/}
                            <div className="property-preview-f-right">
                                <span className="property-card-value">
                                    Partager :
                                </span>

                                <AEShareButtons pathname={'/annonces/:id/details'.replace(':id', entity.id)}
                                                facebook={true} whatsapp={true} telegram={true}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}