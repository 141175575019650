import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {useMetadata} from "../../../../_ae/AEPagination";
import {Pagination} from "../../../../_metronic/_partials/controls";
import {Loader} from "../../../../_ae/components/loader";
import {Slider} from "../Slider";
import {fetchPostGroups, usePostGroupsListState} from "../../../../redux/store/groups";
import {PackCard} from "./PackCard";
import {MainHeader} from "../MainHeader";


function PacksList() {

    const dispatch = useDispatch();
    const {data, isLoading, metadata} = usePostGroupsListState()

    const metadataState = useMetadata(metadata)
    const {page, perPage, sortAsc, filters, sortField, select} = metadataState;

    const getEntities = () => {
        dispatch(fetchPostGroups(metadataState.toAEMetadata()));
    }

    useEffect(() => {
        getEntities()
    }, [dispatch, page, perPage, filters.search, filters.city, select, sortField, sortAsc]);

    return (
        <>
            <header className="header">
                <MainHeader {...metadataState}/>
                <div className="container">
                    <Slider/>
                    {/*<CategoriesList />*/}
                </div>
            </header>
            {/*<header className="header">*/}
            {/*    <Header/>*/}
            {/*    /!*<SearchBar {...metadataState}/>*!/*/}

            {/*    <div className="container">*/}
            {/*        <Slider/>*/}
            {/*        <CategoriesList/>*/}
            {/*    </div>*/}
            {/*</header>*/}

            <main className="main section-color-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="h-side top-pad h-side-slim clearfix">
                                <div className="properties-filter breadcrumb">
                                    <div className="form-group">
                                        <Link to='/packs' style={{textDecoration: 'underline'}}>
                                            <span className="agent-name m-2">
                                                   <i className={"agent-name fa fa-home"}/>
                                                    Tous les Packs ({data ? data.length : '0'} Packs) >>
                                            </span>
                                        </Link>
                                        <Loader isLoading={isLoading}/>
                                    </div>
                                </div>
                            </div>
                            <div className="properties">
                                <div className="row">
                                    {
                                        data &&
                                        data.map((post, index) => {
                                            return (
                                                <PackCard key={index} entity={post}/>
                                            );
                                        })
                                    }
                                </div>

                                <Pagination {...metadataState} counts={[12, 24, 48]}/>

                            </div>
                        </div>

                        {/*<PostsFeatured/>*/}

                    </div>
                </div>
            </main>
        </>
    );
}

export default PacksList;

