import React, {useEffect} from "react";
import {Pagination} from "../../../../../_metronic/_partials/controls";
import {useDispatch, useSelector} from "react-redux";
import {EntityCard} from "../../components/EntityCard";
import {Filter} from "./Filter";
import {useMetadata} from "../../../../../_ae/AEPagination";
import {fetchResumes, useResumesListState} from "../../../../../redux/store/resumes";
import {Loader} from "../../../../../_ae/components/loader";

export const List = () => {
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.auth);

    const {data, isLoading, metadata} = useResumesListState()

    const metadataState = useMetadata(metadata)
    const {page, perPage, sortAsc, filters, sortField, select} = metadataState;

    /* Hooks */
    useEffect(() => {
        if (user) {
            dispatch(fetchResumes({
                ...metadataState.toAEMetadata(),
                filters: {...metadataState.toAEMetadata().filters, user: {id: user.id}}
            }));
        }
    }, [dispatch, user, page, perPage, filters.search, select, sortField, sortAsc]);

    return (
        <>
            <Loader isLoading={isLoading}/>
            <Filter {...metadataState}/>
            <div className={'row'}>
                {
                    data.map(r => (
                        <div key={r.id} className={'col-md-6'}>
                            <EntityCard
                                key={r.id}
                                entity={r}
                                size={'md'}
                                className={'card-stretch'}
                                editAction
                                deleteAction
                                detailsAction
                            />
                        </div>
                    ))
                }
            </div>
            <Pagination {...metadataState} counts={[16, 32, 64, 128]}/>
        </>
    );
}
