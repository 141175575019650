import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import React from "react";
import SVG from "react-inlinesvg";
import {Tooltip} from "react-bootstrap";
import {AEOverlayTrigger} from "../../../../_ae/AEOverlayTrigger";
import {FullName} from "../../Users/components/FullName";

export const StoryAvatar = ({entity, className = '', size = 35}) => {
    const {fileName} = entity

    return (
        <AEOverlayTrigger
            key={entity.id}
            placement={"top"}
            // trigger={["click"]}
            overlay={
                <Tooltip id={`tooltip_entity_card_proposal_user`}>
                    <FullName user={entity}/>
                </Tooltip>}>
            <div className={`symbol ${className} symbol-${size}`}>
                {
                    fileName ?
                    <img className="rounded-circle" src={toEntityFileNameUrl(entity, 'users')} alt={` `}/>
                        :
                    <span className="symbol-label rounded-circle">
                        <SVG
                           className="h-75 align-self-end"
                           src={toAbsoluteUrl("/media/svg/avatars/004-boy-1.svg")}
                        />
                    </span>
                }
                {/*<i className="symbol-badge bg-primary"/>*/}
            </div>
        </AEOverlayTrigger>
    )
}