export {
  actions as categoriesListActions,
  reducer as categoriesListReducer,
  name as categoriesListName,
  fetchDispatcher as fetchCategories,
  resetDispatcher as resetCategories,
  useSelector as useCategoriesListState
} from './slices/list'

export {
  actions as categoriesEditActions,
  reducer as categoriesEditReducer,
  name as categoriesEditName,
  useSelector as useCategoriesEditState,
  fetchDispatcher as fetchCategoryForEdit,
  resetDispatcher as resetCategoryForEdit,
  saveDispatcher as saveCategory
} from './slices/edit'

export {
  actions as categoriesDeleteActions,
  reducer as categoriesDeleteReducer,
  name as categoriesDeleteName,
  useSelector as useCategoriesDeleteState,
  fetchDispatcher as fetchCategoryForDelete,
  resetDispatcher as resetCategoryForDelete,
  deleteDispatcher as deleteCategory
} from './slices/delete'

export {
  actions as subCategoriesEditActions,
  reducer as subCategoriesEditReducer,
  name as subCategoriesEditName,
  useSelector as useSubCategoriesEditState,
  fetchDispatcher as fetchCategoriesForSubCategoriesEdit,
  resetDispatcher as resetCategoryForSubCategoriesEdit
} from './slices/listForSubCategoriesEdit'

export {
  actions as categoriesFilterActions,
  reducer as subCategoriesFilterReducer,
  name as subCategoriesFilterName,
  useSelector as useSubCategoriesFilterState,
  fetchDispatcher as fetchCategoriesForSubCategoriesFilter,
  resetDispatcher as resetCategoryForSubCategoriesFilter
} from './slices/categoriesFilter'

export {
  actions as categoryDetailsActions,
  reducer as categoryDetailsReducer,
  name as categoryDetailsName,
  useSelector as useCategoryDetailsState,
  fetchDispatcher as fetchCategoryForDetails,
  resetDispatcher as resetCategoryForDetails
} from './slices/details'